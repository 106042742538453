import { Component, ElementRef, OnInit } from '@angular/core';
import { Select2Data, Select2Group } from 'ng-select2-component';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Alert, Pais } from '../../models/alert.interface';
import { AlertService } from '../../../alerts/service/alert.service';
import { VehicleService } from '../../../vehicles/services/vehicle.service';
import { PanelService } from 'src/app/panel/services/panel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Op } from 'src/app/multiview/models/interfaces';
import { AssociationService } from 'src/app/vehicles/services/association.service';

declare var $: any;

@Component({
  selector: 'app-alert-accessories-create',
  templateUrl: './alert-accessories-create.component.html',
  styleUrls: ['./alert-accessories-create.component.scss'],
})
export class AlertAccessoriesCreateComponent implements OnInit {
  options = new Array({ id: 'ALERTS-ACCESSORIES', name: 'Alertas 360' });
  type = '';
  public alertForm!: FormGroup;
  alertSelected: any;
  public events: any = [];
  public selectedOption: string = 'Filtrar por:';
  public loading: boolean = true;
  public vehicles: (Select2Group &{operation:number,grupo:number,convoy:number}| Select2Data &{operation:number,grupo:number,convoy:number})[] = [];
  public association: Op[]=[];
  public disabledEventSoundActive = true;
  public disabledEmail = true;
  public disabledWhatsapp = true;
  public codes: Pais[] = [];
  public codeSelected: number = 51;
  loadingEventSelectInput: boolean = true;

  booleanOptions = [
    { label: 'Sí', value: true },
    { label: 'No', value: false },
  ];

  booleanOptionsVentanaEmergente = [
    { label: 'Activado', value: true },
    { label: 'Desactivado', value: false },
  ];

  booleanOptionsAtencionEventos = [
    { label: 'Activado', value: true },
    { label: 'Desactivado', value: false },
  ];
  selectOperation:number | null= null
  selectGroup:number | null=null
  selectConvoy:number | null=null
  showSelectedCascade:boolean=false
  clearSelectedCascade:boolean=false
  listaSonidos: any = [];
  audio = new Audio();

  loadingAlertDropdownReady: boolean = false;
  loadingVehicleMultiselectReady: boolean = false;

  constructor(
    private AlertService: AlertService,
    private VehicleService: VehicleService,
    private formBuilder: FormBuilder,
    public panelService: PanelService,
    private spinner: NgxSpinnerService,
    public associationService: AssociationService,
    private el: ElementRef
  ) {
    this.listaSonidos = [...this.AlertService.listaSonidos];
    this.codes = AlertService.codes;
  }

  ngOnInit(): void {
    this.spinner.show('loadingAlertData');
    this.type =
      this.panelService.nombreComponente == 'ALERTS-SECURITY-CREATE'
        ? 'security'
        : this.panelService.nombreComponente == 'ALERTS-MOBILE-CREATE'
        ? 'mobile'
        : '360';
    console.log('TYPE:   ', this.type);
    this.associationService.getAssociation();

    this.association = this.associationService.association;

    this.alertForm = this.formBuilder.group({
      vehicles: ['', [Validators.required]],
      // geocercas: [[]],
      // geocircles: [[]],
      tipoAlerta: ['', [Validators.required]],
      chkEventoActivado: [true],
      chkSonido: [false],
      chkCorreo: [false],
      chkwhatsapp: [false],
      sonido: [
        {
          value: 'sonidos/alarm8.mp3',
          disabled: this.disabledEventSoundActive,
        },
      ],
      nombre: ['', [Validators.required]], // Campo obligatorio
      lista_emails: [[]],
      fecha_desde: [moment(new Date('2000/01/01')).format('YYYY-MM-DD')],
      fecha_hasta: [moment(new Date('2000/01/01')).format('YYYY-MM-DD')],
      email: [
        { value: '', disabled: this.disabledEmail },
        [Validators.required, Validators.email],
      ],
      eventType: [this.type],

      lista_whatsapp: [[]],
      whatsapp: [
        { value: '', disabled: this.disabledWhatsapp },
        [Validators.required],
      ],
      chkVentanaEmergente: [false],
      chkEvaluation: [false],
    });

    this.loading = false;
    this.loadData();
  }

  public async loadData() {
    this.setDataVehicles();
    this.events = await this.AlertService.getEventsByType(this.type);
    this.loadingEventSelectInput = false;

    this.loadingAlertDropdownReady = true;
    this.hideLoadingSpinner();
  }

  // Inicio el filtro por sonidos

  actualizarSonidos() {
    console.log(this.listaSonidos);
    console.log('tipoalerta', this.alertForm.value.tipoAlerta);

    const alert = this.events.find(
      (event: any) =>
        event.id.toString() === this.alertForm.value.tipoAlerta.toString()
    );

    if (alert) {
      this.alertSelected = alert;
      console.log('alerttt', alert);

      // Obtén todos los elementos de la lista de sonidos hasta el id 27
      let sonidosObtenidos = this.AlertService.listaSonidos.filter(
        (sonido: { id: any }) => sonido.id <= 26
      );

      if (alert.name == 'Fatiga Extrema') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 33
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'Anticolisión Frontal') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 28
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'Riesgo de Colisión con Peatones') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 38
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'No Rostro') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 36
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'Desvío de Carril Hacia la Izquierda') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 30
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'Desvío de Carril Hacia la Derecha') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 29
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'Posible Fatiga') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 37
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      } else if (alert.name == 'Distracción') {
        const vozPersonalizada = this.AlertService.listaSonidos.find(
          (sonido: { id: any }) => sonido.id === 31
        );
        if (vozPersonalizada) {
          sonidosObtenidos = [vozPersonalizada, ...sonidosObtenidos];
        }
      }

      if (sonidosObtenidos.length > 0) {
        console.log('Sonidos obtenidos:', sonidosObtenidos);
        this.listaSonidos = sonidosObtenidos;
      } else {
        console.log(
          'No se encontraron sonidos para la alerta seleccionada o la función no devuelve un array.'
        );
      }
    } else {
      this.listaSonidos = this.AlertService.listaSonidos.filter(
        (sonido: { id: any }) => sonido.id <= 26
      );

      if (this.listaSonidos.length > 0) {
        console.log('Sonidos obtenidos:', this.listaSonidos);
      } else {
        console.log('No se encontraron sonidos para la alerta seleccionada.');
      }
    }
  }

  // Fin el filtro por sonidos
  setDataVehicles() {
    let vehicles = this.VehicleService.getVehiclesData();

    this.vehicles = vehicles.map((vehicle: any) => {
      return {
        value: vehicle.IMEI,
        label: vehicle.name,
        operation:vehicle.idoperation,
        grupo:vehicle.idgrupo,
        convoy:vehicle.idconvoy,
      };
    });

    this.loadingVehicleMultiselectReady = true;
    this.hideLoadingSpinner();
  }

  playAudio(path: string) {
    if (typeof path != 'undefined' && path != '') {
      if (this.audio.currentSrc != '' && !this.audio.ended) {
        this.audio.pause();
      }
      this.audio = new Audio('assets/' + path);
      let audioPromise = this.audio.play();

      if (audioPromise !== undefined) {
        audioPromise
          .then(() => {
            //console.log('Playing notification sound')
          })
          .catch((error: any) => {
            //console.log(error);
            // Auto-play was prevented
          });
      }
    }
  }

  changeDisabled() {
    if (this.alertForm.value.chkSonido) {
      this.alertForm.controls['sonido'].enable();
    } else {
      this.alertForm.controls['sonido'].disable();
    }
  }

  addEmail() {
    if (this.alertForm.value.chkCorreo) {
      if (this.validateEmail(this.alertForm.value.email)) {
        if (
          !this.isInArray(
            this.alertForm.value.email,
            this.alertForm.value.lista_emails
          )
        ) {
          this.alertForm.value.lista_emails.push(this.alertForm.value.email);
          this.alertForm.controls.email.reset();
        } else {
          Swal.fire({
            title: 'Error',
            text: 'El email ingresado ya existe.',
            icon: 'warning',
          });
        }
      } else {
        Swal.fire('Error', 'Debe ingresar un email válido.', 'warning');
      }
    }
  }

  restEmail(index: any) {
    this.alertForm.value.lista_emails.splice(index, 1);
  }

  validateEmail(email: any) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isInArray(value: any, array: any[]) {
    return array.indexOf(value) > -1;
  }

  chkEmailHandler() {
    if (this.alertForm.value.chkCorreo) {
      this.alertForm.controls['email'].enable();
    } else {
      this.alertForm.controls['email'].disable();
    }
  }

  chkWhatsappHandler() {
    if (this.alertForm.value.chkwhatsapp) {
      this.alertForm.controls['whatsapp'].enable();
    } else {
      this.alertForm.controls['whatsapp'].disable();
    }
  }

  onSubmit(event: any) {
    event.preventDefault();

    if (
      this.alertForm.value.chkCorreo &&
      this.alertForm.value.lista_emails.length == 0
    ) {
      Swal.fire('Error', 'Debe ingresar un correo', 'warning');
      return;
    }

    if (
      this.alertForm.value.chkwhatsapp &&
      this.alertForm.value.lista_whatsapp.length == 0
    ) {
      Swal.fire('Error', 'Debe ingresar un número', 'warning');
      return;
    }

    this.alertForm.value.vehiculos = JSON.stringify(
      this.alertForm.value.vehicles
    );

    if (typeof this.alertForm.value.sonido == 'undefined') {
      this.alertForm.value.sonido = 'sonidos/alarm8.mp3';
    }

    if (
      this.alertForm.value.vehicles.length != 0 &&
      this.alertForm.value.nombre.trim() != '' &&
      this.alertForm.value.tipoAlerta.length != 0
    ) {
      Swal.fire({
        title: '¿Desea guardar los cambios?',
        //text: 'Espere un momento...',
        icon: 'warning',
        showLoaderOnConfirm: true,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Guardar',
        cancelButtonText: 'Cancelar',
        preConfirm: async () => {
          const res = await this.AlertService.create(this.alertForm.value);
          this.AlertService.getAll();
          this.clickShowPanel();
        },
      }).then((data) => {
        if (data.isConfirmed) {
          Swal.fire(
            'Datos guardados',
            'Los datos se guardaron correctamente!!',
            'success'
          );
        }
      });
    } else {
      if (this.alertForm.value.vehicles.length === 0) {
        Swal.fire(
          '¡Atención!',
          'Debe seleccionar al menos un vehículo para completar la acción.',
          'warning'
        );
      }
      if (this.alertForm.value.nombre.trim() === '') {
        Swal.fire(
          '¡Atención!',
          'Debe ingresar el nombre de la alerta',
          'warning'
        );
      }
      if (this.alertForm.value.tipoAlerta.length === 0) {
        Swal.fire(
          '¡Atención!',
          'Debe seleccionar el tipo de alerta',
          'warning'
        );
      }
    }
  }

  clickShowPanel(): void {
    $('#panelMonitoreo').show('slow');
    if (this.type == 'security') {
      this.panelService.nombreComponente = 'ALERTS-SECURITY';
      this.panelService.nombreCabecera = 'Alertas Seguridad Vehicular';
    } else if (this.type == 'mobile') {
      this.panelService.nombreComponente = 'ALERTS-MOBILE';
      this.panelService.nombreCabecera = 'Alertas Solución Móvil';
    } else {
      this.panelService.nombreComponente = 'ALERTS-360';
      this.panelService.nombreCabecera = 'Alertas Fatiga 360';
    }
  }

  hideLoadingSpinner() {
    if (this.loadingAlertDropdownReady && this.loadingVehicleMultiselectReady) {
      this.spinner.hide('loadingAlertData');
    }
  }

  addWhatsapp() {
    if (this.alertForm.value.chkwhatsapp) {
      if (
        this.alertForm.value.whatsapp &&
        this.alertForm.value.whatsapp.toString().length > 8
      ) {
        if (
          !this.isInArray(
            this.alertForm.value.whatsapp,
            this.alertForm.value.lista_whatsapp
          )
        ) {
          this.alertForm.value.lista_whatsapp.push(
            `${this.codeSelected}${this.alertForm.value.whatsapp}`
          );
          this.alertForm.controls.whatsapp.reset();
        } else {
          Swal.fire({
            title: 'Error',
            text: 'El número ingresado ya existe.',
            icon: 'warning',
          });
        }
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Debe ingresar un número.',
          icon: 'warning',
        });
      }
    }
  }

  restWhatsapp(index: number) {
    this.alertForm.value.lista_whatsapp.splice(index, 1);
  }

  selectGeneral(id?:number,type?:string){
    // if (event) {
    //   event.stopPropagation();
    // }

    if (id!=undefined && type!=undefined) {
      
      if (type=='operation') {
        this.selectOperation=id

      }
      if (type=='groups') {
        this.selectGroup=id
      }
      if (type=='convoy') {
        this.selectConvoy=id
        console.log('Convoy seleccionado:', id);
      }
    }else{
      this.showSelectedCascade=!this.showSelectedCascade
    }
  }

  savefilterbyclick(id?:number,type?:string){
    if (type=='operation') {
      this.selectGroup=null
      this.selectConvoy=null
      
    }
    if (type=='groups') {
      this.selectConvoy=null
    }
    
    this.selectedOption=''
    console.log(this.selectOperation,':', this.selectGroup,';',this.selectConvoy,this.association);
    this.showSelectedCascade=false;
    if (this.selectOperation!=null) {
      const o=this.association.find((_)=>_.id==this.selectOperation)
      this.selectedOption+=o?.name
      if (this.selectGroup!=null) {
        const g=o?.operationGroups.find((_)=>_.id==this.selectGroup)
        this.selectedOption+=' / '+g?.name
        if (this.selectConvoy!=null) {
          const c=g?.convoyList.find((_)=>_.id==(this.selectConvoy))
          this.selectedOption+=' / '+c?.name
        }
      }
    }
    if (this.selectOperation!=null || this.selectGroup!=null || this.selectConvoy!=null) {
      this.clearSelectedCascade=true
    }
    this.filterVehicleByCascadeSelected()
  }

  clearSelectCascade(event:Event){
    event.stopPropagation()
    this.selectOperation=null
    this.selectGroup=null
    this.selectConvoy=null
    this.showSelectedCascade=false 
    this.clearSelectedCascade=false
    this.selectedOption='Filtrar por:'
    this.setDataVehicles()
  }
  filterVehicleByCascadeSelected(){
    this.setDataVehicles()
    this.vehicles=this.vehicles.filter((vehicle)=>{
      if(this.selectOperation!=null){
        if (this.selectGroup!=null) {
          if (this.selectConvoy!=null) {
            return this.selectConvoy==vehicle.convoy && this.selectGroup==vehicle.grupo && this.selectOperation==vehicle.operation
          } else {
            return this.selectGroup==vehicle.grupo && this.selectOperation==vehicle.operation
          }
        }else{
          return this.selectOperation==vehicle.operation
        }
      }else{
        return true
      }
  })
  }
}
