import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { type } from 'os';
import { Galleria } from 'primeng-lts/galleria';

@Component({
  selector: 'app-dvr-gallery',
  templateUrl: './dvr-gallery.component.html',
  styleUrls: ['./dvr-gallery.component.scss'],
})
export class DvrGalleryComponent implements OnInit {
  @Input() event: any;

  images = [
    {
      previewMediaSrc:
        'https://i.ibb.co/hD619Hb/Whats-App-Image-2024-10-31-at-16-19-25.jpg',
      thumbnailMediaSrc:
        'https://i.ibb.co/hD619Hb/Whats-App-Image-2024-10-31-at-16-19-25.jpg',
      alt: 'Description for Image 1',
      title: 'Title 1',
      hasVideo: false,
    },
    {
      previewMediaSrc:
        'https://i.ibb.co/6ZBQRGB/INVITADOS-invitacion-GL-TRACKER-copia-2.jpg',
      thumbnailMediaSrc:
        'https://i.ibb.co/6ZBQRGB/INVITADOS-invitacion-GL-TRACKER-copia-2.jpg',
      alt: 'Description for Image 2',
      title: 'Title 2',
      hasVideo: false,
    },
    {
      previewMediaSrc:
        'https://gltracker.pe/images/event/video/VIDEO_EXPOTRACKER2024.mp4',
      thumbnailMediaSrc:
        'https://i.ibb.co/6ZBQRGB/INVITADOS-invitacion-GL-TRACKER-copia-2.jpg',
      alt: 'Description for Image 2',
      title: 'Title 2',
      hasVideo: true,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.bindDocumentListeners();
  }
  showThumbnails: boolean = false;
  fullscreen: boolean = false;
  activeIndex: number = 0;
  onFullScreenListener: any;
  @ViewChild('galleria') galleria!: Galleria;

  onThumbnailButtonClick() {
    this.showThumbnails = !this.showThumbnails;
  }

  toggleFullScreen() {
    this.fullscreen
      ? this.closePreviewFullScreen()
      : this.openPreviewFullScreen();
  }

  openPreviewFullScreen() {
    let elem = this.galleria.element.nativeElement.querySelector('.p-galleria');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem['mozRequestFullScreen']) {
      elem['mozRequestFullScreen']();
    } else if (elem['webkitRequestFullscreen']) {
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) {
      elem['msRequestFullscreen']();
    }
  }

  onFullScreenChange() {
    this.fullscreen = !this.fullscreen;
  }

  closePreviewFullScreen() {
    const exitFullscreen =
      document.exitFullscreen ||
      (document as any).mozCancelFullScreen ||
      (document as any).webkitExitFullscreen ||
      (document as any).msExitFullscreen;

    if (exitFullscreen) {
      exitFullscreen.call(document);
    }
  }

  bindDocumentListeners() {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullScreenListener);
    document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.addEventListener(
      'webkitfullscreenchange',
      this.onFullScreenListener
    );
    document.addEventListener('msfullscreenchange', this.onFullScreenListener);
  }

  unbindDocumentListeners() {
    document.removeEventListener('fullscreenchange', this.onFullScreenListener);
    document.removeEventListener(
      'mozfullscreenchange',
      this.onFullScreenListener
    );
    document.removeEventListener(
      'webkitfullscreenchange',
      this.onFullScreenListener
    );
    document.removeEventListener(
      'msfullscreenchange',
      this.onFullScreenListener
    );
    this.onFullScreenListener = null;
  }

  ngOnDestroy() {
    this.unbindDocumentListeners();
  }

  galleriaClass() {
    return `custom-galleria ${this.fullscreen ? 'fullscreen' : ''}`;
  }

  fullScreenIcon() {
    return `pi ${
      this.fullscreen ? 'pi-window-minimize' : 'pi-window-maximize'
    }`;
  }
}
