<p-galleria
  *appHasPermission="'events_popupMedia_dvrGallery_'"
  #galleria
  [(value)]="images"
  [(activeIndex)]="activeIndex"
  [numVisible]="5"
  [containerStyle]="{}"
  [containerClass]="galleriaClass()"
  [showThumbnails]="showThumbnails"
  [showItemNavigators]="true"
  [showItemNavigatorsOnHover]="true"
  [circular]="true"
  [autoPlay]="true"
  [transitionInterval]="3000"
>
  <ng-template pTemplate="item" let-item>
    <div
      [style]="{
        width: fullscreen ? '1250px' : '100%',
        height: fullscreen ? '1000px' : '210px',
        'max-height': fullscreen ? '1000px' : '210px',
        'border-radius': '8px 8px 0px 0px',
        'background-color': 'rgba(0, 0, 0, 0.5)'
      }"
      class="d-flex justify-content-center align-items-center"
    >
      <video
        *ngIf="item.hasVideo"
        [src]="item.previewMediaSrc"
        controls
        style="width: 100%; height: 100%; object-fit: contain"
      ></video>
      <img
        *ngIf="!item.hasVideo"
        class="img-fluid"
        [src]="item.previewMediaSrc"
        style="width: 100%; height: 100%; object-fit: contain"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div class="p-grid p-nogutter p-justify-center">
      <img
        [src]="item.thumbnailMediaSrc"
        class="img-fluid"
        style="width: 100%; height: 100%; object-fit: contain"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="footer" let-item>
    <div class="custom-galleria-footer">
      <button
        type="button"
        pButton
        icon="pi pi-list"
        (click)="onThumbnailButtonClick()"
      ></button>
      <span *ngIf="images" class="title-container">
        <span>{{ activeIndex + 1 }}/{{ images.length }}</span>
        <span class="title">{{ images[activeIndex].title }}</span>
        <span>{{ images[activeIndex].alt }}</span>
      </span>
      <button
        type="button"
        pButton
        [icon]="fullScreenIcon()"
        (click)="toggleFullScreen()"
        class="fullscreen-button"
      ></button>
    </div>
  </ng-template>
</p-galleria>
