<div class="slider-content">
  <!-- <p-progressBar *ngIf="multimediaService.onDemandLoader" mode="indeterminate"></p-progressBar> -->
  <div class="slider-header">
    <div class="slider-title">
      <ng-container *ngIf="showTitle">
        <img
          *ngIf="icons_available.includes(event.tipo); else default"
          [src]="'./assets/images/events/' + event.tipo + '.png'"
          class="slider-icon"
        />
        <ng-template #default>
          <img
            [src]="'./assets/images/events/default-alert.png'"
            class="slider-icon"
          />
        </ng-template>
      </ng-container>
      <span *ngIf="showTitle" class="slider-text">
        {{
          event.nombre
            ? event.nombre
            : !event.mensaje_alerta.includes("undefi")
            ? event.mensaje_alerta
            : (event.name | uppercase)
        }}
      </span>
      <ng-container
        *appHasPermission="[
          'events_popupMediaOptions_VideoRecord',
          'events_popupMediaOptions_VideoOnDemand'
        ]"
      >
        <button
          *ngIf="!showTitle"
          style="width: 20px"
          class="control-button p-auto d-flex justify-content-center align-items-center"
          [disabled]="loading"
          (click)="menu.toggle($event)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M 4.5857997,3.0423416
A 2.4788292,2.4788292 0 0 0 2.1069705,5.5211707 2.4788292,2.4788292 0 0 0 4.5857997,8 2.4788292,2.4788292 0 0 0 6.960053,6.2309294
h 6.223217
c 0.393253,0 0.709758,-0.3165048 0.709759,-0.7097587 0,-0.3932543 -0.316506,-0.7097585 -0.709759,-0.7097585
H 6.9610217
A 2.4788292,2.4788292 0 0 0 4.5857997,3.0423416
Z

m 0,0.9915316
A 1.4872975,1.4872975 0 0 1 6.0730971,5.5211707 1.4872975,1.4872975 0 0 1 4.5857997,7.0084682 1.4872975,1.4872975 0 0 1 3.0985021,5.5211707 1.4872975,1.4872975 0 0 1 4.5857997,4.0338732
Z

M 11.414199,8
A 2.4788292,2.4788292 0 0 0 9.0389777,9.7690704
H 2.816729
c -0.3932539,0 -0.7097585,0.3165046 -0.7097585,0.7097586 0,0.393254 0.3165046,0.709759 0.7097585,0.709759
h 6.2232167
a 2.4788292,2.4788292 0 0 0 2.3742533,1.76907 2.4788292,2.4788292 0 0 0 2.47883,-2.478829
A 2.4788292,2.4788292 0 0 0 11.414199,8
Z

m 0,0.9915314
a 1.4872975,1.4872975 0 0 1 1.487298,1.4872976 1.4872975,1.4872975 0 0 1 -1.487298,1.487297 1.4872975,1.4872975 0 0 1 -1.4872971,-1.487297 1.4872975,1.4872975 0 0 1 1.4872971,-1.4872976
z"
            />
          </svg>
        </button>
        <p-menu
          #menu
          [popup]="true"
          [model]="menuMultimedia"
          appendTo="body"
        ></p-menu>
      </ng-container>
    </div>
    <div
      *ngIf="
        hasMultimedia &&
        showMultimedias &&
        multimediaService.multimediaCipiaItems[event.parametros.eventId].length
      "
      class="multimedia-controls"
    >
      <button
        *ngIf="
          multimediaService.multimediaCipiaItems[event.parametros.eventId]
            .length > 1
        "
        class="control-button pe-auto"
        (click)="next()"
      >
        <i class="pi pi-chevron-left"></i>
      </button>
      <span class="slider-text"
        >{{ activeIndex }}/{{
          multimediaService.multimediaCipiaItems[event.parametros.eventId]
            .length
        }}</span
      >
      <button
        *ngIf="
          multimediaService.multimediaCipiaItems[event.parametros.eventId]
            .length > 1
        "
        class="control-button pe-auto"
        (click)="prev()"
      >
        <i class="pi pi-chevron-right"></i>
      </button>
      <span
        (click)="waitTimeService.getAllTimes()"
        *ngIf="!error && !loading"
        [pTooltip]="
          waitTimeService.getDurationOfCipiaByIds(
            event.uuid_event,
            activeIndex - 1
          ) + ' seg.'
        "
      >
        <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path
            d="m 7.7918372,4.9718705
c -0.1510907,0 -0.2714843,0.1223468 -0.2714843,0.2734375
v 3.1367188
c 0,0.030471 0.00453,0.058618 0.013672,0.085937 -7.04e-5,6.572e-4 6.55e-5,0.0013 0,0.00195 -0.0096,0.096234 0.033175,0.1955976 0.1191406,0.2539063
l 2.5957025,1.7597649
c 0.125041,0.08481 0.294094,0.05278 0.378907,-0.07227 0.08481,-0.125041 0.05278,-0.294094 -0.07227,-0.378906
L 8.0652748,8.3449174
V 5.245308
c 0,-0.1510907 -0.1223468,-0.2734375 -0.2734376,-0.2734375
z

M 8,4.466
V 0.534
A 0.25,0.25 0 0 0 7.59,0.342
L 5.23,2.308
a 0.25,0.25 0 0 0 0,0.384
L 7.59,4.658
A 0.25,0.25 0 0 0 8,4.466

M 8,3
A 5,5 0 1 1 3.454,5.914 0.5,0.5 0 0 0 2.546,5.497 6,6 0 1 0 8,2
Z"
          />
        </svg>
      </span>
    </div>
  </div>
  <div *ngIf="showMultimedias" class="multimedia-body">
    <div class="multimedia-wrapper" #multimedia_wrapper>
      <ng-container
        *ngFor="
          let item of multimediaService.multimediaCipiaItems[
            event.parametros.eventId
          ];
          let i = index
        "
      >
        <div
          class="multimedia-item overflow-hidden"
          *ngIf="i == activeIndex - 1"
        >
          <img
            *ngIf="!error && !loading && item.type == 'image'"
            [src]="item.url"
            style="height: 100%; object-fit: contain"
            alt="cargando..."
          />
          <div
            *ngIf="error"
            (click)="loadMedia(item)"
            class="error-multimedia d-flex flex-column align-items-center justify-content-center"
          >
            <i class="pi pi-refresh fs-2 cursor-pointer text-white"></i>
            <span class="text-white fs-6 cursor-pointer">Reintentar</span>
          </div>
          <video
            width="100%"
            height="100%"
            controls
            type="video/mp4"
            *ngIf="!error && !loading && item.type == 'video'"
            [src]="item.url"
          ></video>
          <p-progressSpinner
            *ngIf="!error && loading"
            [style]="{ width: '40px', height: '40px' }"
            styleClass="custom-spinner"
            strokeWidth="5"
            fill="#000000"
            animationDuration="1s"
          ></p-progressSpinner>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-content></ng-content>
  <div *ngIf="showFooter" class="slider-footer">
    <div class="slider-title">
      <img src="./assets/images/events/conductor.png" class="slider-icon" />
      <span class="slider-text">{{
        driver ? driver : "Conductor no identificado"
      }}</span>
    </div>
  </div>
  <div *ngIf="!showFooter" class="slider-footer">
    <div class="slider-title d-flex justify-content-between w-100">
      <span class="slider-text"
        >{{
          multimediaService.multimediaCipiaItems[event.parametros.eventId][
            activeIndex - 1
          ]?.description
        }}
      </span>
      <div
        *ngIf="
          hasMultimedia &&
          showMultimedias &&
          multimediaService.multimediaCipiaItems[event.parametros.eventId]
            .length &&
          multimediaService.multimediaCipiaItems[event.parametros.eventId][
            activeIndex - 1
          ].type == 'image'
        "
        class="multimedia-controls"
      >
        <button class="window-button pe-auto" (click)="toggleMaximize()">
          <i *ngIf="!isMaximized" class="pi pi-window-maximize"></i>
          <i *ngIf="isMaximized" class="pi pi-window-minimize"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="Obtener video"
  [(visible)]="showGetVideoDialog"
  (onHide)="showGetVideoDialog = false"
  (onShow)="updateSliderBackground()"
  [breakpoints]="{ '960px': '50vw', '640px': '75vw' }"
  appendTo="body"
  id="getVideo"
>
  <div class="dialog-body-container">
    <div class="p-fluid p-formgrid p-grid">
      <div class="dialog-form-container">
        <div
          class="d-flex flex-column mt-2 gap-1 justify-content-center"
          style="min-width: 25rem"
        >
          <p-selectButton
            *appHasPermission="'events_popupMediaOptions_VideoOnDemand_byType'"
            [options]="mediaCipiaOptions"
            [(ngModel)]="mediaCipiaValue"
            style="margin: 0; padding: 0"
          >
            <ng-template let-item style="margin: 0; padding: 0">
              <span style="font-size: 0.875rem; margin: auto; padding: 0">{{
                item.label
              }}</span>
            </ng-template>
          </p-selectButton>
          <div class="d-flex justify-content-between">
            <h6 class="my-2">
              Rango: {{ rangeValues[0] | unixTimeToDate }} -
              {{ rangeValues[1] | unixTimeToDate }}
            </h6>
            <h6 class="my-2">
              Duración: {{ (rangeValues[1] - rangeValues[0]) / 1000 }}&nbsp;seg.
            </h6>
          </div>
          <div
            class="d-flex justify-content-between gap-1 align-content-center"
          >
            <button
              pButton
              pRipple
              [pTooltip]="'- 1 minuto'"
              (click)="addMinMaxTime(-60)"
              type="button"
              icon="pi pi-step-backward"
              class="p-button-rounded p-button-text"
            ></button>
            <p-slider
              *ngIf="showGetVideoDialog"
              [(ngModel)]="rangeValues"
              [range]="true"
              [min]="min_range"
              [max]="max_range"
              [step]="1000"
              class="w-100 m-auto"
              [style]="{ background: gradientColor, margin: 'auto' }"
              #_slider
            >
            </p-slider>
            <button
              pButton
              pRipple
              [pTooltip]="'+ 1 minuto'"
              (click)="addMinMaxTime(+60)"
              type="button"
              icon="pi pi-step-forward"
              class="p-button-rounded p-button-text"
            ></button>
          </div>
          <div class="d-flex justify-content-between">
            <span>{{ min_range | unixTimeToDate }}</span>
            <span>{{ max_range | unixTimeToDate }}</span>
          </div>
          <button
            (click)="getMultimediaByRange(rangeValues)"
            type="button"
            class="btn-gl-save btn-dark-hover btn-sm w-50 my-4 mx-auto"
            [disabled]="loading"
          >
            Obtener
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<p-dialog
  header="Grabar video"
  [(visible)]="showRecordVideoDialog"
  (onHide)="showRecordVideoDialog = false"
  [breakpoints]="{ '960px': '50vw', '640px': '75vw' }"
  appendTo="body"
  id="recordVideo"
>
  <div class="dialog-body-container">
    <div class="p-fluid p-formgrid p-grid">
      <div class="dialog-form-container">
        <div
          class="d-flex flex-column mt-2 gap-1 justify-content-center"
          style="min-width: 25rem"
        >
          <p-selectButton
            *appHasPermission="'events_popupMediaOptions_VideoRecord_byType'"
            [options]="mediaCipiaOptions"
            [(ngModel)]="mediaCipiaValue"
            style="margin: 0; padding: 0"
          >
            <ng-template let-item style="margin: 0; padding: 0">
              <span style="font-size: 0.875rem; margin: auto; padding: 0">{{
                item.label
              }}</span>
            </ng-template>
          </p-selectButton>
          <div class="d-flex justify-content-between">
            <h6 class="my-2">Duración: {{ recordTime }}&nbsp;seg.</h6>
          </div>
          <div
            class="d-flex justify-content-between gap-1 align-content-center"
          >
            <p-slider
              *ngIf="showRecordVideoDialog"
              [(ngModel)]="recordTime"
              [min]="10"
              [max]="60"
              [step]="1"
              class="w-100 m-auto"
            >
            </p-slider>
          </div>
          <button
            (click)="getMultimediaRecording(recordTime)"
            type="button"
            class="btn-gl-save btn-dark-hover btn-sm w-50 my-4 mx-auto"
            [disabled]="loading"
          >
            Grabar
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
