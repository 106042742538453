// Importaciones
import { Injectable } from '@angular/core';
import { Operation, Convoy, UserTracker, Op, Gp, Co } from "src/app/multiview/models/interfaces";
import { VehicleService } from "./vehicle.service";
// import { CascadeConvoy, CascadeGroup, CascadeOperation } from "./cascade_interfaces";
// import { group } from 'console';

@Injectable({
  providedIn: 'root'
})
export class AssociationService {
  // Propiedades públicas
  public operations: Operation[] = [];
  public convoys: Convoy[] = [];
  public vehicles: UserTracker[] = [];
  public association: Op[] = [];

  // Constructor
  constructor(private vehicleService: VehicleService) {}

  getAssociation(){
    
    this.association=this.initialize(this.vehicleService.vehicles)

  }
  initialize(vehicles:UserTracker[]):Op[]{
      const ops:Op[]=[]
      ops.push({
        id:0,
        name:'Unidades Sin Operacion',
        operationGroups:[]
      })
        vehicles.map((vehicle)=>{
          this.operation(vehicle,ops)
          this.assignGroupsToOperations(vehicle,ops)
          this.assignConvoysToGroupsInOperations(vehicle,ops)
        })
    return ops;
  }

  operation(vehicle:UserTracker,ops:Op[]){
     // Operaciones
     if (vehicle.idoperation && vehicle.idoperation > 0) {
      let op:Op = {
        name: vehicle.nameoperation ?? '',
        id: vehicle.idoperation,
        operationGroups: [],
      };

      if (!ops.some((o) => o.id === op.id)) {
        ops.push(op);
      }
    }


  }
  assignGroupsToOperations(vehicle:UserTracker,ops:Op[]){
        // Grupos
        if (vehicle.idoperation != null && vehicle.idgrupo != null) {
          console.log('ASIGNANDO GRUPO',(vehicle.idoperation && vehicle.idgrupo));
          let group:Gp = {
            id: vehicle.idgrupo,
            name: vehicle.namegrupo ?? '',
            convoyList: []
          };
          ops.map((op)=>{
            if(op.id==vehicle.idoperation){
              if (!op.operationGroups.some((g) => g.id === group.id)) {
                op.operationGroups.push(group);
                
              }
            }
          })
        }
  }
  assignConvoysToGroupsInOperations(vehicle:UserTracker,ops:Op[]){
     // Convoys
     if (vehicle.idoperation !=null && vehicle.idgrupo !=null && vehicle.idconvoy != null) {
      let convoy:Co = {
        id: vehicle.idconvoy,
        name: vehicle.nameconvoy ?? '',
      };
      ops.map((op)=>{
        if(op.id==vehicle.idoperation){
          op.operationGroups.map((co)=>{
            if (co.id == vehicle.idgrupo) {
              if (!co.convoyList.some((c) => c.id === convoy.id)) {
                co.convoyList.push(convoy);
              }
            }
          })
        }
      })
      
    }
  }
}