<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="reportSpinner" size = "medium" color = "#fff" type = "ball-rotate" [fullScreen] = "false"><p style="color: white" > Generando Reporte... </p></ngx-spinner>
<p-confirmDialog #cdExport [style]="{width: '30rem'}" [closeOnEscape]="true" [breakpoints]="{'960px': '50vw', '640px': '75vw'}" class="gl-confirm-dialog " key="showSelectExcelConfirmation">
  <ng-template pTemplate="header">
      <h4>Elija un formato para exportar</h4>
      <div class="p-dialog-header-icons confirm-dialog-close-button">
        <button type="button" class="p-dialog-header-icon p-dialog-header-close p-link" (click)="cdExport.close($event)">
          <span class="pi pi-times"></span>
        </button>
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="col-4">
      <button type="button" class="btn-gl-neutral btn-neutral-hover btn-sm w-100" (click)="configReporteUnido(); cdExport.accept()">Unido</button>
    </div>
    <div class="col-4">
      <button type="button" class="btn-gl-neutral btn-neutral-hover btn-sm w-100" (click)="configReporteSeparado(); cdExport.accept()">Separado</button>
    </div>
  </ng-template>
</p-confirmDialog>
<div *ngIf="(reportService.modalActive)">
  <app-modal></app-modal>
</div>

<div *ngIf="(reportService.modalReportesProgramadosActive)">
  <app-modal-reportes-programados></app-modal-reportes-programados>
</div>

<p-dialog header="Detalle del Exceso" [(visible)]="modalDetalleRepExcesosEnZona"  [style]="{width: '50vw'}" >
  <ng-container >
    <table  class="table table-striped table-border row-border hover" [style]="{'font-size': '11px'}">
      <thead>
        <tr>
            <th>Nº</th>
            <th>Fecha</th>
            <th>Velocidad</th>
            <th>Coordenada</th>
            <th>V.Límite</th>
            <th>V.Tolerable</th>
            <th>V.Grave</th>
            <th>V.Muy Grave</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of detalleExcesosEnZona.detalle;  let i = index;">
            <td>{{i + 1}}</td>
            <td>{{row[0]}}</td>
            <td>{{row[3]}}</td>
            <td>
              <a href="http://maps.google.com/maps?q={{row[1]}},{{row[2]}}&amp;t=m" target="_blank">{{row[1]}},{{row[2]}}</a>
            </td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta == 'false'">{{detalleExcesosEnZona.v0}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta == 'false'">{{detalleExcesosEnZona.v1}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta == 'false'">{{detalleExcesosEnZona.v2}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta == 'false'">{{detalleExcesosEnZona.v3}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta != 'false'">{{detalleExcesosEnZona.v0_camioneta}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta != 'false'">{{detalleExcesosEnZona.v1_camioneta}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta != 'false'">{{detalleExcesosEnZona.v2_camioneta}}</td>
            <td *ngIf="detalleExcesosEnZona.isCamioneta != 'false'">{{detalleExcesosEnZona.v3_camioneta}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalDetalleRepExcesosEnZona=false" label="Aceptar" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>


<p-dialog header="Detalle del Exceso" [(visible)]="modalDetalleRepNoRostro"  [style]="{width: '50vw'}" >
  <ng-container >
    <table  class="table table-striped table-border row-border hover" [style]="{'font-size': '11px'}">
      <thead>
        <tr>
            <th>Nº</th>
            <th>Fecha</th>
            <th>Velocidad</th>
            <th>ai1</th>
            <th>Coordenada</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of detalleNoRostro.detalle;  let i = index;">
            <td style="text-align:center;">{{i + 1}}</td>
            <td style="text-align:center;">{{row[1]}}</td>
            <td style="text-align:center;">{{row[5]}} km/h</td>
            <td style="text-align:center;">{{row[6]}}</td>
            <td style="text-align:center;">
              <a href="http://maps.google.com/maps?q={{row[3]}},{{row[4]}}&amp;t=m" target="_blank">{{row[3]}},{{row[4]}}</a>
            </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalDetalleRepNoRostro=false" label="Aceptar" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Detalle Fatiga Extrema" [(visible)]="modalDetalleRepFatigaExtrema"  [style]="{width: '50vw'}" >
  <ng-container >
    <table  class="table table-striped table-border row-border hover" [style]="{'font-size': '11px'}">
      <thead>
        <tr>
            <th>Nº</th>
            <th>Fecha</th>
            <th>Velocidad</th>
            <th>Coordenada</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of detalleFatigaExtrema.detalle;  let i = index;">
            <td style="text-align:center;">{{i + 1}}</td>
            <td style="text-align:center;">{{row[2]}}</td>
            <td style="text-align:center;">{{row[3]}} km/h</td>
            <td style="text-align:center;">
              <a href="http://maps.google.com/maps?q={{row[4]}},{{row[5]}}&amp;t=m" target="_blank">{{row[4].toFixed(6)}},{{row[5].toFixed(6)}}</a>
            </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="modalDetalleRepFatigaExtrema=false" label="Aceptar" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>


<div style="display: flex; flex-direction: column; padding: 0; height: 100%; max-height: 100%; margin: 0; font-size: 0.8rem;">
  <div *ngIf="dt_completed == 0" class="pristine-report">
    <img src="./assets/images/fondo-svg-reporte.svg" alt="fondo-reporte-logo">
  </div>
  <div [ngClass]="table_hide" class="container grand-container">
    <!-- <div *ngIf="report_data.num_rep >= 0 && report_data.num_rep <= 22 " class="table-title p-card" style="margin-bottom: 0.25rem;">{{report_data.rep_title}}</div> -->
    <div class="table-title p-card" style="margin-bottom: 0.25rem;">{{report_data.rep_title}} <span style="font-size: 1.5rem;"> {{ str_nombre_eventos }}</span> </div>

    <div class="table-subtitle p-card" style="margin-bottom: 0.25rem;">{{ report_data.rep_subtitle }}</div>
  </div>
  <ng-container>

    <!-- 0. REPORTE DE PARADAS Y MOVIMIENTOS -->

    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R001'">
      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 0.8rem;">
          <!-- <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;"> -->

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <!-- <div>{{v_table[0][1]}}</div> -->
                <div>
                  <div class="gl-row-underline vehicle-dropdown border-0">
                    <p-dropdown
                          [filter]="true"
                          [options]="reportTableVehicleDropdownOptions"
                          optionLabel="name"
                          [(ngModel)]="reportTableVehicleSelected"
                          [appendTo]="'body'"
                          (onChange)="renderDataTable()">
                          <ng-template let-vehicle pTemplate="item">
                              <div class="country-item">
                                  <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                              </div>
                          </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div>
                <div>PERIODO</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                    <th>Ítem</th>
                    <th>Estado</th>
                    <th *ngIf="!chkDateHour">Inicio</th>
                    <th *ngIf="chkDateHour">Fecha de Inicio</th>
                    <th *ngIf="chkDateHour">Hora de Inicio</th>
                    <th *ngIf="!chkDateHour">Fin</th>
                    <th *ngIf="chkDateHour">Fecha de Fin</th>
                    <th *ngIf="chkDateHour">Hora de Fin</th>
                    <th>Duración</th>
                    <th *ngIf="reportTableDropdownData[2].Paradas">Ubicación</th> <!-- Posición detenida -->
                    <th *ngIf="reportTableDropdownData[2].Movimientos">Distancia Recorrida</th><!-- Recorrido -->
                    <th *ngIf="reportTableDropdownData[2].Movimientos">Velocidad Máxima GPS</th>
                    <th *ngIf="reportTableDropdownData[2].Movimientos">Velocidad Máxima CAN</th>
                    <th *ngIf="reportTableDropdownData[2].Paradas">Punto cercano</th>
                    <th *ngIf="reportTableDropdownData[2].Movimientos">Velocidad Promedio</th>
                    <th *ngIf="reportTableDropdownData[2].Movimientos">Vel. prom. CAN</th>
                    <th *ngIf="reportTableDropdownData[2].Paradas">Referencia</th>
                    <!-- <th *ngIf="v_table[2].Paradas">Posición detenida</th>
                    <th *ngIf="v_table[2].Movimientos">Recorrido</th>
                    <th *ngIf="v_table[2].Movimientos">Velocidad Máxima</th>
                    <th *ngIf="v_table[2].Movimientos">Vel. máx. CAN</th>
                    <th *ngIf="v_table[2].Paradas">Punto cercano</th>
                    <th *ngIf="v_table[2].Movimientos">Velocidad Promedio</th>
                    <th *ngIf="v_table[2].Movimientos">Vel. prom. CAN</th>
                    <th *ngIf="v_table[2].Paradas">Referencia</th> -->
                  </tr>
                </thead>

                <!-- PARADAS Y MOVIMIENTOS -->
                <tbody *ngIf="reportTableDropdownData[2].Paradas && reportTableDropdownData[2].Movimientos">
                <!-- <tbody *ngIf="v_table[2].Paradas && v_table[2].Movimientos"> -->
                  <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <!-- <tr *ngFor="let row of v_table[1];  let i = index;"> -->
                    <td>{{i+1}}</td>
                    <td>{{row.estado}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fechasig}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[1]}}</td>
                    <td>{{row.duracion}}</td>
                    <td *ngIf="row.esInt == 0">
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td><td *ngIf="row.esInt == 1">---</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.recorrido}}</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.maxima_velocidad}}</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.vel_max_can}}</td>

                    <td *ngIf="row.esInt == 0">{{row.pCercano}}</td><td *ngIf="row.esInt == 1">---</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.vel_promedio}}</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.vel_prom_can}}</td>

                    <td *ngIf="row.esInt == 0">{{row.referencia}}</td><td *ngIf="row.esInt == 1">---</td>
                  </tr>
                </tbody>

                <!-- SOLO MOVIMIENTOS -->
                <tbody *ngIf="!reportTableDropdownData[2].Paradas && reportTableDropdownData[2].Movimientos">
                <!-- <tbody *ngIf="!v_table[2].Paradas && v_table[2].Movimientos"> -->
                  <tr *ngFor="let row of filterMovimientos(reportTableDropdownData[1]);  let i = index;">
                  <!-- <tr *ngFor="let row of filterMovimientos(v_table[1]);  let i = index;"> -->
                    <td>{{i+1}}</td>
                    <td>{{row.estado}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fechasig}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[1]}}</td>
                    <td>{{row.duracion}}</td>
                    <td>{{row.recorrido}}</td>
                    <td>{{row.maxima_velocidad}}</td>
                    <td>{{row.vel_max_can}}</td>

                    <td>{{row.vel_promedio}}</td>
                    <td>{{row.vel_prom_can}}</td>

                  </tr>
                </tbody>

                <!-- SOLO PARADAS -->
                <tbody *ngIf="reportTableDropdownData[2].Paradas && !reportTableDropdownData[2].Movimientos">
                <!-- <tbody *ngIf="v_table[2].Paradas && !v_table[2].Movimientos"> -->
                  <tr *ngFor="let row of filterParadas(reportTableDropdownData[1]);  let i = index;">
                  <!-- <tr *ngFor="let row of filterParadas(v_table[1]);  let i = index;"> -->
                    <td>{{i+1}}</td>
                    <td>{{row.estado}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fechasig}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[1]}}</td>
                    <td>{{row.duracion}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td>{{row.pCercano}}</td>
                    <td>{{row.referencia}}</td>
                  </tr>
                </tbody>

              </table>
            </ng-container>

            <div *ngIf="reportTableDropdownData[1].length == 0" class="p-card text-left" style="padding: 1rem;">
            <!-- <div *ngIf="v_table[1].length == 0" class="p-card text-left" style="padding: 1rem;"> -->
              <p>Ningún dato encontrado en su consulta.</p>
            </div>

            <div *ngIf="reportTableDropdownData[1].length != 0" class="p-card text-left" style="padding: 1rem;">
            <!-- <div *ngIf="v_table[1].length != 0" class="p-card text-left" style="padding: 1rem;"> -->
              <h6>RESUMEN DE TOTALES</h6>
              <p>Longitud de ruta       : <span>{{reportTableDropdownData[3][0]}}</span></p>
              <p>Duración de movimiento : <span>{{reportTableDropdownData[3][1]}}</span></p>
              <p>Duración de detenido   : <span>{{reportTableDropdownData[3][2]}}</span></p>
              <p>Velocidad más alta     : <span>{{reportTableDropdownData[3][3]}}</span></p>
              <p>Velocidad promedio     : <span>{{reportTableDropdownData[3][4]}}</span></p>
              <!-- <p>Longitud de ruta       : <span>{{v_table[3][0]}}</span></p>
              <p>Duración de movimiento : <span>{{v_table[3][1]}}</span></p>
              <p>Duración de detenido   : <span>{{v_table[3][2]}}</span></p>
              <p>Velocidad más alta     : <span>{{v_table[3][3]}}</span></p>
              <p>Velocidad promedio     : <span>{{v_table[3][4]}}</span></p> -->

              <!-- <p ng-if="res.optionUser() == 445">Horas Trabajadas en el Periodo : <span>{{v_table[3][5]}}</span></p>
              <p ng-if="res.optionUser() == 445">Total de Horas Trabajadas : <span>{{v_table[3][6]}}</span></p>

              <p ng-if="res.optionUser() == 394">Total de combustible consumido: <span>{{v_table[3][7]}}</span></p>
              <p ng-if="res.optionUser() == 394">Total de distancia recorrida(CAN): <span>{{v_table[3][8]}}</span></p>
              <p ng-if="res.optionUser() == 394">Rendimiento por galón: <span>{{v_table[3][9]}}</span></p> -->

            </div>

          </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

          <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <div>{{v_table[0][1]}}</div>
              </div>
              <div>
                <div>PERIODO</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                    <th>Ítem</th>
                    <th>Estado</th>
                    <th *ngIf="!chkDateHour">Inicio</th>
                    <th *ngIf="chkDateHour">Fecha de Inicio</th>
                    <th *ngIf="chkDateHour">Hora de Inicio</th>
                    <th *ngIf="!chkDateHour">Fin</th>
                    <th *ngIf="chkDateHour">Fecha de Fin</th>
                    <th *ngIf="chkDateHour">Hora de Fin</th>
                    <th>Duración</th>


                    <th *ngIf="v_table[2].Paradas">Ubicación</th> <!-- Posición detenida -->
                    <th *ngIf="v_table[2].Movimientos">Distancia Recorrida</th><!-- Recorrido -->
                    <th *ngIf="v_table[2].Movimientos">Velocidad Máxima GPS</th>
                    <th *ngIf="v_table[2].Movimientos">Velocidad Máxima CAN</th>
                    <th *ngIf="v_table[2].Paradas">Punto cercano</th>
                    <th *ngIf="v_table[2].Movimientos">Velocidad Promedio</th>
                    <th *ngIf="v_table[2].Movimientos">Vel. prom. CAN</th>
                    <th *ngIf="v_table[2].Paradas">Referencia</th>
                  </tr>
                </thead>

                <!-- PARADAS Y MOVIMIENTOS -->
                <tbody *ngIf="v_table[2].Paradas && v_table[2].Movimientos">
                  <tr *ngFor="let row of v_table[1];  let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{row.estado}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fechasig}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[1]}}</td>
                    <td>{{row.duracion}}</td>
                    <td *ngIf="row.esInt == 0">
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td><td *ngIf="row.esInt == 1">---</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.recorrido}}</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.maxima_velocidad}}</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.vel_max_can}}</td>

                    <td *ngIf="row.esInt == 0">{{row.pCercano}}</td><td *ngIf="row.esInt == 1">---</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.vel_promedio}}</td>
                    <td *ngIf="row.esInt == 0">---</td><td *ngIf="row.esInt == 1">{{row.vel_prom_can}}</td>

                    <td *ngIf="row.esInt == 0">{{row.referencia}}</td><td *ngIf="row.esInt == 1">---</td>
                  </tr>
                </tbody>

                <!-- SOLO MOVIMIENTOS -->
                <tbody *ngIf="!v_table[2].Paradas && v_table[2].Movimientos">
                  <tr *ngFor="let row of filterMovimientos(v_table[1]);  let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{row.estado}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fechasig}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[1]}}</td>
                    <td>{{row.duracion}}</td>
                    <td>{{row.recorrido}}</td>
                    <td>{{row.maxima_velocidad}}</td>
                    <td>{{row.vel_max_can}}</td>

                    <td>{{row.vel_promedio}}</td>
                    <td>{{row.vel_prom_can}}</td>

                  </tr>
                </tbody>

                <!-- SOLO PARADAS -->
                <tbody *ngIf="v_table[2].Paradas && !v_table[2].Movimientos">
                  <tr *ngFor="let row of filterParadas(v_table[1]);  let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{row.estado}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fechasig}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fechasig.split(' ')[1]}}</td>
                    <td>{{row.duracion}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td>{{row.pCercano}}</td>
                    <td>{{row.referencia}}</td>
                  </tr>
                </tbody>

              </table>
            </ng-container>

            <div *ngIf="v_table[1].length == 0" class="p-card text-left" style="padding: 1rem;">
              <p>Ningún dato encontrado en su consulta.</p>
            </div>

            <div *ngIf="v_table[1].length != 0" class="p-card text-left" style="padding: 1rem;">
              <h6>RESUMEN DE TOTALES</h6>
              <p>Longitud de ruta       : <span>{{v_table[3][0]}}</span></p>
              <p>Duración de movimiento : <span>{{v_table[3][1]}}</span></p>
              <p>Duración de detenido   : <span>{{v_table[3][2]}}</span></p>
              <p>Velocidad más alta     : <span>{{v_table[3][3]}}</span></p>
              <p>Velocidad promedio     : <span>{{v_table[3][4]}}</span></p>

              <!-- <p ng-if="res.optionUser() == 445">Horas Trabajadas en el Periodo : <span>{{v_table[3][5]}}</span></p>
              <p ng-if="res.optionUser() == 445">Total de Horas Trabajadas : <span>{{v_table[3][6]}}</span></p>

              <p ng-if="res.optionUser() == 394">Total de combustible consumido: <span>{{v_table[3][7]}}</span></p>
              <p ng-if="res.optionUser() == 394">Total de distancia recorrida(CAN): <span>{{v_table[3][8]}}</span></p>
              <p ng-if="res.optionUser() == 394">Rendimiento por galón: <span>{{v_table[3][9]}}</span></p> -->

            </div>

          </div>

      </ng-container>

    </div>

    <!-- 1. REPORTE DE EXCESO DE VELOCIDAD -->

    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R002'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                          <div class="country-item">
                              <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                          </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered && !chkDuracion">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th>Nombre</th>
                  <th *ngIf="!chkDateHour">Fecha y Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Máxima Velocidad</th>
                  <th>Ubicación</th>
                  <th>Punto Cercano</th>
                  <th>Referencia</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td>{{row.nombre}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td>{{row.maxima_velocidad}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td>{{row.PC}}</td>
                  <td>{{row.referencia}}</td>
                </tr>
              </tbody>

            </table>
          </ng-container>

          <ng-container *ngIf="dtRendered && chkDuracion">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th *ngIf="!chkDateHour">Inicio</th>
                  <th *ngIf="chkDateHour">Fecha de Inicio</th>
                  <th *ngIf="chkDateHour">Hora de Inicio</th>
                  <th *ngIf="!chkDateHour">Fin</th>
                  <th *ngIf="chkDateHour">Fecha de Fin</th>
                  <th *ngIf="chkDateHour">Hora de Fin</th>
                  <th>Duración</th>
                  <th>Cant. de Tramas</th>
                  <th>Tramas</th>
                  <th>Vel. Min.</th>
                  <th>Vel. Máx.</th>
                  <th>Punto Cercano</th>
                  <th>Zona Cercana</th>
                  <th>Ubicación</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_inicial}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicial.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicial.split(' ')[1]}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_final}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[1]}}</td>
                  <td>{{row.duracion}}</td>
                  <td>{{row.cant_tramas}}</td>
                  <td>{{row.string_tramas}}</td>
                  <td>{{row.vel_min}}</td>
                  <td>{{row.vel_max}}</td>
                  <td>{{row.PC}}</td>
                  <td>{{row.ZC}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

          <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered && !chkDuracion">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th>Nombre</th>
                  <th *ngIf="!chkDateHour">Fecha y Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Máxima Velocidad</th>
                  <th>Ubicación</th>
                  <th>Punto Cercano</th>
                  <th>Referencia</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td>{{row.nombre}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td>{{row.maxima_velocidad}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td>{{row.PC}}</td>
                  <td>{{row.referencia}}</td>
                </tr>
              </tbody>

            </table>
          </ng-container>

          <ng-container *ngIf="dtRendered && chkDuracion">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th *ngIf="!chkDateHour">Inicio</th>
                  <th *ngIf="chkDateHour">Fecha de Inicio</th>
                  <th *ngIf="chkDateHour">Hora de Inicio</th>
                  <th *ngIf="!chkDateHour">Fin</th>
                  <th *ngIf="chkDateHour">Fecha de Fin</th>
                  <th *ngIf="chkDateHour">Hora de Fin</th>
                  <th>Duración</th>
                  <th>Cant. de Tramas</th>
                  <th>Tramas</th>
                  <th>Vel. Min.</th>
                  <th>Vel. Máx.</th>
                  <th>Punto Cercano</th>
                  <th>Zona Cercana</th>
                  <th>Ubicación</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_inicial}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicial.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicial.split(' ')[1]}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_final}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[1]}}</td>
                  <td>{{row.duracion}}</td>
                  <td>{{row.cant_tramas}}</td>
                  <td>{{row.string_tramas}}</td>
                  <td>{{row.vel_min}}</td>
                  <td>{{row.vel_max}}</td>
                  <td>{{row.PC}}</td>
                  <td>{{row.ZC}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 2. REPORTE DE ENTRADA Y SALIDA -->

    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R003'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th>Fecha de Entrada</th>
                  <th *ngIf="chkDateHour">Hora de Entrada</th>
                  <th>Fecha de Salida</th>
                  <th *ngIf="chkDateHour">Hora de Salida</th>
                  <th>Duración</th>
                  <th>Nombre de Geocerca</th>
                  <th>Posición de Geocerca</th>
                  <th>Punto Cercano</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_out}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_out.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_out.split(' ')[1]}}</td>
                  <td>{{row.duracion}}</td>
                  <td>{{row.nombre_zona}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td>{{row.PC}}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th>Fecha de Entrada</th>
                  <th *ngIf="chkDateHour">Hora de Entrada</th>
                  <th>Fecha de Salida</th>
                  <th *ngIf="chkDateHour">Hora de Salida</th>
                  <th>Duración</th>
                  <th>Nombre de Geocerca</th>
                  <th>Posición de Geocerca</th>
                  <th>Punto Cercano</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_out}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_out.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_out.split(' ')[1]}}</td>
                  <td>{{row.duracion}}</td>
                  <td>{{row.nombre_zona}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td>{{row.PC}}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>

        </div>

      </ng-container>


    </div>

    <!-- 3. REPORTE DE COMBUSTIBLE -->

    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R004'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 0.8rem;">
          <!-- <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;"> -->

            <!-- <div class="subtable-title mb-2">
              <div>VEHÍCULO: {{v_table[0][1]}}</div>
              <div>PERIODO: {{period}}</div>
            </div> -->

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <!-- <div>{{v_table[0][1]}}</div> -->
                <div>
                  <div class="gl-row-underline vehicle-dropdown border-0">
                    <p-dropdown
                          [filter]="true"
                          [options]="reportTableVehicleDropdownOptions"
                          optionLabel="name"
                          [(ngModel)]="reportTableVehicleSelected"
                          [appendTo]="'body'"
                          (onChange)="renderDataTable()">
                          <ng-template let-vehicle pTemplate="item">
                              <div class="country-item">
                                  <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                              </div>
                          </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div>
                <div>PERIODO</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                    <th>Ítem</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>

                    <th>Vel. CAN (km/h)</th>
                    <th>Nivel de Combustible (%)</th>
                    <th>Consumo de Combustible (litros)</th>
                    <th>Consumo HRFC (litros)</th>
                    <th>Combustible Restante (galones)</th>
                    <th>Contacto de Motor</th>

                    <th *ngIf="!chkOdomV">Odómetro</th>
                    <th *ngIf="chkOdomV">Odómetro Virtual</th>
                    <th>Ubicación</th>
                    <!-- <th>Mensaje de Alerta</th>
                    <th>Combustible Alerta (galones)</th> -->
                    <th>can_rpm</th>
                    <th>Altitud</th>

                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <!-- <tr *ngFor="let row of v_table[1];  let i = index;"> -->
                    <td>{{i+1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td>{{row.can_speed}}</td>
                    <td>{{row.fuel_level}}</td>
                    <td>{{row.fuel_used}}</td>
                    <td>{{row.can_HRFC}}</td>
                    <td>{{row.combusrestante}}</td>
                    <td>{{row.di4}}</td>

                    <td *ngIf="!chkOdomV">{{row.can_dist}}</td>
                    <td *ngIf="chkOdomV">{{row.virtual_odom}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.alerta}}</td>
                    <td>{{row.combustiblealerta}}</td> -->
                    <td>{{row.can_rpm}}</td>
                    <td>{{row.altitude}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <div *ngIf="reportTableDropdownData[1].length == 0" class="p-card text-left" style="padding: 1rem;">
            <!-- <div *ngIf="v_table[1].length == 0" class="p-card text-left" style="padding: 1rem;"> -->
              <p>Ningún dato encontrado en su consulta.</p>
            </div>

            <div *ngIf="reportTableDropdownData[1].length != 0" class="p-card text-left" style="padding: 1rem;">
            <!-- <div *ngIf="v_table[1].length != 0" class="p-card text-left" style="padding: 1rem;"> -->
              <h6>RESUMEN DE TOTALES</h6>
              <p>Total de distancia recorrida           : <span>{{reportTableDropdownData[2][0]}}</span></p>
              <p>Total de combustible consumido         : <span>{{reportTableDropdownData[2][1]}}</span></p>
              <p>Total de combustible HRFC consumido    : <span>{{reportTableDropdownData[2][2]}}</span></p>
              <p>Rendimiento por galón                  : <span>{{reportTableDropdownData[2][3]}}</span></p>
              <!-- <p>Total de distancia recorrida           : <span>{{v_table[2][0]}}</span></p>
              <p>Total de combustible consumido         : <span>{{v_table[2][1]}}</span></p>
              <p>Total de combustible HRFC consumido    : <span>{{v_table[2][2]}}</span></p>
              <p>Rendimiento por galón                  : <span>{{v_table[2][3]}}</span></p> -->
            </div>

          </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

          <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <div>{{v_table[0][1]}}</div>
              </div>
              <div>
                <div>PERIODO</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                    <th>Ítem</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    
                    <th>Vel. CAN (km/h)</th>
                    <th>Nivel de Combustible (%)</th>
                    <th>Consumo de Combustible (litros)</th>
                    <th>Consumo HRFC (litros)</th>
                    <th>Combustible Restante (galones)</th>
                    <th>Contacto de Motor</th>

                    <th *ngIf="!chkOdomV">Odómetro</th>
                    <th *ngIf="chkOdomV">Odómetro Virtual</th>
                    <th>Ubicación</th>
                    <th>can_rpm</th>
                    <th>Altitud</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of v_table[1];  let i = index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td>{{row.can_speed}}</td>
                    <td>{{row.fuel_level}}</td>
                    <td>{{row.fuel_used}}</td>
                    <td>{{row.can_HRFC}}</td>
                    <td>{{row.combusrestante}}</td>
                    <td>{{row.di4}}</td>
                    
                    <td *ngIf="!chkOdomV">{{row.can_dist}}</td>
                    <td *ngIf="chkOdomV">{{row.virtual_odom}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td>{{row.can_rpm}}</td>
                    <td>{{row.altitude}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <div *ngIf="v_table[1].length == 0" class="p-card text-left" style="padding: 1rem;">
              <p>Ningún dato encontrado en su consulta.</p>
            </div>

            <div *ngIf="v_table[1].length != 0" class="p-card text-left" style="padding: 1rem;">
              <h6>RESUMEN DE TOTALES</h6>
              <p>Total de distancia recorrida           : <span>{{v_table[2][0]}}</span></p>
              <p>Total de combustible consumido         : <span>{{v_table[2][1]}}</span></p>
              <p>Total de combustible HRFC consumido    : <span>{{v_table[2][2]}}</span></p>
              <p>Rendimiento por galón                  : <span>{{v_table[2][3]}}</span></p>
            </div>

          </div>

      </ng-container>

    </div>


    <!-- 4. REPORTE DE EXCESOS EN ZONA-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R005'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">
          <!-- <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;"> -->

            <!-- <div class="subtable-title mb-2">
              <div>VEHÍCULO: {{v_table[0][1]}}</div>
              <div>PERIODO: {{period}}</div>
            </div> -->

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <!-- <div>{{v_table[0][1]}}</div> -->
                <div>
                  <div class="gl-row-underline vehicle-dropdown border-0">
                    <p-dropdown
                          [filter]="true"
                          [options]="reportTableVehicleDropdownOptions"
                          optionLabel="name"
                          [(ngModel)]="reportTableVehicleSelected"
                          [appendTo]="'body'"
                          (onChange)="renderDataTable()">
                          <ng-template let-vehicle pTemplate="item">
                              <div class="country-item">
                                  <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                              </div>
                          </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div>
                <div>PERIODO -1-</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                      <th>Item</th>
                      <th>Convoy</th>
                      <th>Código</th>
                      <th>Placa</th>

                      <th>Vel. Límite</th>
                      <th>Exceso vel maxima</th>

                      <th *ngIf="!chkDateHour">Fecha/Hora Inicio</th>
                      <th *ngIf="chkDateHour">Fecha de Inicio</th>
                      <th *ngIf="chkDateHour">Hora de Inicio</th>

                      <th *ngIf="!chkDateHour">Fecha/Hora Final</th>
                      <th *ngIf="chkDateHour">Fecha Final</th>
                      <th *ngIf="chkDateHour">Hora Final</th>

                      <th>Duración</th>
                      <th>Tramo</th>
                      <th>Referencia</th>
                      <th>Ubicación</th>
                      <th>Detalle</th>

                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <!-- <tr *ngFor="let row of v_table[1];  let i = index;"> -->

                      <td>{{i + 1}}</td>
                      <td>{{row.convoy}}</td>
                      <td>{{row.codigo}}</td>
                      <td>{{row.tracto}}</td>

                      <td>{{row.v0}}</td>
                      <td>{{row.velocidad}}</td>

                      <td *ngIf="!chkDateHour">{{row.fecha_inicio}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[0]}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[1]}}</td>

                      <td *ngIf="!chkDateHour">{{row.fecha_final}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[0]}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[1]}}</td>

                      <td>{{row.duracion2}}</td>
                      <td>{{row.nombre_zona}}</td>
                      <td>{{row.referencia}}</td>

                      <td>
                        <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                      </td>
                      <td>
                        <button class="btn btn-success" (click)="showModalExcesosEnZona(row);">Detalle</button>
                      </td>
                  </tr>
                </tbody>

              </table>
            </ng-container>

          </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

          <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO -2-</div>
                <div>{{v_table[0][1]}}</div>
              </div>
              <div>
                <div>PERIODO--</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                      <th>Item</th>
                      <th>Convoy</th>
                      <th>Código</th>
                      <th>Placa</th>

                      <th>Vel. Límite</th>
                      <th>Exceso vel maxima</th>

                      <th *ngIf="!chkDateHour">Fecha/Hora Inicio</th>
                      <th *ngIf="chkDateHour">Fecha de Inicio</th>
                      <th *ngIf="chkDateHour">Hora de Inicio</th>

                      <th *ngIf="!chkDateHour">Fecha/Hora Final</th>
                      <th *ngIf="chkDateHour">Fecha Final</th>
                      <th *ngIf="chkDateHour">Hora Final</th>

                      <th>Duración</th>
                      <th>Tramo</th>
                      <th>Referencia</th>
                      <th>Ubicación</th>
                      <th>Detalle</th>

                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of v_table[1];  let i = index;">

                      <td>{{i + 1}}</td>
                      <td>{{row.convoy}}</td>
                      <td>{{row.codigo}}</td>
                      <td>{{row.tracto}}</td>

                      <td>{{row.v0}}</td>
                      <td>{{row.velocidad}}</td>

                      <td *ngIf="!chkDateHour">{{row.fecha_inicio}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[0]}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[1]}}</td>

                      <td *ngIf="!chkDateHour">{{row.fecha_final}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[0]}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[1]}}</td>

                      <td>{{row.duracion2}}</td>
                      <td>{{row.nombre_zona}}</td>
                      <td>{{row.referencia}}</td>

                      <td>
                        <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                      </td>
                      <td>
                        <button class="btn btn-success" (click)="showModalExcesosEnZona(row);">Detalle</button>
                      </td>
                  </tr>
                </tbody>

              </table>
            </ng-container>

          </div>

      </ng-container>

    </div>


    <!-- 5. REPORTE GENERAL-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R006' || report_data.num_rep == 'R036'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">
          <!-- <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;"> -->

            <!-- <div class="subtable-title mb-2">
              <div>VEHÍCULO: {{v_table[0][1]}}</div>
              <div>PERIODO: {{period}}</div>
            </div> -->

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <!-- <div>{{v_table[0][1]}}</div> -->
                <div>
                  <div class="gl-row-underline vehicle-dropdown border-0">
                    <p-dropdown
                          [filter]="true"
                          [options]="reportTableVehicleDropdownOptions"
                          optionLabel="name"
                          [(ngModel)]="reportTableVehicleSelected"
                          [appendTo]="'body'"
                          (onChange)="renderDataTable()">
                          <ng-template let-vehicle pTemplate="item">
                              <div class="country-item">
                                  <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                              </div>
                          </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div>
                <div>PERIODO</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                      <th>Nº</th>
                      <th *ngIf="!chkDateHour">Fecha/Hora</th>
                      <th *ngIf="chkDateHour">Fecha</th>
                      <th *ngIf="chkDateHour">Hora</th>

                      <th *ngIf="reportTableDropdownData[2].fServidor && !chkDateHour">Fecha/Hora Servidor</th>
                      <th *ngIf="reportTableDropdownData[2].fServidor && chkDateHour">Fecha Servidor</th>
                      <th *ngIf="reportTableDropdownData[2].fServidor && chkDateHour">Hora Servidor</th>

                      <th *ngIf="reportTableDropdownData[2].pCercano" >Punto Cercano</th>

                      <th *ngIf="reportTableDropdownData[2].velCAN" >Velocidad CAN</th>
                      <th *ngIf="reportTableDropdownData[2].velGPS && report_data.num_rep != 'R036'" >Velocidad GPS</th>
                      <th *ngIf="reportTableDropdownData[2].velGPS && report_data.num_rep == 'R036'" >Velocidad 360</th>
                      <th *ngIf="reportTableDropdownData[2].velGPS_speed" >Velocidad GPS speed</th>
                      <th *ngIf="reportTableDropdownData[2].velMobileye_ME460" >Velocidad Mobileye</th>
                      <th *ngIf="reportTableDropdownData[2].velECO" >Velocidad ECO</th>

                      <th *ngIf="reportTableDropdownData[2].DUOT2state" >DUOT2 state</th>
                      <th *ngIf="reportTableDropdownData[2].RxM" >RPM</th>
                      <th *ngIf="reportTableDropdownData[2].RPMAlta" >RPM Alta</th>

                      <th *ngIf="reportTableDropdownData[2].cNivel" >Nivel de Combustible</th>
                      <th *ngIf="reportTableDropdownData[2].cRestante" >Combustible Restante</th>

                      <th *ngIf="reportTableDropdownData[2].cMotor" >Consumo Motor</th>
                      <th *ngIf="reportTableDropdownData[2].odometro" >Odómetro</th>

                      <th *ngIf="reportTableDropdownData[2].altitud" >Altitud</th>
                      <th *ngIf="reportTableDropdownData[2].angulo" >Angulo</th>

                      <th *ngIf="reportTableDropdownData[2].alimentGps" >Alimentación GPS</th>
                      <th *ngIf="reportTableDropdownData[2].nivelBateria" >Nivel de Batería</th>

                      <th *ngIf="reportTableDropdownData[2].nivelCobertura" >Nivel de Cobertura</th>
                      <th *ngIf="reportTableDropdownData[2].temperaturaGps" >Temperatura GPS</th>

                      <th *ngIf="reportTableDropdownData[2].satelite" >Satélite</th>
                      <th *ngIf="reportTableDropdownData[2].recFacial" >Reconocimiento Facial</th>

                      <th *ngIf="reportTableDropdownData[2].onOff" >Ignición</th>
                      <th *ngIf="reportTableDropdownData[2].alcoholemia" >Alcoholemia</th>

                      <th *ngIf="reportTableDropdownData[2].parametros" >Parámetros</th>
                      <th *ngIf="reportTableDropdownData[2].ubicacion" >Ubicación</th>
                      <th *ngIf="reportTableDropdownData[2].referencia" >Referencia</th>


                      <!-- NO SE USAN -->
                      <th *ngIf="reportTableDropdownData[2].fatiga" >Fatiga</th>
                      <!-- <th *ngIf="v_table[2].fExBrusca" >Frenada Extrema Brusca</th> -->
                      <th *ngIf="reportTableDropdownData[2].fBrusca" >Frenada Brusca</th>
                      <th *ngIf="reportTableDropdownData[2].aBrusca" >Aceleración Brusca</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <!-- <tr *ngFor="let row of v_table[1];  let i = index;"> -->

                      <td>{{i + 1}}</td>
                      <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                      <td *ngIf="reportTableDropdownData[2].fServidor && !chkDateHour">{{row.fServidor}}</td>
                      <td *ngIf="reportTableDropdownData[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[0]}}</td>
                      <td *ngIf="reportTableDropdownData[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[1]}}</td>
                      <!-- <td *ngIf="v_table[2].fServidor && !chkDateHour">{{row.fServidor}}</td>
                      <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[0]}}</td>
                      <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[0]}}</td> -->

                      <td *ngIf="reportTableDropdownData[2].pCercano" >{{row.pCercano}}</td>
                      <td *ngIf="reportTableDropdownData[2].velCAN" [ngStyle]="{'background-color': row.velCAN > reportTableDropdownData[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velCAN}}</td>
                      <td *ngIf="reportTableDropdownData[2].velGPS" [ngStyle]="{'background-color': row.velGPS > reportTableDropdownData[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velGPS}}</td>
                      <td *ngIf="reportTableDropdownData[2].velGPS_speed" >{{row.velGPS_speed}}</td>
                      <td *ngIf="reportTableDropdownData[2].velMobileye_ME460" >{{row.velMobileye_ME460}}</td>
                      <td *ngIf="reportTableDropdownData[2].velECO" [ngStyle]="{'background-color': row.velECO > reportTableDropdownData[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velECO}}</td>

                      <td *ngIf="reportTableDropdownData[2].DUOT2state" >{{row.DUOT2state}}</td>
                      <td *ngIf="reportTableDropdownData[2].RxM" >{{row.RxM}}</td>
                      <td *ngIf="reportTableDropdownData[2].RPMAlta" >{{row.RPMAlta}}</td>

                      <td *ngIf="reportTableDropdownData[2].cNivel" >{{row.cNivel}}</td>
                      <td *ngIf="reportTableDropdownData[2].cRestante" >{{row.cRestante}}</td>

                      <td *ngIf="reportTableDropdownData[2].cMotor" >{{row.cMotor}}</td>
                      <td *ngIf="reportTableDropdownData[2].odometro" >{{row.odometro}}</td>

                      <td *ngIf="reportTableDropdownData[2].altitud" >{{row.altitud}}</td>
                      <td *ngIf="reportTableDropdownData[2].angulo" >{{row.angulo}}</td>

                      <!-- NEW -->
                      <td *ngIf="reportTableDropdownData[2].alimentGps" >{{row.alimentGps}}</td>
                      <td *ngIf="reportTableDropdownData[2].nivelBateria" >{{row.nivelBateria}}</td>

                      <td *ngIf="reportTableDropdownData[2].nivelCobertura" >{{row.nivelCobertura}}</td>
                      <td *ngIf="reportTableDropdownData[2].temperaturaGps" >{{row.temperaturaGps}}</td>

                      <td *ngIf="reportTableDropdownData[2].satelite" >{{row.satelite}}</td>
                      <td *ngIf="reportTableDropdownData[2].recFacial" >{{row.recFacial}}</td>
                      <td *ngIf="reportTableDropdownData[2].onOff" >{{row.onOff}}</td>

                      <td *ngIf="reportTableDropdownData[2].alcoholemia && row.alcohol_state == 4" [ngStyle]="{'background-color': 'rgb(28, 243, 9)'}">{{row.alcohol_nombre}}</td>
                      <td *ngIf="reportTableDropdownData[2].alcoholemia && row.alcohol_state == 5" [ngStyle]="{'background-color': 'rgb(255,  0, 0)'}">{{row.alcohol_nombre}}</td>
                      <td *ngIf="reportTableDropdownData[2].alcoholemia && row.alcohol_state != 4 && row.alcohol_state != 5" >{{row.alcohol_nombre}}</td>

                      <td *ngIf="reportTableDropdownData[2].parametros" >{{row.parametros}}</td>
                      <td *ngIf="reportTableDropdownData[2].ubicacion">
                        <!-- <td *ngIf="v_table[2].ubicacion"> -->
                          <a href="http://maps.google.com/maps?q={{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}&amp;t=m" target="_blank">{{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}</a>
                        </td>
                      <td *ngIf="reportTableDropdownData[2].referencia" >
                        <!-- {{row.referencia}} -->
                        <span *ngIf="row.referencia != 'desconocido ...'" [innerHTML]="row.referencia">
                        </span>
                        <button *ngIf="row.referencia == 'desconocido ...'" class="btn btn-success" (click)="showGeneralDireccion(row, reportTableDropdownData[1]);">Ver</button>
                      </td>

                      <td *ngIf="reportTableDropdownData[2].fatiga" [ngStyle]="{'background-color': row.fatiga == 'si' ? 'rgb(28, 243, 9)' : {} }">{{row.fatiga}}</td>
                      <td *ngIf="reportTableDropdownData[2].fBrusca" >{{row.fBrusca}}</td>
                      <td *ngIf="reportTableDropdownData[2].aBrusca" >{{row.aBrusca}}</td>





											<!-- <td ng-if="dato[2].referencia" >
												<span ng-if="dat.referencia != 'NN'"  ng-bind-html="dat.referencia" ></span>
												<md-button ng-if="dat.referencia == 'NN'" class="md-primary md-raised btn-sm" ng-click="res.showGeneralDireccion( dat, reportTableDropdownData[1])"  >
													Ver
												</md-button>
											</td> -->





                  </tr>
                </tbody>

              </table>

              <div *ngIf="reportTableDropdownData[1].length > 0" class="p-card text-left" style="padding: 1rem;">

                  <h6>RESUMEN DE TOTALES</h6>
                  <p>TOTAL de Excesos de Velocidad(GPS) : <span>{{reportTableDropdownData[3][0]}}</span></p>
                  <p>TOTAL de Excesos de Velocidad(ECO) : <span>{{reportTableDropdownData[3][1]}}</span></p>
                  <p>TOTAL de Excesos de Velocidad(CAN) : <span>{{reportTableDropdownData[3][2]}}</span></p>

                  <p *ngIf="reportTableDropdownData[2].fatiga" >TOTAL de Fatigas : <span>{{reportTableDropdownData[3][3]}}</span></p>
                  <!-- <p ng-if="dato[2].fExBrusca" >TOTAL de Frenada Extremadamente Brusca : <span>{{dato[3][4]}}</span></p> -->
                  <p *ngIf="reportTableDropdownData[2].fBrusca" >TOTAL de Frenada Brusca : <span>{{reportTableDropdownData[3][5]}}</span></p>
                  <p *ngIf="reportTableDropdownData[2].RPMAlta" >TOTAL RPM Alta : <span>{{reportTableDropdownData[3][6]}}</span></p>
                  <p *ngIf="reportTableDropdownData[2].alcoholemia" >TOTAL de alerta de alcohol en la sangre : <span>{{reportTableDropdownData[3][7]}}</span></p>

              </div>

                <ng-container *ngIf="reportTableDropdownData[1].length > 0">

                    <div class="row mt-3">
                      <div class="col">
                      </div>
                      <div class="col">
                      </div>
                      <div class="col">
                      </div>
                      <div class="col">
                        <div class="col-md-auto">
                          <button class="btn btn-success" (click)="verTodos(period, reportTableDropdownData[0][0], chkDateHour, reportTableDropdownData[0][1], params);">Ver Todos</button>
                          <!-- <button class="btn btn-success" (click)="showModal();">Ver modal</button> -->

                        </div>
                      </div>
                    </div>

                </ng-container>

            </ng-container>

            <div *ngIf="reportTableDropdownData[1].length == 0" class="p-card text-left" style="padding: 1rem;">
              <p>Ningún dato encontrado en su consulta.</p>
            </div>

          </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

          <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

            <div class="subtable-title mb-3">
              <div>
                <div>VEHÍCULO</div>
                <div>{{v_table[0][1]}}</div>
              </div>
              <div>
                <div>PERIODO</div>
                <div>{{period}}</div>
              </div>
            </div>

            <ng-container *ngIf="dtRendered">
              <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
                <thead>
                  <tr>
                      <th>Nº</th>
                      <th *ngIf="!chkDateHour">Fecha/Hora</th>
                      <th *ngIf="chkDateHour">Fecha</th>
                      <th *ngIf="chkDateHour">Hora</th>

                      <th *ngIf="v_table[2].fServidor && !chkDateHour">Fecha/Hora Servidor</th>
                      <th *ngIf="v_table[2].fServidor && chkDateHour">Fecha Servidor</th>
                      <th *ngIf="v_table[2].fServidor && chkDateHour">Hora Servidor</th>

                      <th *ngIf="v_table[2].pCercano" >Punto Cercano</th>

                      <th *ngIf="v_table[2].velCAN" >Velocidad CAN</th>
                      <th *ngIf="v_table[2].velGPS && report_data.num_rep != 'R036'" >Velocidad GPS</th>
                      <th *ngIf="v_table[2].velGPS && report_data.num_rep == 'R036'" >Velocidad 360</th>
                      <th *ngIf="v_table[2].velGPS_speed" >Velocidad GPS speed</th>
                      <th *ngIf="v_table[2].velMobileye_ME460" >Velocidad Mobileye</th>
                      <th *ngIf="v_table[2].velECO" >Velocidad ECO</th>
                      <th *ngIf="v_table[2].DUOT2state" >DUOT2 state</th>

                      <th *ngIf="v_table[2].RxM" >RPM</th>
                      <th *ngIf="v_table[2].RPMAlta" >RPM Alta</th>

                      <th *ngIf="v_table[2].cNivel" >Nivel de Combustible</th>
                      <th *ngIf="v_table[2].cRestante" >C.Restante</th>

                      <th *ngIf="v_table[2].cMotor" >C.Motor</th>
                      <th *ngIf="v_table[2].odometro" >Odómetro</th>

                      <th *ngIf="v_table[2].altitud" >Altitud</th>
                      <th *ngIf="v_table[2].angulo" >Angulo</th>

                      <th *ngIf="v_table[2].alimentGps" >Alimentación GPS</th>
                      <th *ngIf="v_table[2].nivelBateria" >Nivel de Batería</th>

                      <th *ngIf="v_table[2].nivelCobertura" >Nivel de Cobertura</th>
                      <th *ngIf="v_table[2].temperaturaGps" >Temperatura GPS</th>

                      <th *ngIf="v_table[2].satelite" >Satélite</th>
                      <th *ngIf="v_table[2].recFacial" >Reconocimiento Facial</th>

                      <th *ngIf="v_table[2].onOff" >Ignición</th>
                      <th *ngIf="v_table[2].alcoholemia" >Alcoholemia</th>

                      <th *ngIf="v_table[2].parametros" >Parámetros</th>
                      <th *ngIf="v_table[2].ubicacion" >Ubicación</th>

                      <th *ngIf="v_table[2].referencia" >Referencia</th>

                      <!-- YA NO SE USAN -->
                      <th *ngIf="v_table[2].fatiga" >Fatiga</th>
                      <!-- <th *ngIf="v_table[2].fExBrusca" >Frenada Extrema Brusca</th> -->
                      <th *ngIf="v_table[2].fBrusca" >Frenada Brusca</th>
                      <th *ngIf="v_table[2].aBrusca" >Aceleración Brusca</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of v_table[1];  let i = index;">

                      <td>{{i + 1}}</td>
                      <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                      <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                      <td *ngIf="v_table[2].fServidor && !chkDateHour">{{row.fServidor}}</td>
                      <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[0]}}</td>
                      <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[1]}}</td>

                      <td *ngIf="v_table[2].pCercano" >{{row.pCercano}}</td>

                      <td *ngIf="v_table[2].velCAN" [ngStyle]="{'background-color': row.velCAN > v_table[3][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velCAN}}</td>
                      <td *ngIf="v_table[2].velGPS" [ngStyle]="{'background-color': row.velGPS > v_table[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velGPS}}</td>
                      <td *ngIf="v_table[2].velGPS_speed" >{{row.velGPS_speed}}</td>
                      <td *ngIf="v_table[2].velMobileye_ME460" >{{row.velMobileye_ME460}}</td>
                      <td *ngIf="v_table[2].velECO" [ngStyle]="{'background-color': row.velECO > v_table[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velECO}}</td>

                      <td *ngIf="v_table[2].DUOT2state" >{{row.DUOT2state}}</td>
                      <td *ngIf="v_table[2].RxM" >{{row.RxM}}</td>
                      <td *ngIf="v_table[2].RPMAlta" >{{row.RPMAlta}}</td>

                      <td *ngIf="v_table[2].cNivel" >{{row.cNivel}}</td>
                      <td *ngIf="v_table[2].cRestante" >{{row.cRestante}}</td>

                      <td *ngIf="v_table[2].cMotor" >{{row.cMotor}}</td>
                      <td *ngIf="v_table[2].odometro" >{{row.odometro}}</td>

                      <td *ngIf="v_table[2].altitud" >{{row.altitud}}</td>
                      <td *ngIf="v_table[2].angulo" >{{row.angulo}}</td>

                      <!-- NEW -->
                      <td *ngIf="v_table[2].alimentGps" >{{row.alimentGps}}</td>
                      <td *ngIf="v_table[2].nivelBateria" >{{row.nivelBateria}}</td>

                      <td *ngIf="v_table[2].nivelCobertura" >{{row.nivelCobertura}}</td>
                      <td *ngIf="v_table[2].temperaturaGps" >{{row.temperaturaGps}}</td>

                      <td *ngIf="v_table[2].satelite" >{{row.satelite}}</td>
                      <td *ngIf="v_table[2].recFacial" >{{row.recFacial}}</td>

                      <td *ngIf="v_table[2].onOff" >{{row.onOff}}</td>
                      <td *ngIf="v_table[2].alcoholemia && row.alcohol_state == 4" [ngStyle]="{'background-color': 'rgb(28, 243, 9)'}">{{row.alcohol_nombre}}</td>
                      <td *ngIf="v_table[2].alcoholemia && row.alcohol_state == 5" [ngStyle]="{'background-color': 'rgb(255,  0, 0)'}">{{row.alcohol_nombre}}</td>
                      <td *ngIf="v_table[2].alcoholemia && row.alcohol_state != 4 && row.alcohol_state != 5" >{{row.alcohol_nombre}}</td>

                      <td *ngIf="v_table[2].parametros" >{{row.parametros}}</td>
                      <td *ngIf="v_table[2].ubicacion">
                        <a href="http://maps.google.com/maps?q={{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}&amp;t=m" target="_blank">{{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}</a>
                      </td>
                      <td *ngIf="v_table[2].referencia" >
                        <!-- {{row.referencia}} -->
                        <span *ngIf="row.referencia != 'desconocido ...'" [innerHTML]="row.referencia"></span>
                        <button *ngIf="row.referencia == 'desconocido ...'" class="btn btn-success" (click)="showGeneralDireccion(row, v_table[1]);">Ver</button>
                      </td>

                      <td *ngIf="v_table[2].fatiga" [ngStyle]="{'background-color': row.fatiga == 'si' ? 'rgb(28, 243, 9)' : {} }">{{row.fatiga}}</td>
                      <td *ngIf="v_table[2].fBrusca" >{{row.fBrusca}}</td>
                      <td *ngIf="v_table[2].aBrusca" >{{row.aBrusca}}</td>




                      <!-- <td *ngIf="v_table[2].referencia" >{{row.referencia}}</td> -->





                  </tr>
                </tbody>

              </table>

              <div *ngIf="v_table[1].length > 0" class="p-card text-left" style="padding: 1rem;">

                  <h6>RESUMEN DE TOTALES</h6>
                  <p>TOTAL de Excesos de Velocidad(GPS) : <span>{{v_table[3][0]}}</span></p>
                  <p>TOTAL de Excesos de Velocidad(ECO) : <span>{{v_table[3][1]}}</span></p>
                  <p>TOTAL de Excesos de Velocidad(CAN) : <span>{{v_table[3][2]}}</span></p>

                  <p *ngIf="v_table[2].fatiga" >TOTAL de Fatigas : <span>{{v_table[3][3]}}</span></p>
                  <!-- <p ng-if="dato[2].fExBrusca" >TOTAL de Frenada Extremadamente Brusca : <span>{{dato[3][4]}}</span></p> -->
                  <p *ngIf="v_table[2].fBrusca" >TOTAL de Frenada Brusca : <span>{{v_table[3][5]}}</span></p>
                  <p *ngIf="v_table[2].RPMAlta" >TOTAL RPM Alta : <span>{{v_table[3][6]}}</span></p>
                  <p *ngIf="v_table[2].alcoholemia" >TOTAL de alerta de alcohol en la sangre : <span>{{v_table[3][7]}}</span></p>

              </div>

                <ng-container *ngIf="v_table[1].length > 0">

                    <div class="row mt-3">
                      <div class="col">
                      </div>
                      <div class="col">
                      </div>
                      <div class="col">
                      </div>
                      <div class="col">
                        <div class="col-md-auto">
                          <button class="btn btn-success" (click)="verTodos(period, v_table[0][0], chkDateHour, v_table[0][1], params);">Ver Todos</button>
                          <!-- <button class="btn btn-success" (click)="showModal();">Ver modal2</button> -->
                        </div>
                      </div>
                    </div>

                </ng-container>

            </ng-container>

            <div *ngIf="v_table[1].length == 0" class="p-card text-left" style="padding: 1rem;">
              <p>Ningún dato encontrado en su consulta.</p>
            </div>

          </div>

      </ng-container>

    </div>




    <!-- 6. REPORTE DE EVENTOS ANTERIOR-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R007'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <!-- <th>Unidad</th> -->
                  <th>Código</th>
                  <th>Placa</th>

                  <th>Evento</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Velocidad GPS</th>
                  <th>Velocidad CAN</th>
                  <th>Tramo</th>
                  <th>Ubicación</th>
                  <th>Referencia</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <!-- <td>{{row.nombre_objeto}}</td> -->
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa_con_guion}}</td>

                  <td>{{row.descripcion_evento}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>

                  <td>{{row.velocidad}}</td>
                  <td>{{row.vel_can}}</td>
                  <td>{{row.nombre_zona}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td>{{row.referencia}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <!-- <th>Unidad</th> -->
                  <th>Código</th>
                  <th>Placa</th>

                  <th>Evento</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Velocidad GPS</th>
                  <th>Velocidad CAN</th>
                  <th>Tramo</th>
                  <th>Ubicación</th>
                  <th>Referencia</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <!-- <td>{{row.nombre_objeto}}</td> -->
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa_con_guion}}</td>

                  <td>{{row.descripcion_evento}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>

                  <td>{{row.velocidad}}</td>
                  <td>{{row.vel_can}}</td>
                  <td>{{row.nombre_zona}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td>{{row.referencia}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 7. REPORTE DE POSICIÓN -->

    <div class="container p-card singletable-container" *ngIf="report_data.num_rep == 'R008'">
      <table *ngIf="dtRendered" [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
        <thead>
          <tr>
            <th>Index</th>
            <th>Código</th>
            <th>Placa</th>
            <th *ngIf="!chkDateHour">Fecha/Hora</th>
            <th *ngIf="chkDateHour">Fecha</th>
            <th *ngIf="chkDateHour">Hora</th>
            <th>Status</th>
            <th>Conductor</th>

            <th>Velocidad GPS</th>
            <th>Velocidad CAN</th>
            <th>Odómetro</th>
            <th>Ubicación</th>
            <th>Geocerca</th>
            <th>Referencia</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of data;  let i = index;">
            <td>{{i+1}}</td>
            <td>{{row.codigo}}</td>
            <td>{{row.placa}}</td>
            <td *ngIf="!chkDateHour">{{row.fecha}}</td>
            <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
            <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
            <td>{{row['estado']}}</td>
            <td>{{row.conductor}}</td>
            <td>{{(row.velocidad != '-')? row.velocidad + ' km/h': '-'}}</td>
            <td>{{(row.velocidad_can != '-')? row.velocidad_can + ' km/h': '-'}}</td>
            <td>{{row.odometro}}</td>
            <td>
              <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}}, {{row.longitud.toFixed(6)}}</a>
            </td>
            <td>{{row.tramo}}</td>
            <td>{{row.referencia}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 10. REPORTE DE FRENADA Y ACELERACION BRUSCA -->

    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R011'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 0.8rem;">

          <!-- <div class="subtable-title mb-2">
            <div>VEHÍCULO: {{v_table[0][1]}}</div>
            <div>PERIODO: {{period}}</div>
          </div> -->

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th *ngIf="!chkDateHour">Fecha / Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo Unidad</th>

                  <th>ID Conductor</th>
                  <th>Conductor</th>
                  <th>Vel. GPS</th>
                  <th>Vel. CAN</th>

                  <th *ngIf="chkFrenada && chkAceleracion">Descripción</th>
                  <th>Tramo</th>
                  <th>Punto Cercano</th>
                  <th>Ubicación</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_final}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>
                  <td>{{row.velGPS}}</td>
                  <td>{{row.vel_can}}</td>

                  <td *ngIf="chkFrenada && chkAceleracion">{{row.tipo_frenada}}</td>
                  <td>{{row.tramo}}</td>
                  <td>{{row.PC}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Ítem</th>
                  <th *ngIf="!chkDateHour">Fecha / Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo Unidad</th>

                  <th>ID Conductor</th>
                  <th>Conductor</th>
                  <th>Vel. GPS</th>
                  <th>Vel. CAN</th>

                  <th *ngIf="chkFrenada && chkAceleracion">Descripción</th>
                  <th>Tramo</th>
                  <th>Punto Cercano</th>
                  <th>Ubicación</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_final}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_final.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>
                  <td>{{row.velGPS}}</td>
                  <td>{{row.vel_can}}</td>

                  <td *ngIf="chkFrenada && chkAceleracion">{{row.tipo_frenada}}</td>
                  <td>{{row.tramo}}</td>
                  <td>{{row.PC}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 11. REPORTE DE DISTRACCIÓN Y POSIBLE FATIGA-->
    <!-- 21 -R022 REPORTE DE SOMNOLENCIA Y/O DISTRACCIÓN - PROLOINT -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R012' || report_data.num_rep == 'R022'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>
                    <th *ngIf="chkFatigaSomnolencia && chkFatigaDistraccion">Descripción</th>
                    <th>Reconocimiento Facial (ai1x)</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.velGPS}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>
                    <td *ngIf="chkFatigaSomnolencia && chkFatigaDistraccion">{{row.descripcion}}</td>

                    <td *ngIf="(row.ai1x > 1000)">Rostro detectado ({{row.ai1x}})</td>
                    <td *ngIf="(row.ai1x < 1001)">Rostro ausente ({{row.ai1x}})</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>
                    <th *ngIf="chkFatigaSomnolencia && chkFatigaDistraccion">Descripción</th>
                    <th>Reconocimiento Facial (ai1x)</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.velGPS}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>
                    <td *ngIf="chkFatigaSomnolencia && chkFatigaDistraccion">{{row.descripcion}}</td>

                    <td *ngIf="(row.ai1x > 1000)">Rostro detectado ({{row.ai1x}})</td>
                    <td *ngIf="(row.ai1x < 1001)">Rostro ausente ({{row.ai1x}})</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!-- 12. REPORTE DE CALIFICACION DE MANEJO-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R013'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>

                    <th *ngIf="!chkDateHour">Fecha/Hora Fin</th>
                    <th *ngIf="chkDateHour">Fecha Fin</th>
                    <th *ngIf="chkDateHour">Hora Fin</th>

                    <th>Codigo</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>C01</th>
                    <th>C02</th>
                    <th>C03</th>
                    <th>C04</th>
                    <th>C05</th>
                    <th>C06</th>
                    <th>C07</th>
                    <th>C08</th>
                    <th>C09</th>
                    <th>C10</th>
                    <th>C11</th>
                    <!-- <th>C12</th> -->
                    <th>Calificación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td *ngIf="!chkDateHour">{{row.fecha_fin}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha_fin.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha_fin.split(' ')[1]}}</td>

                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C01" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;">
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C02" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C03" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C04" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C05" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C06" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C07" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C08" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C09" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C10" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C11" (change)="puntuacion(reportTableVehicleSelected.index);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>{{row.puntuacion}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>


        <div class="p-card" style="padding: 1rem;">

          <h6>RESUMEN</h6>
          <!-- <p><span>C01 = cantidad de frenadas extremadamente bruscas.</span></p> -->
          <p><span>C01 = cantidad de frenadas bruscas.</span></p>
          <p><span>C02 = cantidad de aceleraciones bruscas.</span></p>
          <p><span>C03 = cantidad de RPM altas. (mayores a 2,000 para sprintes y buses mayores a 2,300).</span></p>
          <p><span>C04 = cantidad de posible fatiga.</span></p>
          <p><span>C05 = cantidad de fatiga extrema.</span></p>
          <p><span>C06 = cantidad de distracciones.</span></p>
          <p><span>C07 = cantidad de colisión frontal.</span></p>
          <p><span>C08 = cantidad de colisión con peatones.</span></p>
          <p><span>C09 = cantidad de desvio de carril a la izquierda.</span></p>
          <p><span>C10 = cantidad de desvio de carril a la derecha.</span></p>
          <p><span>C11 = cantidad de exceso de velocidad muy grave.</span></p>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let ii = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>

                    <th *ngIf="!chkDateHour">Fecha/Hora Fin</th>
                    <th *ngIf="chkDateHour">Fecha Fin</th>
                    <th *ngIf="chkDateHour">Hora Fin</th>

                    <th>Codigo</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>C01</th>
                    <th>C02</th>
                    <th>C03</th>
                    <th>C04</th>
                    <th>C05</th>
                    <th>C06</th>
                    <th>C07</th>
                    <th>C08</th>
                    <th>C09</th>
                    <th>C10</th>
                    <th>C11</th>
                    <!-- <th>C12</th> -->
                    <th>Calificación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td *ngIf="!chkDateHour">{{row.fecha_fin}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha_fin.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha_fin.split(' ')[1]}}</td>

                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C01" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;">
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C02" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C03" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C04" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C05" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C06" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C07" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C08" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C09" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C10" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>
                      <input *ngIf="true" [(ngModel)]="row.C11" (change)="puntuacion(ii);" type="number" style="width: 50px; border: none; padding: 2px 0 2px 4px;" >
                    </td>

                    <td>{{row.puntuacion}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>


        <div class="p-card" style="padding: 1rem;">

          <h6>RESUMEN</h6>
          <!-- <p><span>C01 = cantidad de frenadas extremadamente bruscas.</span></p> -->
          <p><span>C01 = cantidad de frenadas bruscas.</span></p>
          <p><span>C02 = cantidad de aceleraciones bruscas.</span></p>
          <p><span>C03 = cantidad de RPM altas. (mayores a 2,000 para sprintes y buses mayores a 2,300).</span></p>
          <p><span>C04 = cantidad de posible fatiga.</span></p>
          <p><span>C05 = cantidad de fatiga extrema.</span></p>
          <p><span>C06 = cantidad de distracciones.</span></p>
          <p><span>C07 = cantidad de colisión frontal.</span></p>
          <p><span>C08 = cantidad de colisión con peatones.</span></p>
          <p><span>C09 = cantidad de desvio de carril a la izquierda.</span></p>
          <p><span>C10 = cantidad de desvio de carril a la derecha.</span></p>
          <p><span>C11 = cantidad de exceso de velocidad muy grave.</span></p>

        </div>

      </ng-container>

    </div>


    <!-- 13. REPORTE DE FATIGA EXTREMA-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R014'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <!-- <th>Reconocimiento Facial (ai1x)</th> -->
                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <th>Detalle</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.velGPS}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <!-- <td *ngIf="(row.ai1x > 1000)">Rostro detectado ({{row.ai1x}})</td>
                    <td *ngIf="(row.ai1x < 1001)">Rostro ausente ({{row.ai1x}})</td> -->

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="showModalFatigaExtrema(row);">Detalle</button>
                    </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <!-- <th>Reconocimiento Facial (ai1x)</th> -->
                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <th>Detalle</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.velGPS}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <!-- <td *ngIf="(row.ai1x > 1000)">Rostro detectado ({{row.ai1x}})</td>
                    <td *ngIf="(row.ai1x < 1001)">Rostro ausente ({{row.ai1x}})</td> -->

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="showModalFatigaExtrema(row);">Detalle</button>
                    </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 14. REPORTE DE ANTICOLISION FRONTAL-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R015'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 15. REPORTE DE COLISIÓN CON PEATONES-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R016'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 16. REPORTE DE DESVÍO DE CARRIL HACIA LA IZQUIERDA-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R017'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!-- 17.REPORTE DE DESVÍO DE CARRIL HACIA LA DERECHA-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R018'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 18. REPORTE DE BLOQUEO DE VISIÓN DEL MOBILEYE-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R019'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- 19. REPORTE GERENCIAL (Cuenta cruzdelsur)-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R020'">
      <div class="p-card" style="padding: 1rem;" [hidden]="true">

        <div class="subtable-title mb-3">
          <!-- <div>
            <div>VEHÍCULO</div>
            <div>
              <div class="gl-row-underline vehicle-dropdown border-0">
                <p-dropdown
                      [filter]="true"
                      [options]="reportTableVehicleDropdownOptions"
                      optionLabel="name"
                      [(ngModel)]="reportTableVehicleSelected"
                      [appendTo]="'body'"
                      (onChange)="renderDataTable()">
                      <ng-template let-vehicle pTemplate="item">
                          <div class="country-item">
                              <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                          </div>
                      </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div> -->
          <div>
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>

        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
            <thead>
              <tr>
                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo de Unidad</th>



              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{(row.fecha || '')}}</td>
                  <td *ngIf="chkDateHour">{{(row.fecha || '').split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{(row.fecha || '').split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

              </tr>
            </tbody>

          </table>
        </ng-container>

        <!-- <div *ngIf="reportTableDropdownData[1].length == 0" class="p-card text-left" style="padding: 1rem;">
          <p>Ningún dato encontrado en su consulta.</p>
        </div> -->

      </div>

      <div class="p-card" style="padding: 0.8rem;">

          <ng-container *ngIf="dtRendered">

              <!-- <button class="btn btn-success" (click)="runReportGerencial(1);">Ejecutar la creacion de graficos</button> -->
              <!-- <div class="table-subtitle p-card" style="padding: 1rem;">
                  <div class="mb-6">
                    PERIODO: {{period}}
                  </div>
              </div> -->

              <!-- subtable-title mb-3 -->




              <div class="p-card">

                <div class="subtable-title mb-2">
                  <div style="width: 50%; min-width: 50%;">
                    <div style="padding: 20px;">
                      {{period}}
                    </div>
                  </div>
                  <div >
                    <div style="padding: 20px;">
                      <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="seeTableRepGerencial = !seeTableRepGerencial">VER DATOS </button>
                    </div>
                  </div>
                  <div>
                    <div style="padding: 20px;">
                      <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="runReportGerencial(1);">EJECUTAR GRÁFICO</button>
                    </div>
                  </div>
                </div>

              </div>

              <div *ngIf="seeTableRepGerencial">


                  <div class="table-subtitle p-card" style="padding: 1rem;">
                    <div class="mb-6">
                      DISTRACCIÓN
                    </div>
                  </div>


                  <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

                    <div class="subtable-title mb-3">
                      <div>
                        <div>VEHÍCULO : {{v_table[0][1]}}</div>
                      </div>
                      <div style="width: 60%; min-width: 60%;">
                        <div>PERIODO : {{period}}</div>
                      </div>
                    </div>

                    <ng-container *ngIf="dtRendered">
                      <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">

                        <thead>
                          <tr>
                              <th>Item</th>
                              <th *ngIf="!chkDateHour">Fecha/Hora</th>
                              <th *ngIf="chkDateHour">Fecha</th>
                              <th *ngIf="chkDateHour">Hora</th>
                              <th>Código</th>
                              <th>Placa</th>
                              <th>Tipo de Unidad</th>
                              <th>Descripción</th>
                              <th>Ubicación</th>
                              <th>Geocerca</th>
                              <th>Conductor</th>
                              <th>-</th>
                              <th>Activar/Desactivar</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container *ngFor="let row of v_table[1];  let i = index;">
                            <tr *ngIf="row.descripcion_evento == 'Distraccion'">

                              <td>{{row.nro_row + 1}}</td>
                              <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                              <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                              <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>
                              <td>{{row.codigo}}</td>
                              <td>{{row.placa}}</td>
                              <td>{{row.tipo_unidad}}</td>
                              <td>{{row.descripcion_evento}}</td>
                              <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                              <td><input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.nombre_zona"></td>
                              <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.conductor"></td>
                              <td *ngIf="row.mostrar == true"><b>Activo</b></td>
                              <td *ngIf="row.mostrar == false"><b>Eliminado</b></td>
                              <td>
                                <!-- <md-button  class="md-raised md-primary" ng-click="res.repSensorFatigaBtnEliminar(dato[1],$index,dat)" >Activar/Desactivar</md-button> -->
                                <button style="font-size: 12px;" (click)="repSensorFatigaBtnEliminar(row);">Activar/Desactivar</button>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>

                      </table>
                    </ng-container>

                  </div>




                  <div class="table-subtitle p-card" style="padding: 1rem;">
                    <div class="mb-6">
                      POSIBLE FATIGA
                    </div>
                  </div>

                  <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

                    <div class="subtable-title mb-3">
                      <div>
                        <div>VEHÍCULO : {{v_table[0][1]}}</div>
                      </div>
                      <div style="width: 60%; min-width: 60%;">
                        <div>PERIODO : {{period}}</div>
                      </div>
                    </div>

                    <ng-container *ngIf="dtRendered">
                      <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">

                        <thead>
                          <tr>
                              <th>Item</th>
                              <th *ngIf="!chkDateHour">Fecha/Hora</th>
                              <th *ngIf="chkDateHour">Fecha</th>
                              <th *ngIf="chkDateHour">Hora</th>
                              <th>Código</th>
                              <th>Placa</th>
                              <th>Tipo de Unidad</th>
                              <th>Descripción</th>
                              <th>Ubicación</th>
                              <th>Geocerca</th>
                              <th>Conductor</th>
                              <th>-</th>
                              <th>Activar/Desactivar</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container *ngFor="let row of v_table[1];  let i = index;">
                            <tr *ngIf="row.descripcion_evento == 'Somnolencia'">

                              <td>{{row.nro_row + 1}}</td>
                              <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                              <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                              <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>
                              <td>{{row.codigo}}</td>
                              <td>{{row.placa}}</td>
                              <td>{{row.tipo_unidad}}</td>
                              <td>{{row.descripcion_evento == 'Somnolencia' ? 'Posible Fatiga' : '?'}}</td>

                              <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                              <td><input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.nombre_zona"></td>
                              <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.conductor"></td>
                              <td *ngIf="row.mostrar == true"><b>Activo</b></td>
                              <td *ngIf="row.mostrar == false"><b>Eliminado</b></td>
                              <td>
                                <!-- <md-button  class="md-raised md-primary" ng-click="res.repSensorFatigaBtnEliminar(dato[1],$index,dat)" >Activar/Desactivar</md-button> -->
                                <button style="font-size: 12px;" (click)="repSensorFatigaBtnEliminar(row);">Activar/Desactivar</button>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>

                      </table>
                    </ng-container>

                  </div>


                  <div class="table-subtitle p-card" style="padding: 1rem;">
                    <div class="mb-6">
                      FATIGA EXTREMA
                    </div>
                  </div>


                  <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

                    <div class="subtable-title mb-3">
                      <div>
                        <div>VEHÍCULO : {{v_table[0][1]}}</div>
                      </div>
                      <div style="width: 60%; min-width: 60%;">
                        <div>PERIODO : {{period}}</div>
                      </div>
                    </div>

                    <ng-container *ngIf="dtRendered">
                      <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">

                        <thead>
                          <tr>
                              <th>Item</th>
                              <th *ngIf="!chkDateHour">Fecha/Hora</th>
                              <th *ngIf="chkDateHour">Fecha</th>
                              <th *ngIf="chkDateHour">Hora</th>
                              <th>Código</th>
                              <th>Placa</th>
                              <th>Tipo de Unidad</th>
                              <th>Descripción</th>
                              <th>Ubicación</th>
                              <th>Geocerca</th>
                              <th>Conductor</th>
                              <th>-</th>
                              <th>Activar/Desactivar</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container *ngFor="let row of v_table[1];  let i = index;">
                            <tr *ngIf="row.descripcion_evento == 'Fatiga Extrema'">

                              <td>{{row.nro_row + 1}}</td>
                              <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                              <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                              <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>
                              <td>{{row.codigo}}</td>
                              <td>{{row.placa}}</td>
                              <td>{{row.tipo_unidad}}</td>
                              <td>{{row.descripcion_evento}}</td>
                              <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                              <td><input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.nombre_zona"></td>
                              <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.conductor"></td>
                              <td *ngIf="row.mostrar == true"><b>Activo</b></td>
                              <td *ngIf="row.mostrar == false"><b>Eliminado</b></td>
                              <td>
                                <!-- <md-button  class="md-raised md-primary" ng-click="res.repSensorFatigaBtnEliminar(dato[1],$index,dat)" >Activar/Desactivar</md-button> -->
                                <button style="font-size: 12px;" (click)="repSensorFatigaBtnEliminar(row);">Activar/Desactivar</button>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>

                      </table>
                    </ng-container>

                  </div>


              </div>


              <div  class="p-card" style="padding: 1rem;">
                <div class="container" >

                  <div class="row justify-content-md-center">
                    <div class="col-md-auto">
                      <button *appHasPermission="'reports_pdf_R020'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfReporteGerencial();">EXPORTAR PDF</button>
                    </div>
                  </div>

                 </div>
              </div>

              <div  class="p-card" style="padding: 1rem;">

                    <div class="container">
                      <div class="row justify-content-md-center">
                        <div class="col-md-auto">
                          <div class="h4">RESUMEN DE EVENTOS</div>
                        </div>
                      </div>
                    </div>

                    <table class="table table-sm" width="100%">
                      <thead class="table-light">
                        <tr>
                          <td>EVENTO</td>
                          <td>Nº DE EVENTOS</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>DISTRACCIÓN</td>
                          <td>{{arrDataTabla.distraccion}}</td>
                        </tr>
                        <tr>
                          <td>POSIBLE FATIGA</td>
                          <td>{{arrDataTabla.posible_fatiga}}</td>
                        </tr>
                        <tr>
                          <td>FATIGA EXTREMA</td>
                          <td>{{arrDataTabla.fatiga_extrema}}</td>
                        </tr>

                      </tbody>

                    </table>

              </div>

              <br>

              <div  class="p-card" style="padding: 1rem;">
                <div class="container" >

                      <div class="row justify-content-md-center">
                        <div class="col-md-auto">
                          <div class="h4">DISTRACCION</div>
                        </div>
                      </div>

                      <div class="h5">Eventos Por Unidad</div>
                      <div [hidden]="!arrDataGrafico[0]" >
                        <div class="demo-container"  >
                          <div id="placeholder_x_unidad_distraccion" class="demo-placeholder"></div>
                        </div>
                      </div>

                      <div class="h5">Eventos Por Fecha</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[1]">
                        <div id="placeholder_x_fecha_distraccion" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Geocerca</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[2]" >
                        <div id="placeholder_x_geocerca_distraccion" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Conductor</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[3]" >
                        <div id="placeholder_x_conductor_distraccion" class="demo-placeholder"></div>
                      </div>

                </div>
              </div>
              <br>
              <div  class="p-card" style="padding: 1rem;">
                <div class="container" >

                      <div class="row justify-content-md-center">
                        <div class="col-md-auto">
                          <div class="h4">POSIBLE FATIGA</div>
                        </div>
                      </div>

                      <div class="h5">Eventos Por Unidad</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[4]">
                        <div id="placeholder_x_unidad_posible_fatiga" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Fecha</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[5]">
                        <div id="placeholder_x_fecha_posible_fatiga" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Geocerca</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[6]">
                        <div id="placeholder_x_geocerca_posible_fatiga" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Conductor</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[7]">
                        <div id="placeholder_x_conductor_posible_fatiga" class="demo-placeholder"></div>
                      </div>

                 </div>
              </div>

              <br>

              <div  class="p-card" style="padding: 1rem;">
                <div class="container" >

                      <div class="row justify-content-md-center">
                        <div class="col-md-auto">
                          <div class="h4">FATIGA EXTREMA</div>
                        </div>
                      </div>

                      <div class="h5">Eventos Por Unidad</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[8]">
                        <div id="placeholder_x_unidad_fatiga_extrema" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Fecha</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[9]">
                        <div id="placeholder_x_fecha_fatiga_extrema" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Geocerca</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[10]">
                        <div id="placeholder_x_geocerca_fatiga_extrema" class="demo-placeholder"></div>
                      </div>

                      <div class="h5">Eventos Por Conductor</div>
                      <div class="demo-container" [hidden]="!arrDataGrafico[11]">
                        <div id="placeholder_x_conductor_fatiga_extrema" class="demo-placeholder"></div>
                      </div>

                 </div>
              </div>


              <div  class="p-card" style="padding: 1rem;">
                <div class="container" >

                  <div class="row justify-content-md-center">
                    <div class="col-md-auto">
                      <button *appHasPermission="'reports_pdf_R020'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfReporteGerencial();">EXPORTAR PDF</button>
                    </div>
                  </div>

                 </div>
              </div>


          </ng-container>
      </div>


    </div>


    <!-- 20. REPORTE  DE EXCESOS DE VELOCIDAD (FORMATO EXTENDIDO)-->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R021'">
      <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

        <div class="subtable-title mb-3">
          <!-- <div>
            <div>VEHÍCULO</div>
            <div>{{v_table[0][1]}}</div>
          </div> -->
          <div style="width: 100%; min-width: 100%;">
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>

        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
            <thead>
              <tr>
                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo de Unidad</th>

                  <th>Id Conductor</th>
                  <th>Conductor</th>

                  <th>Tramo</th>
									<th>Límite de Velocidad</th>
									<th>Exceso de Velocidad</th>
                  <th>Ubicación</th>

              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_inicio}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>

									<td>{{row.nombre_zona}}</td>
                  <td>{{row.v0}} Km/h</td>
                  <td>{{row.velocidad}} Km/h</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>

              </tr>
            </tbody>

          </table>
        </ng-container>

      </div>
    </div>


    <!-- 22. REPORTE DE MANTENIMIENTOS FISICOS -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R023'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>
                    <th>Tipo de Mantenimiento</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>
                    <td>{{row.tipo_mantenimiento}}</td>


                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>
                    <th>Tipo de Mantenimiento</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>
                    <td>{{row.tipo_mantenimiento}}</td>


                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>



    <!--   -R024 REPORTE DE NO ROSTRO -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R024'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>

                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>
                    <th>ai1</th>
                    <th>Tramo</th>
                    <th>Referencia</th>
                    <th>Ubicación</th>
                    <th>Detalle</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">


                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>

                    <td>{{row.vel_gps}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>
                    <td>{{row.ai1x}}</td>
                    <td>{{row.tramo}}</td>
                    <td>{{row.referencia}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>

                    <td>

                        <button class="btn btn-success" (click)="showModalNoRostro(row);">Detalle</button>


                      <!-- <md-button class="md-primary md-raised" ng-click="res.showDetalleGeneral(dat,'exportExcelNoRostro')"  >
                        Detalle
                      </md-button> -->
                    </td>




                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <!-- <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>
                    <th>Tipo de Mantenimiento</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th> -->




                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>
                    <th>Id Conductor</th>
                    <th>Conductor</th>

                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>
                    <th>ai1</th>
                    <th>Tramo</th>
                    <th>Referencia</th>
                    <th>Ubicación</th>
                    <th>Detalle</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>

                    <td>{{row.vel_gps}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>
                    <td>{{row.ai1x}}</td>
                    <td>{{row.tramo}}</td>
                    <td>{{row.referencia}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>

                    <td>

                        <button class="btn btn-success" (click)="showModalNoRostro(row);">Detalle</button>

                    </td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!--   -R025 REPORTE DE ODOMETRO TLINEA -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R025'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora Salida</th>
                    <th *ngIf="chkDateHour">Fecha Salida</th>
                    <th *ngIf="chkDateHour">Hora Salida</th>
                    <th>Origen</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora Llegada</th>
                    <th *ngIf="chkDateHour">Fecha Llegada</th>
                    <th *ngIf="chkDateHour">Hora Llegada</th>
                    <th>Destino</th>

                    <th>Distancia</th>
                    <th>O.Inicial</th>
                    <th>O.Final</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">
                    <td>{{i + 1}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td *ngIf="!chkDateHour">{{row.f_ini}}</td>
                    <td *ngIf="chkDateHour">{{row.f_ini.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.f_ini.split(' ')[1]}}</td>
                    <td>{{row.zona_salida}}</td>
                    <td *ngIf="!chkDateHour">{{row.f_fin}}</td>
                    <td *ngIf="chkDateHour">{{row.f_fin.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.f_fin.split(' ')[1]}}</td>
                    <td>{{row.zona_llegada}}</td>

                    <td>{{row.distancia}}</td>
                    <td>{{row.odometro_inicial}}</td>
                    <td>{{row.odometro_final}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora Salida</th>
                  <th *ngIf="chkDateHour">Fecha Salida</th>
                  <th *ngIf="chkDateHour">Hora Salida</th>
                  <th>Origen</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora Llegada</th>
                  <th *ngIf="chkDateHour">Fecha Llegada</th>
                  <th *ngIf="chkDateHour">Hora Llegada</th>
                  <th>Destino</th>

                  <th>Distancia</th>
                  <th>O.Inicial</th>
                  <th>O.Final</th>

                  <th>Id Conductor</th>
                  <th>Conductor</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td *ngIf="!chkDateHour">{{row.f_ini}}</td>
                  <td *ngIf="chkDateHour">{{row.f_ini.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.f_ini.split(' ')[1]}}</td>
                  <td>{{row.zona_salida}}</td>
                  <td *ngIf="!chkDateHour">{{row.f_fin}}</td>
                  <td *ngIf="chkDateHour">{{row.f_fin.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.f_fin.split(' ')[1]}}</td>
                  <td>{{row.zona_llegada}}</td>

                  <td>{{row.distancia}}</td>
                  <td>{{row.odometro_inicial}}</td>
                  <td>{{row.odometro_final}}</td>

                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!--   -R026 REPORTE DE ENCENDIDO DE MOTOR -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R026'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>


                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Id Conductor</th>
                    <th>Conductor</th>


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Id Conductor</th>
                  <th>Conductor</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!--   -R027 REPORTE LLAVE I-BUTTON -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R027'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>I-Button</th>
                    <th>Id Conductor</th>
                    <th>Conductor</th>

                    <th>Tramo</th>
                    <th>Ubicación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idButton_str}}</td>
                    <td>{{row.idConductor}}</td>
                    <td>{{row.notificacion}}</td>

                    <td>{{row.tramo}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo de Unidad</th>

                  <th>I-Button</th>
                  <th>Id Conductor</th>
                  <th>Conductor</th>

                  <th>Tramo</th>
                  <th>Ubicación</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

                  <td>{{row.idButton_str}}</td>
                  <td>{{row.idConductor}}</td>
                  <td>{{row.notificacion}}</td>

                  <td>{{row.tramo}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!--   -R028 REPORTE DE MANIPULACION DE DISPOSITIVO -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R028'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>


                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Id Conductor</th>
                    <th>Conductor</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Id Conductor</th>
                  <th>Conductor</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!--   -R029 REPORTE DE VIBRACION DEL SENSOR DE FATIGA -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R029'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>


                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>AIN4</th>

                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <th>Descripción</th>


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.AIN4}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                    <td>{{row.descripcion}}</td>


                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo de Unidad</th>

                  <th>Id Conductor</th>
                  <th>Conductor</th>
                  <th>AIN4</th>

                  <th>Vel.GPS</th>
                  <th>Vel.CAN</th>

                  <th>Tramo</th>
                  <th>Punto Cercano</th>
                  <th>Ubicación</th>
                  <th>Descripción</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>
                  <td>{{row.AIN4}}</td>
                  <td>{{row.vel_gps_speed}} Km/h</td>
                  <td>{{row.vel_can}} Km/h</td>

                  <td>{{row.tramo}}</td>
                  <td>{{row.PC}}</td>
                  <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                  <td>{{row.descripcion}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!--   -R030 REPORTE DE POSIBLE FATIGA - ATENCIÓN DE EVENTOS -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R030'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->




          <div class="p-card">

            <div class="subtable-title mb-2">
              <div style="width: 50%; min-width: 50%;">
                <div style="padding: 20px;">
                  {{period}}
                </div>
              </div>
              <div >
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="actualizarReporteFatigaExtrema();" >ACTUALIZAR</button>

                </div>
              </div>
              <div>
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="guardarReporteFatigaExtrema();" >GUARDAR</button>
                </div>
              </div>
            </div>

          </div>


          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>


                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>


                    <th>Id Conductor</th>
                    <th>Conductor</th>


										<th>Fecha de Evaluación</th>
										<th>Criterio de Evaluación</th>
										<th>Observación</th>


                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>


                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Descripción</th>	 -->
										<th>Operador de Monitoreo1</th>
										<!-- <th>Detalle</th> -->


                </tr>
              </thead>

              <tbody>

                <tr *ngFor="let row of tablaFormatoBetsy_1;  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>


                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>


                    <td>{{row.fecha_evaluacion}}</td>
                    <td>
                      <select [(ngModel)]="row.criterio_evaluacion" style="width:180px;">
                        <option value="">OPCIONES</option>
                        <option value="COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA">COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA</option>
                        <option value="EXISTE MIRADA DEL CONDUCTOR AL TABLERO">EXISTE MIRADA DEL CONDUCTOR AL TABLERO</option>
                        <option value="PRESENCIA DE DESLUMBRAMIENTOS">PRESENCIA DE DESLUMBRAMIENTOS</option>
                        <option value="CARACTERÍSTICA DE OJOS DE FACCIONES ASIÁTICAS">CARACTERÍSTICA DE OJOS DE FACCIONES ASIÁTICAS</option>
                        <option value="USO DE CHULLO, GORRA CON VISERA O CASCO">USO DE CHULLO, GORRA CON VISERA O CASCO</option>
                        <option value="LENTES CORRECTORES CON MARCO REDUCIDO">LENTES CORRECTORES CON MARCO REDUCIDO</option>
                        <option value="LENTES DE SEGURIDAD U OTRO TIPO MAL COLOCADOS O MARCO REDUCIDO">LENTES DE SEGURIDAD U OTRO TIPO MAL COLOCADOS O MARCO REDUCIDO </option>
                        <option value="USO DE MAQUILLAJE EN ROSTRO">USO DE MAQUILLAJE EN ROSTRO</option>
                        <option value="USO DE MASCARILLA NEGRA">USO DE MASCARILLA NEGRA</option>
                        <option value="USO DE PROTECTOR FACIAL TIPO CARETA">USO DE PROTECTOR FACIAL TIPO CARETA</option>
                        <option value="USO DE SOBRELENTES">USO DE SOBRELENTES</option>
                        <option value="MANO CON ACCIÓN DE FROTACIÓN EN EL OJO">MANO CON ACCIÓN DE FROTACIÓN EN EL OJO</option>
                        <option value="BOSTEZO CON OJOS ABIERTOS MENOR A 3 VECES">BOSTEZO CON OJOS ABIERTOS MENOR A 3 VECES</option>
                      </select>
                    </td>
                    <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.observacion_evaluacion"></td>


                    <td>{{row.velGPS}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>


                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                    <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.operador_monitoreo"></td>


                </tr>

              </tbody>
            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div class="p-card" style="padding: 0.8rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <div class="p-card">

            <div class="subtable-title mb-2">
              <div style="width: 50%; min-width: 50%;">
                <div style="padding: 20px;">
                  {{period}}
                </div>
              </div>
              <div >
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="actualizarReporteFatigaExtrema();" >ACTUALIZAR</button>
                </div>
              </div>
              <div>
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="guardarReporteFatigaExtrema();" >GUARDAR</button>
                </div>
              </div>
            </div>

          </div>


          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo de Unidad</th>


                  <th>Id Conductor</th>
                  <th>Conductor</th>


                  <th>Fecha de Evaluación</th>
                  <th>Criterio de Evaluación</th>
                  <th>Observación</th>


                  <th>Vel.GPS</th>
                  <th>Vel.CAN</th>


                  <th>Tramo</th>
                  <th>Punto Cercano</th>
                  <th>Ubicación</th>
                  <!-- <th>Descripción</th>	 -->
                  <th>Operador de Monitoreo2</th>
                  <!-- <th>Detalle</th> -->

                </tr>
              </thead>

              <tbody>

                <tr *ngFor="let row of tablaFormatoBetsy_1;  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>


                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>


                  <td>{{row.fecha_evaluacion}}</td>
                  <td>
                    <select [(ngModel)]="row.criterio_evaluacion" style="width:180px;">
                      <option value="">OPCIONES</option>
                      <option value="COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA">COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA</option>
                      <option value="EXISTE MIRADA DEL CONDUCTOR AL TABLERO">EXISTE MIRADA DEL CONDUCTOR AL TABLERO</option>
                      <option value="PRESENCIA DE DESLUMBRAMIENTOS">PRESENCIA DE DESLUMBRAMIENTOS</option>
                      <option value="CARACTERÍSTICA DE OJOS DE FACCIONES ASIÁTICAS">CARACTERÍSTICA DE OJOS DE FACCIONES ASIÁTICAS</option>
                      <option value="USO DE CHULLO, GORRA CON VISERA O CASCO">USO DE CHULLO, GORRA CON VISERA O CASCO</option>
                      <option value="LENTES CORRECTORES CON MARCO REDUCIDO">LENTES CORRECTORES CON MARCO REDUCIDO</option>
                      <option value="LENTES DE SEGURIDAD U OTRO TIPO MAL COLOCADOS O MARCO REDUCIDO">LENTES DE SEGURIDAD U OTRO TIPO MAL COLOCADOS O MARCO REDUCIDO </option>
                      <option value="USO DE MAQUILLAJE EN ROSTRO">USO DE MAQUILLAJE EN ROSTRO</option>
                      <option value="USO DE MASCARILLA NEGRA">USO DE MASCARILLA NEGRA</option>
                      <option value="USO DE PROTECTOR FACIAL TIPO CARETA">USO DE PROTECTOR FACIAL TIPO CARETA</option>
                      <option value="USO DE SOBRELENTES">USO DE SOBRELENTES</option>
                      <option value="MANO CON ACCIÓN DE FROTACIÓN EN EL OJO">MANO CON ACCIÓN DE FROTACIÓN EN EL OJO</option>
                      <option value="BOSTEZO CON OJOS ABIERTOS MENOR A 3 VECES">BOSTEZO CON OJOS ABIERTOS MENOR A 3 VECES</option>
                    </select>
                  </td>
                  <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.observacion_evaluacion"></td>


                  <td>{{row.velGPS}} Km/h</td>
                  <td>{{row.vel_can}} Km/h</td>


                  <td>{{row.tramo}}</td>
                  <td>{{row.PC}}</td>
                  <td><a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a></td>
                  <td><input style="font-size: 12px;" size="40" type="text" [(ngModel)]="row.operador_monitoreo"></td>


                </tr>


              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>
      <!-- <button *ngIf="report_data.num_rep == 'R030'" class="btn btn-gl btn-success btn-dark-hover" (click)="fatigaExtremaNewFormato();" >RUN TABLA EVENTOS</button> -->
      <button *ngIf="fatigaExtremaNewFormato();"  ></button>
    </div>


    <!--   -R031 REPORTE DE ALCOTESTER -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R031'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <!-- <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div> -->
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>


                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>

                    <th>Tipo de Unidad</th>
                    <th>Resultados</th>

                    <th>Tramo</th>
                    <th>Referencia</th>
                    <th>Ubicación</th>


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>

                    <td>{{row.tipo_unidad}}</td>
                    <td>{{row.resultados}}</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.referencia}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <!-- <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div> -->
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>

                  <th>Tipo de Unidad</th>
                  <th>Resultados</th>

                  <th>Tramo</th>
                  <th>Referencia</th>
                  <th>Ubicación</th>


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">


                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>

                  <td>{{row.tipo_unidad}}</td>
                  <td>{{row.resultados}}</td>

                  <td>{{row.tramo}}</td>
                  <td>{{row.referencia}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>


                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!-- R032 REPORTE DE EVENTO SOS -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R032'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th *ngIf="!chkDateHour">Fecha/Hora</th>
                    <th *ngIf="chkDateHour">Fecha</th>
                    <th *ngIf="chkDateHour">Hora</th>
                    <th>Código</th>
                    <th>Placa</th>
                    <th>Tipo de Unidad</th>

                    <th>Id Conductor</th>
                    <th>Conductor</th>
                    <th>Vel.GPS</th>
                    <th>Vel.CAN</th>

                    <th>Tramo</th>
                    <th>Punto Cercano</th>
                    <th>Ubicación</th>
                    <!-- <th>Sonido en Cabina</th> -->


                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>

                    <td>{{row.idConductor}}</td>
                    <td>{{row.conductor}}</td>
                    <td>{{row.vel_gps_speed}} Km/h</td>
                    <td>{{row.vel_can}} Km/h</td>

                    <td>{{row.tramo}}</td>
                    <td>{{row.PC}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <!-- <td>{{row.sonidoEnCabina}}</td> -->

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!-- R033 REPORTE COORDENADAS GEOCERCAS -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R033'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th>Nº Geo</th>
                    <th>Nombre Geocerca</th>
                    <th>Latitud</th>
                    <th>Longitud</th>

                    <th>Velocidad</th>
                    <th>Vel.Muy Grave</th>
                    <th>Vel.Grave</th>
                    <th>Vel.Tolerable</th>
                    <th>Zona Color</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td>{{row.nro}}</td>
                    <td>{{row.zone_name}}</td>
                    <td>{{row.lat}}</td>
                    <td>{{row.lng}}</td>

                    <td>{{row.vel_max}}</td>
                    <td>{{row.vel3_zona}}</td>
                    <td>{{row.vel2_zona}}</td>
                    <td>{{row.vel_zona}}</td>
                    <td>{{row.zone_color}}</td>

                </tr>

              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Nº Geo</th>
                  <th>Nombre Geocerca</th>
                  <th>Latitud</th>
                  <th>Longitud</th>

                  <th>Velocidad</th>
                  <th>Vel.Muy Grave</th>
                  <th>Vel.Grave</th>
                  <th>Vel.Tolerable</th>
                  <th>Zona Color</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td>{{row.nro}}</td>
                  <td>{{row.zone_name}}</td>
                  <td>{{row.lat}}</td>
                  <td>{{row.lng}}</td>

                  <td>{{row.vel_max}}</td>
                  <td>{{row.vel3_zona}}</td>
                  <td>{{row.vel2_zona}}</td>
                  <td>{{row.vel_zona}}</td>
                  <td>{{row.zone_color}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- R034 REPORTE COORDENADAS GEOPUNTOS -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R034'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th>Nº Punto</th>
                    <th>Nombre Punto</th>

                    <th>Latitud</th>
                    <th>Longitud</th>
                    <th>Color</th>

                    <th>Visibilidad Punto</th>
                    <th>Visibilidad Nombre</th>

                    <th>Latitud 2</th>
                    <th>Longitud 2</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td>{{row.nro}}</td>
                    <td>{{row.geopunto_name}}</td>
                    <td>{{row.lat}}</td>
                    <td>{{row.lng}}</td>
                    <td>{{row.geopunto_color}}</td>

                    <td>{{row.geopunto_visible}}</td>
                    <td>{{row.geopunto_nombre_visible}}</td>
                    <td>{{row.lat_1}}</td>
                    <td>{{row.lng_1}}</td>

                </tr>

              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Nº Punto</th>
                  <th>Nombre Punto</th>

                  <th>Latitud</th>
                  <th>Longitud</th>
                  <th>Color</th>

                  <th>Visibilidad Punto</th>
                  <th>Visibilidad Nombre</th>

                  <th>Latitud 2</th>
                  <th>Longitud 2</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td>{{row.nro}}</td>
                  <td>{{row.geopunto_name}}</td>
                  <td>{{row.lat}}</td>
                  <td>{{row.lng}}</td>
                  <td>{{row.geopunto_color}}</td>

                  <td>{{row.geopunto_visible}}</td>
                  <td>{{row.geopunto_nombre_visible}}</td>
                  <td>{{row.lat_1}}</td>
                  <td>{{row.lng_1}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- R035 REPORTE COORDENADAS GEOCERCAS CIRCULARES -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R035'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>
                    <th>Nº Geo</th>
                    <th>Nombre Geocerca</th>

                    <th>Latitud</th>
                    <th>Longitud</th>
                    <th>radio</th>
                    <th>diametro</th>

                    <th>Velocidad</th>
                    <th>Vel.Muy Grave</th>
                    <th>Vel.Grave</th>
                    <th>Vel.Tolerable</th>
                    <th>Zona Color</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>
                    <td>{{row.nro}}</td>
                    <td>{{row.geocerca_name}}</td>

                    <td>{{row.lat}}</td>
                    <td>{{row.lng}}</td>
                    <td>{{row.radio}}</td>
                    <td>{{row.diametro}}</td>

                    <td>{{row.v0}}</td>
                    <td>{{row.v3}}</td>
                    <td>{{row.v2}}</td>
                    <td>{{row.v1}}</td>
                    <td>{{row.geocerca_color}}</td>

                </tr>

              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <!-- <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div> -->

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Nº Geo</th>
                  <th>Nombre Geocerca</th>

                  <th>Latitud</th>
                  <th>Longitud</th>
                  <th>radio</th>
                  <th>diametro</th>

                  <th>Velocidad</th>
                  <th>Vel.Muy Grave</th>
                  <th>Vel.Grave</th>
                  <th>Vel.Tolerable</th>
                  <th>Zona Color</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td>{{row.nro}}</td>
                  <td>{{row.geocerca_name}}</td>

                  <td>{{row.lat}}</td>
                  <td>{{row.lng}}</td>
                  <td>{{row.radio}}</td>
                  <td>{{row.diametro}}</td>

                  <td>{{row.v0}}</td>
                  <td>{{row.v3}}</td>
                  <td>{{row.v2}}</td>
                  <td>{{row.v1}}</td>
                  <td>{{row.geocerca_color}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>


    <!-- R037. REPORTE DE EVENTOS CIPIA -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R037' || report_data.num_rep == 'R040'">

      <ng-container *ngIf="chkTableDropdown">

        <div class="p-card" style="padding: 1rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>
                <div class="gl-row-underline vehicle-dropdown border-0">
                  <p-dropdown
                        [filter]="true"
                        [options]="reportTableVehicleDropdownOptions"
                        optionLabel="name"
                        [(ngModel)]="reportTableVehicleSelected"
                        [appendTo]="'body'"
                        (onChange)="renderDataTable()">
                        <ng-template let-vehicle pTemplate="item">
                            <div class="country-item">
                                <span>{{vehicle.name}} {{vehicle.dataSize}}</span>
                            </div>
                        </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>
                    <th>Item</th>


                    <th *ngIf="!chkDateHour && eC.Fecha">Fecha/Hora Evento</th>
                    <th *ngIf="chkDateHour && eC.Fecha">Fecha Evento</th>
                    <th *ngIf="chkDateHour && eC.Fecha">Hora Evento</th>
                    <th *ngIf="!chkDateHour && eC.FechaServidor">Fecha/Hora Servidor</th>
                    <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor ">Fecha Servidor</th>
                    <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">Hora Servidor</th>

                    <th *ngIf="eC.NombreEvento" >Nombre del Evento</th>
                    <th *ngIf="eC.Evento" >Descripción</th>

                    <th *ngIf="eC.Codigo">Código</th>
                    <th *ngIf="eC.Placa">Placa</th>
                    <th *ngIf="eC.TipoUnidad">Tipo de Unidad</th>

                    <th *ngIf="eC.IdConductor">ID Conductor</th>
                    <th *ngIf="eC.Conductor">Conductor</th>

                    <th *ngIf="eC.VelMobileye">Vel.Mobileye</th>
                    <th *ngIf="eC.VelGPS">Vel.GPS</th>
                    <th *ngIf="eC.VelGPSspeed">Vel.GPS speed</th>
                    <th *ngIf="eC.VelCAN">Vel.CAN</th>
                    <th *ngIf="eC.VelECO">Vel.ECO</th>

                    <th *ngIf="eC.Zona">Geocerca</th>
                    <th *ngIf="eC.PuntoCercano">Punto Cercano</th>
                    <th *ngIf="eC.Ubicacion">Ubicación</th>
                    <th *ngIf="eC.Referencia">Referencia</th>
                    <th *ngIf="eC.EnlaceArchivo">Enlace a Archivo</th>
                    <th *ngIf="eC.Parametros">Parámetros</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of reportTableDropdownData[1];  let i = index;">

                    <td>{{i + 1}}</td>


                    <td *ngIf="!chkDateHour && eC.Fecha">{{row.fecha_tracker}}</td>
                    <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[1]}}</td>

                    <td *ngIf="!chkDateHour && eC.FechaServidor">{{row.fecha_servidor}}</td>
                    <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[1]}}</td>

                    <td *ngIf="eC.NombreEvento">{{row.nombre_evento}}</td>
                    <td *ngIf="eC.Evento">{{row.descripcion_evento}}</td>

                    <td *ngIf="eC.Codigo">{{row.codigo}}</td>
                    <td *ngIf="eC.Placa">{{row.placa}}</td>
                    <td *ngIf="eC.TipoUnidad">{{row.tipo_unidad}}</td>


                    <td *ngIf="eC.IdConductor">{{row.idConductor}}</td>
                    <td *ngIf="eC.Conductor">{{row.conductor}}</td>


                    <td *ngIf="eC.VelMobileye">{{row.vel_mobileye}}</td>
                    <td *ngIf="eC.VelGPS">{{row.vel_gps}}</td>
                    <td *ngIf="eC.VelGPSspeed">{{row.vel_gps_speed}}</td>
                    <td *ngIf="eC.VelCAN">{{row.vel_can}}</td>
                    <td *ngIf="eC.VelECO">{{row.vel_eco}}</td>


                    <td *ngIf="eC.Zona">{{row.tramo}}</td>
                    <td *ngIf="eC.PuntoCercano">{{row.PC}}</td>
                    <td *ngIf="eC.Ubicacion">
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td *ngIf="eC.Referencia">{{row.referencia}}</td>

                    <td *ngIf="eC.EnlaceArchivo">
                    </td>

                    <td *ngIf="eC.Parametros">{{row.parametros}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

      <ng-container *ngIf="!chkTableDropdown">

        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">

          <div class="subtable-title mb-3">
            <div>
              <div>VEHÍCULO</div>
              <div>{{v_table[0][1]}}</div>
            </div>
            <div>
              <div>PERIODO</div>
              <div>{{period}}</div>
            </div>
          </div>

          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>


                  <th *ngIf="!chkDateHour && eC.Fecha">Fecha/Hora Evento</th>
                  <th *ngIf="chkDateHour && eC.Fecha">Fecha Evento</th>
                  <th *ngIf="chkDateHour && eC.Fecha">Hora Evento</th>
                  <th *ngIf="!chkDateHour && eC.FechaServidor">Fecha/Hora Servidor</th>
                  <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor ">Fecha Servidor</th>
                  <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">Hora Servidor</th>

                  <th *ngIf="eC.NombreEvento" >Nombre del Evento</th>
                  <th *ngIf="eC.Evento" >Descripción</th>

                  <th *ngIf="eC.Codigo">Código</th>
                  <th *ngIf="eC.Placa">Placa</th>
                  <th *ngIf="eC.TipoUnidad">Tipo de Unidad</th>

                  <th *ngIf="eC.IdConductor">ID Conductor</th>
                  <th *ngIf="eC.Conductor">Conductor</th>

                  <th *ngIf="eC.VelMobileye">Vel.Mobileye</th>
                  <th *ngIf="eC.VelGPS">Vel.GPS</th>
                  <th *ngIf="eC.VelGPSspeed">Vel.GPS speed</th>
                  <th *ngIf="eC.VelCAN">Vel.CAN</th>
                  <th *ngIf="eC.VelECO">Vel.ECO</th>

                  <th *ngIf="eC.Zona">Geocerca</th>
                  <th *ngIf="eC.PuntoCercano">Punto Cercano</th>
                  <th *ngIf="eC.Ubicacion">Ubicación</th>
                  <th *ngIf="eC.Referencia">Referencia</th>

                  <th *ngIf="eC.EnlaceArchivo">Enlace a Archivo</th>
                  <th *ngIf="eC.Parametros">Parámetros</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                    <!-- <td>{{i + 1}}</td>
                    <td>{{row.descripcion_evento}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>
                    <td *ngIf="!chkDateHour">{{row.fecha_servidor}}</td>
                    <td *ngIf="chkDateHour && user_id != 923">{{row.fecha_servidor.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour && user_id != 923">{{row.fecha_servidor.split(' ')[1]}}</td>
                    <td>{{row.codigo}}</td>
                    <td>{{row.placa}}</td>
                    <td>{{row.tipo_unidad}}</td>
                    <td>{{row.vel_gps}} Km/h</td>
                    <td>{{row.tramo}}</td>
                    <td>{{row.referencia}}</td>
                    <td>
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td>
                      <a href="{{row.enlaceImageCIPIA}}" target="_blank">Imagen</a>/<a href="{{row.enlaceVideoCIPIA}}" target="_blank">Video</a>
                    </td> -->

                    <td>{{i + 1}}</td>

                    <td *ngIf="!chkDateHour && eC.Fecha">{{row.fecha_tracker}}</td>
                    <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[1]}}</td>

                    <td *ngIf="!chkDateHour && eC.FechaServidor">{{row.fecha_servidor}}</td>
                    <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[0]}}</td>
                    <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[1]}}</td>

                    <td *ngIf="eC.NombreEvento">{{row.nombre_evento}}</td>
                    <td *ngIf="eC.Evento">{{row.descripcion_evento}}</td>

                    <td *ngIf="eC.Codigo">{{row.codigo}}</td>
                    <td *ngIf="eC.Placa">{{row.placa}}</td>
                    <td *ngIf="eC.TipoUnidad">{{row.tipo_unidad}}</td>

                    <td *ngIf="eC.IdConductor">{{row.idConductor}}</td>
                    <td *ngIf="eC.Conductor">{{row.conductor}}</td>


                    <td *ngIf="eC.VelMobileye">{{row.vel_mobileye}}</td>
                    <td *ngIf="eC.VelGPS">{{row.vel_gps}}</td>
                    <td *ngIf="eC.VelGPSspeed">{{row.vel_gps_speed}}</td>
                    <td *ngIf="eC.VelCAN">{{row.vel_can}}</td>
                    <td *ngIf="eC.VelECO">{{row.vel_eco}}</td>

                    <td *ngIf="eC.Zona">{{row.tramo}}</td>
                    <td *ngIf="eC.PuntoCercano">{{row.PC}}</td>
                    <td *ngIf="eC.Ubicacion">
                      <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                    </td>
                    <td *ngIf="eC.Referencia">{{row.referencia}}</td>

                    <td *ngIf="eC.EnlaceArchivo">
                      <a href="{{row.enlaceImageCIPIA}}" target="_blank">Imagen</a>/<a href="{{row.enlaceVideoCIPIA}}" target="_blank">Video</a>
                    </td>
                    <td *ngIf="eC.Parametros">{{row.parametros}}</td>

                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- R038. REPORTE DE ATENCION DE EVENTOS CIPIA -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R038' && !lineaUser()">


      <!-- <ng-container *ngIf="!chkTableDropdown"> -->
      <ng-container >
        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">


          <div class="p-card">

            <div class="subtable-title mb-2">
              <div style="width: 50%; min-width: 50%;">
                <div style="padding: 20px;">
                  {{period}}
                </div>
              </div>
              <div >
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="actualizarEvaluacionEventos();" >ACTUALIZAR</button>

                </div>
              </div>
              <div>
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover" (click)="guardarEvaluacionEventos();" >GUARDAR</button>
                </div>
              </div>
            </div>

          </div>




          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
              <thead>
                <tr>

                  <th>Item</th>


                  <th *ngIf="!chkDateHour && eC.Fecha">Fecha/Hora Evento</th>
                  <th *ngIf="chkDateHour && eC.Fecha">Fecha Evento</th>
                  <th *ngIf="chkDateHour && eC.Fecha">Hora Evento</th>
                  <th *ngIf="!chkDateHour && eC.FechaServidor">Fecha/Hora Servidor</th>
                  <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor ">Fecha Servidor</th>
                  <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">Hora Servidor</th>

                  <th *ngIf="eC.NombreEvento" >Nombre del Evento</th>
                  <th *ngIf="eC.Evento" >Descripción</th>

                  <th *ngIf="eC.Codigo">Código</th>
                  <th *ngIf="eC.Placa">Placa</th>
                  <th *ngIf="eC.TipoUnidad">Tipo de Unidad</th>

                  <th *ngIf="eC.IdConductor">ID Conductor</th>
                  <th *ngIf="eC.Conductor">Conductor</th>


                  <th *ngIf="eC.FechaEvaluacion">Fecha de Evaluación</th>
                  <th *ngIf="eC.CriterioEvaluacion">Criterio de Evaluación</th>
                  <th *ngIf="eC.Observacion">Observación</th>
                  <!-- <th *ngIf="eC.Validacion">Validación</th> -->

                  <th *ngIf="eC.ComRadial">Comunicación Radial</th>
                  <th *ngIf="eC.VelMobileye">Vel.Mobileye</th>
                  <th *ngIf="eC.VelGPS">Vel.GPS</th>
                  <th *ngIf="eC.VelGPSspeed">Vel.GPS speed</th>
                  <th *ngIf="eC.VelCAN">Vel.CAN</th>
                  <th *ngIf="eC.VelECO">Vel.ECO</th>

                  <th *ngIf="eC.Zona">Geocerca</th>
                  <th *ngIf="eC.PuntoCercano">Punto Cercano</th>
                  <th *ngIf="eC.Ubicacion">Ubicación</th>
                  <th *ngIf="eC.Referencia">Referencia</th>
                  <th *ngIf="eC.EnlaceArchivo">Enlace a Archivo</th>
                  <!-- <th *ngIf="eC.Parametros">Parámetros</th> -->

                  <th *ngIf="eC.OperadorMonitoreo">Operador de Monitoreo</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>


                  <td *ngIf="!chkDateHour && eC.Fecha">{{row.fecha_tracker}}</td>
                  <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[1]}}</td>

                  <td *ngIf="!chkDateHour && eC.FechaServidor">{{row.fecha_servidor}}</td>
                  <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[1]}}</td>

                  <td *ngIf="eC.NombreEvento">{{row.nombre_evento}}</td>
                  <td *ngIf="eC.Evento">{{row.descripcion_evento}}</td>

                  <td *ngIf="eC.Codigo">{{row.codigo}}</td>
                  <td *ngIf="eC.Placa">{{row.placa}}</td>
                  <td *ngIf="eC.TipoUnidad">{{row.tipo_unidad}}</td>


                  <td *ngIf="eC.IdConductor">{{row.idConductor}}</td>
                  <td *ngIf="eC.Conductor">{{row.conductor}}</td>

                  <td *ngIf="eC.FechaEvaluacion">{{row.fecha_evaluacion}}</td>


                  <!--
                  $result[$i][1][$j]->fecha_evaluacion = "";
                  $result[$i][1][$j]->criterio_evaluacion = "";
                  $result[$i][1][$j]->observacion_evaluacion = "";
                  $result[$i][1][$j]->operador_monitoreo = "";

                  $result[$i][1][$j]->senales_posible_fatiga = false;
                  $result[$i][1][$j]->identificacion_video = "";
                  $result[$i][1][$j]->valoracion_evento = "1"; -->


                  <td *ngIf="eC.CriterioEvaluacion">
                      <!-- <input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.CriterioEvaluacion"> -->
                      <select [(ngModel)]="row.criterio_evaluacion" style="width:180px;" (change)="onChangeCriterioEvaluacion(row)" >
                        <option value="">OPCIONES</option>
                        <ng-container
                        *ngIf="row.descripcion_evento.toUpperCase() != 'CELULAR DETECTADO 360' &&
                        row.descripcion_evento.toUpperCase() != 'DISTRACCIÓN DETECTADA 360' &&
                        row.descripcion_evento.toUpperCase() != 'CONDUCTOR SOMNOLIENTO 360' &&
                        row.descripcion_evento.toUpperCase() != 'CONDUCTOR ADORMITADO 360' &&
                        row.descripcion_evento.toUpperCase() != 'CINTURÓN NO DETECTADO 360' &&
                        row.descripcion_evento.toUpperCase() != 'CIGARRO DETECTADO 360'">
                          <optgroup label="SEÑALES DE POSIBLE FATIGA">
                            <option value="BOSTEZO CONTINUO (>3 EN 30 SEG.)">BOSTEZO CONTINUO (>3 EN 30 SEG.)</option>
                            <option value="GESTOS O MOVIMIENTOS A MANERA DE AUTOGESTIÓN">GESTOS O MOVIMIENTOS A MANERA DE AUTOGESTIÓN</option>
                            <option value="INCLINACIÓN DE LA CABEZA HACIA ADELANTE Y HACIA ATRÁS">INCLINACIÓN DE LA CABEZA HACIA ADELANTE Y HACIA ATRÁS</option>
                            <option value="MANO CON ACCIÓN DE FROTACIÓN EN EL OJO">MANO CON ACCIÓN DE FROTACIÓN EN EL OJO</option>
                            <option value="PARPADEO INVOLUNTARIO">PARPADEO INVOLUNTARIO</option>
                          </optgroup>
                          <optgroup label="SIN SEÑALES DE POSIBLE FATIGA">
                            <option value='CARACTERÍSTICA FÍSICA DE OJOS "RASGOS ASIÁTICOS"'>CARACTERÍSTICA FÍSICA DE OJOS "RASGOS ASIÁTICOS"</option>
                            <option value="COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA">COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA</option>
                            <option value="COMUNICACIÓN RADIAL CON EL CONDUCTOR">COMUNICACIÓN RADIAL CON EL CONDUCTOR</option>
                            <option value="EXISTE MIRADA DEL CONDUCTOR AL TABLERO">EXISTE MIRADA DEL CONDUCTOR AL TABLERO</option>
                            <option value="LENTES CORRECTORES MAL PUESTOS">LENTES CORRECTORES MAL PUESTOS</option>
                            <option value="LENTES CORRECTORES CON MARCO REDUCIDO">LENTES CORRECTORES CON MARCO REDUCIDO</option>
                            <option value="LENTES DE SEGURIDAD MAL PUESTOS">LENTES DE SEGURIDAD MAL PUESTOS</option>
                            <option value="OTRO TIPO DE LENTES">OTRO TIPO DE LENTES</option>
                            <option value="PRESENCIA DE DESLUMBRAMIENTOS">PRESENCIA DE DESLUMBRAMIENTOS</option>
                            <option value="USO DE CHULLO, GORRA CON VÍSERA O CASCO">USO DE CHULLO, GORRA CON VÍSERA O CASCO</option>
                            <option value="USO DE MAQUILLAJE EN EL ROSTRO">USO DE MAQUILLAJE EN EL ROSTRO</option>
                            <option value="USO DE SOBRELENTES">USO DE SOBRELENTES</option>
                          </optgroup>
                        </ng-container>
                        <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CELULAR DETECTADO 360'" label="CELULAR DETECTADO">
                            <option value="CELULAR DETECTADO">CELULAR DETECTADO</option>
                            <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                            <option value="CINTA REFLECTIVA DEL CHALECO Y/O CASACA">CINTA REFLECTIVA DEL CHALECO Y/O CASACA</option>
                            <option value="CONDUCTOR HACE USO DE PTT">CONDUCTOR HACE USO DE PTT</option>
                            <option value="MANO A LA ALTURA DEL OÍDO">MANO A LA ALTURA DEL OÍDO</option>
                        </optgroup>
                        <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'DISTRACCIÓN DETECTADA 360'" label="DISTRACCIÓN DETECTADA">
                            <option value="DISTRACCIÓN DETECTADA">DISTRACCIÓN DETECTADA</option>
                            <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                            <option value="EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO">EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO</option>
                            <option value="MANIOBRAS DE CONDUCCIÓN">MANIOBRAS DE CONDUCCIÓN</option>
                            <option value="MANIPULACIÓN DE EQUIPOS DEL TABLERO / VEHÍCULO">MANIPULACIÓN DE EQUIPOS DEL TABLERO / VEHÍCULO</option>
                            <option value="VISUALIZACIÓN DE ESPEJOS LATERALES">VISUALIZACIÓN DE ESPEJOS LATERALES</option>
                            <option value="VISUALIZACIÓN DE VÍA">VISUALIZACIÓN DE VÍA</option>
                        </optgroup>
                        <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CONDUCTOR SOMNOLIENTO 360'" label="CONDUCTOR SOMNOLIENTO">
                            <option value="CONDUCTOR SOMNOLIENTO">CONDUCTOR SOMNOLIENTO</option>
                            <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                            <option value="EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO">EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO</option>
                            <option value="INCLINACIÓN POR MANIPULACIÓN DE EQUIPOS DEL TABLERO">INCLINACIÓN POR MANIPULACIÓN DE EQUIPOS DEL TABLERO</option>
                            <option value="PARPADEO CONSTANTE">PARPADEO CONSTANTE</option>
                            <option value="PRESENCIA DE DESLUMBRAMIENTO HACIA EL CONDUCTOR">PRESENCIA DE DESLUMBRAMIENTO HACIA EL CONDUCTOR</option>
                        </optgroup>
                        <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CONDUCTOR ADORMITADO 360'" label="CONDUCTOR ADORMITADO">
                            <option value="CONDUCTOR ADORMITADO">CONDUCTOR ADORMITADO</option>
                            <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                            <option value="CERRADO DE OJOS POR BOSTEZO">CERRADO DE OJOS POR BOSTEZO</option>
                            <option value="CERRADO DE OJOS POR SONRISA">CERRADO DE OJOS POR SONRISA</option>
                            <option value="CERRADO DE OJOS POR DESLUMBRAMIENTO">CERRADO DE OJOS POR DESLUMBRAMIENTO</option>
                            <option value="EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO">EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO</option>
                            <option value="INCLINACIÓN POR MANIPULACIÓN DE EQUIPOS DEL TABLERO">INCLINACIÓN POR MANIPULACIÓN DE EQUIPOS DEL TABLERO</option>
                        </optgroup>
                        <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CINTURÓN NO DETECTADO 360'" label="CINTURÓN NO DETECTADO">
                            <option value="CINTURÓN NO DETECTADO">CINTURÓN NO DETECTADO</option>
                            <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                            <option value="CINTA REFLECTIVA DEL CHALECO Y/O CASACA">CINTA REFLECTIVA DEL CHALECO Y/O CASACA</option>
                            <option value="DESLUMBRAMIENTO HACIA EL CONDUCTOR">DESLUMBRAMIENTO HACIA EL CONDUCTOR</option>
                            <option value="OBSTRUCCIÓN DE VISUALIZACIÓN DE CINTURÓN">OBSTRUCCIÓN DE VISUALIZACIÓN DE CINTURÓN</option>
                            <option value="USO INADECUADO DEL CINTURÓN">USO INADECUADO DEL CINTURÓN</option>
                        </optgroup>
                        <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CIGARRO DETECTADO 360'" label="CIGARRO DETECTADO">
                            <option value="CIGARRO DETECTADO">CIGARRO DETECTADO</option>
                            <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                            <option value="CINTA REFLECTIVA DEL CHALECO Y/O CASACA">CINTA REFLECTIVA DEL CHALECO Y/O CASACA</option>
                            <option value="CONDUCTOR CON OBJETO EN LA BOCA">CONDUCTOR CON OBJETO EN LA BOCA</option>
                            <option value="CONDUCTOR HACE USO DE PTT">CONDUCTOR HACE USO DE PTT</option>
                            <option value="DESLUMBRAMIENTO HACIA EL CONDUCTOR">DESLUMBRAMIENTO HACIA EL CONDUCTOR</option>
                            <option value="MANO A LA ALTURA DE LA BOCA">MANO A LA ALTURA DE LA BOCA</option>
                        </optgroup>
                        <optgroup label="SEÑALES DE POSIBLE FATIGA" *ngIf="false">

                          <!-- <option value="Inclinación de la cabeza hacia adelante y/o hacia atrás" >Inclinación de la cabeza hacia adelante y/o hacia atrás</option>
                          <option value="Parpadeo involuntario" >Parpadeo involuntario</option>
                          <option value="Gestos o movimientos a manera de autogestión" >Gestos o movimientos a manera de autogestión</option>
                          <option value="Bostezo continuo (>=3 en 30 seg.)" >Bostezo continuo (>=3 en 30 seg.)</option>
                          <option value="Mano con acción de frotación en los ojos" >Mano con acción de frotación en los ojos</option> -->

                          <option value="INCLINACIÓN DE LA CABEZA HACIA ADELANTE Y/O HACIA ATRÁS" >INCLINACIÓN DE LA CABEZA HACIA ADELANTE Y/O HACIA ATRÁS</option>
                          <option value="PARPADEO INVOLUNTARIO" >PARPADEO INVOLUNTARIO</option>
                          <option value="GESTOS O MOVIMIENTOS A MANERA DE AUTOGESTIÓN" >GESTOS O MOVIMIENTOS A MANERA DE AUTOGESTIÓN</option>
                          <option value="BOSTEZO CONTINUO (>=3 EN 30 SEG.)" >BOSTEZO CONTINUO (>=3 EN 30 SEG.)</option>
                          <option value="MANO CON ACCIÓN DE FROTACIÓN EN LOS OJOS" >MANO CON ACCIÓN DE FROTACIÓN EN LOS OJOS</option>


                        </optgroup>
                        <optgroup label="SIN SEÑAL DE POSIBLE FATIGA" *ngIf="false">

                          <!-- <option value="Características de ojos de facciones asiáticas" >Características de ojos de facciones asiáticas</option>
                          <option value="Cobertura de rostro con bufanda y/o chalina" >Cobertura de rostro con bufanda y/o chalina</option>
                          <option value="Existe mirada del conductor al tablero" >Existe mirada del conductor al tablero</option>
                          <option value="Lentes correctores mal puestos" >Lentes correctores mal puestos</option>
                          <option value="Lentes correctores con marco reducido" >Lentes correctores con marco reducido</option>
                          <option value="Lentes de seguridad mal colocados o marco reducido" >Lentes de seguridad mal colocados o marco reducido</option>
                          <option value="Otro tipo de lentes" >Otro tipo de lentes</option>
                          <option value="Presencia de deslumbramientos" >Presencia de deslumbramientos</option>
                          <option value="Uso de chullo, gorra con vísera o casco" >Uso de chullo, gorra con vísera o casco</option>
                          <option value="Uso de sobrelentes" >Uso de sobrelentes</option>
                          <option value="Uso de maquillaje en el rostro" >Uso de maquillaje en el rostro</option> -->

                          <option value="CARACTERÍSTICAS DE OJOS DE FACCIONES ASIÁTICAS" >CARACTERÍSTICAS DE OJOS DE FACCIONES ASIÁTICAS</option>
                          <option value="COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA" >COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA</option>
                          <option value="EXISTE MIRADA DEL CONDUCTOR AL TABLERO" >EXISTE MIRADA DEL CONDUCTOR AL TABLERO</option>
                          <option value="LENTES CORRECTORES MAL PUESTOS" >LENTES CORRECTORES MAL PUESTOS</option>
                          <option value="LENTES CORRECTORES CON MARCO REDUCIDO" >LENTES CORRECTORES CON MARCO REDUCIDO</option>
                          <!-- <option value="LENTES DE SEGURIDAD MAL COLOCADOS O MARCO REDUCIDO" >LENTES DE SEGURIDAD MAL COLOCADOS O MARCO REDUCIDO</option> -->
                          <option value="LENTES DE SEGURIDAD MAL PUESTOS" >LENTES DE SEGURIDAD MAL PUESTOS</option>
                          <option value="OTRO TIPO DE LENTES" >OTRO TIPO DE LENTES</option>
                          <option value="PRESENCIA DE DESLUMBRAMIENTOS" >PRESENCIA DE DESLUMBRAMIENTOS</option>
                          <option value="USO DE CHULLO, GORRA CON VÍSERA O CASCO" >USO DE CHULLO, GORRA CON VÍSERA O CASCO</option>
                          <option value="USO DE SOBRELENTES" >USO DE SOBRELENTES</option>
                          <option value="USO DE MAQUILLAJE EN EL ROSTRO" >USO DE MAQUILLAJE EN EL ROSTRO</option>

                        </optgroup>
                      </select>

                      <span *ngIf="row.es_antiguo_criterio">
												{{row.criterio_evaluacion}}
											</span>

                      <br *ngIf="row.senales_posible_fatiga" >
											<input *ngIf="row.senales_posible_fatiga" style="font-size:12px; width:180px; margin-top: 8px;" type="text" [(ngModel)]="row.identificacion_video" (change)="onChangeObservacion(row)" placeholder="Identificar video">
											<br *ngIf="row.senales_posible_fatiga" >


                        <!-- <div ng-if="row.senales_posible_fatiga" id="form_stars"> -->
                      <div *ngIf="row.senales_posible_fatiga" class="form_stars">
                        <p class="clasificacion">
                            <input id="radio1_{{i}}" name="grupo_{{i}}" type="radio" [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="5"><!--
                          --><label for="radio1_{{i}}">★</label><!--
                          --><input id="radio2_{{i}}" name="grupo_{{i}}" type="radio" [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="4"><!--
                          --><label for="radio2_{{i}}">★</label><!--
                          --><input id="radio3_{{i}}" name="grupo_{{i}}" type="radio" [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="3"><!--
                          --><label for="radio3_{{i}}">★</label><!--
                          --><input id="radio4_{{i}}" name="grupo_{{i}}" type="radio" [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="2"><!--
                          --><label for="radio4_{{i}}">★</label><!--
                          --><input id="radio5_{{i}}" name="grupo_{{i}}" type="radio" [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="1"><!--
                          --><label for="radio5_{{i}}">★</label>
                        </p>
                      </div>



                  </td>

                  <td *ngIf="eC.Observacion"><input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.observacion_evaluacion" (change)="onChangeObservacion(row)" ></td>
                  <!-- <td *ngIf="eC.Validacion"><input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.valoracion_evento"></td> -->

                  <td *ngIf="eC.ComRadial">{{row.ComRadial}}</td>
                  <td *ngIf="eC.VelMobileye">{{row.vel_mobileye}}</td>
                  <td *ngIf="eC.VelGPS">{{row.vel_gps}}</td>
                  <td *ngIf="eC.VelGPSspeed">{{row.vel_gps_speed}}</td>
                  <td *ngIf="eC.VelCAN">{{row.vel_can}}</td>
                  <td *ngIf="eC.VelECO">{{row.vel_eco}}</td>


                  <td *ngIf="eC.Zona">{{row.tramo}}</td>
                  <td *ngIf="eC.PuntoCercano">{{row.PC}}</td>
                  <td *ngIf="eC.Ubicacion">
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td *ngIf="eC.Referencia">{{row.referencia}}</td>
                  <td *ngIf="eC.EnlaceArchivo">
                    <a href="{{row.enlaceImageCIPIA}}" target="_blank">Imagen</a>/<a href="{{row.enlaceVideoCIPIA}}" target="_blank">Video</a>
                  </td>

                  <td *ngIf="eC.OperadorMonitoreo" >

                      <!-- <input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.OperadorMonitoreo"> -->

                      <select [(ngModel)]="row.operador_monitoreo" style="width:180px;" (change)="onChangeOperadorMonitoreo(row)">

                        <option value="">OPCIONES</option>
                        <!-- <option value="ATENCIO ALFARO, Claudia Isabel">ATENCIO ALFARO, Claudia Isabel</option>
                        <option value="FERNANDEZ CRUZ, Rosellia Yanina">FERNANDEZ CRUZ, Rosellia Yanina</option>
                        <option value="HAÑARI SALCEDO, Katherine Liliana">HAÑARI SALCEDO, Katherine Liliana</option>
                        <option value="MAMANI SIERRA, Raquel">MAMANI SIERRA, Raquel</option>
                        <option value="NUÑEZ CHAMBI, Michelle Solzire">NUÑEZ CHAMBI, Michelle Solzire</option>
                        <option value="NUÑONCA ALARCON, Daniela Stefany">NUÑONCA ALARCON, Daniela Stefany</option>
                        <option value="PACCI QUISPE, Betzy Belquis">PACCI QUISPE, Betzy Belquis</option>
                        <option value="RODRIGUEZ GUTIERREZ, Reyli Yussef">PACHECO MOLLO, Cesar Vidal</option>
                        <option value="ROJAS RONDON, Carla Beatriz">ROJAS RONDON, Carla Beatriz</option>
                        <option value="SUARES PACURI, Ayelen Melani">SUARES PACURI, Ayelen Melani</option> -->
                        <!-- EN ESPERA DE USAR OPERADORES DE PANEL ADMIN -->
                        <option *ngFor="let operator of operators" [value]="operator.value">{{ operator.label }}</option>
                        <option value="OTRO">OTRO</option>
                      </select>

                      <span *ngIf="row.operador_monitoreo == 'OTRO'">
												<input style="font-size: 12px;" size="28" type="text" [(ngModel)]="row.otro_operador_monitoreo" (change)="onChangeOperadorMonitoreo(row)">
											</span>

											<span *ngIf="row.es_antiguo_operador">
												{{row.operador_monitoreo}}
											</span>


                  </td>





                </tr>
              </tbody>

            </table>
          </ng-container>

        </div>

      </ng-container>

    </div>
    <!-- R038. REPORTE DE ATENCION DE EVENTOS TLINEA -->
  <ng-container *ngIf="lineaUser() && report_data.num_rep == 'R038'">
    <div class="container grand-container multitable-container">
      <!-- <ng-container *ngIf="!chkTableDropdown"> -->
      <ng-container>
        <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">
      
      
          <div class="p-card">
      
            <div class="subtable-title mb-2">
              <div style="width: 50%; min-width: 50%;">
                <div style="padding: 20px;">
                  {{period}}
                </div>
              </div>
              <div>
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover"
                    (click)="actualizarEvaluacionEventos();">ACTUALIZAR</button>
      
                </div>
              </div>
              <div>
                <div style="padding: 20px;">
                  <button class="btn btn-gl btn-gl-light-blue btn-dark-hover"
                    (click)="guardarEvaluacionEventos();">GUARDAR</button>
                </div>
              </div>
            </div>
      
          </div>
          <ng-container *ngIf="dtRendered">
            <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger"
              class="table table-striped table-border row-border hover">
              <thead>
                <tr>
      
                  <th>Item</th>
      
      
                  <th *ngIf="!chkDateHour && eC.Fecha">Fecha/Hora Evento</th>
                  <th *ngIf="chkDateHour && eC.Fecha">Fecha Evento</th>
                  <th *ngIf="chkDateHour && eC.Fecha">Hora Evento</th>
                  <th *ngIf="!chkDateHour && eC.FechaServidor">Fecha/Hora Servidor</th>
                  <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor ">Fecha Servidor</th>
                  <th *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">Hora Servidor</th>
      
                  <th *ngIf="eC.NombreEvento" >Nombre del Evento</th>
                  <th *ngIf="eC.Evento">Descripción</th>
      
                  <th *ngIf="eC.Codigo">Código</th>
                  <th *ngIf="eC.Placa">Placa</th>
                  <th *ngIf="eC.TipoUnidad">Tipo de Unidad</th>
      
                  <th *ngIf="eC.IdConductor">ID Conductor</th>
                  <th *ngIf="eC.Conductor">Conductor</th>
      
      
                  <th *ngIf="eC.FechaEvaluacion">Fecha de Evaluación</th>
                  <th *ngIf="eC.CriterioEvaluacion">Criterio de Evaluación</th>
                  <th *ngIf="eC.Observacion">Observación</th>
                  <!-- <th *ngIf="eC.Validacion">Validación</th> -->
      
      
                  <th *ngIf="eC.ComRadial">Comunicación Radial</th>
                  <th *ngIf="eC.VelMobileye">Vel.Mobileye</th>
                  <th *ngIf="eC.VelGPS">Vel.GPS</th>
                  <th *ngIf="eC.VelGPSspeed">Vel.GPS speed</th>
                  <th *ngIf="eC.VelCAN">Vel.CAN</th>
                  <th *ngIf="eC.VelECO">Vel.ECO</th>
      
                  <th *ngIf="eC.Zona">Geocerca</th>
                  <th *ngIf="eC.PuntoCercano">Punto Cercano</th>
                  <th *ngIf="eC.Ubicacion">Ubicación</th>
                  <th *ngIf="eC.Referencia">Referencia</th>
                  <th *ngIf="eC.EnlaceArchivo">Enlace a Archivo</th>
                  <!-- <th *ngIf="eC.Parametros">Parámetros</th> -->
      
                  <th *ngIf="eC.OperadorMonitoreo">Operador de Monitoreo</th>
      
                </tr>
              </thead>
      
              <tbody>
                <tr *ngFor="let row of v_table[1];  let i = index;">
      
                  <td>{{i + 1}}</td>
      
      
                  <td *ngIf="!chkDateHour && eC.Fecha">{{row.fecha_tracker}}</td>
                  <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour && eC.Fecha">{{row.fecha_tracker.split(' ')[1]}}</td>
      
                  <td *ngIf="!chkDateHour && eC.FechaServidor">{{row.fecha_servidor}}</td>
                  <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour && user_id != 923 && eC.FechaServidor">{{row.fecha_servidor.split(' ')[1]}}</td>
      
                  <td *ngIf="eC.NombreEvento">{{row.nombre_evento}}</td>
                  <td *ngIf="eC.Evento">{{row.descripcion_evento}}</td>
      
                  <td *ngIf="eC.Codigo">{{row.codigo}}</td>
                  <td *ngIf="eC.Placa">{{row.placa}}</td>
                  <td *ngIf="eC.TipoUnidad">{{row.tipo_unidad}}</td>
      
      
                  <td *ngIf="eC.IdConductor">{{row.idConductor}}</td>
                  <td *ngIf="eC.Conductor">{{row.conductor}}</td>
      
                  <td *ngIf="eC.FechaEvaluacion">{{row.fecha_evaluacion}}</td>
      
      
                  <!--
                        $result[$i][1][$j]->fecha_evaluacion = "";
                        $result[$i][1][$j]->criterio_evaluacion = "";
                        $result[$i][1][$j]->observacion_evaluacion = "";
                        $result[$i][1][$j]->operador_monitoreo = "";
      
                        $result[$i][1][$j]->senales_posible_fatiga = false;
                        $result[$i][1][$j]->identificacion_video = "";
                        $result[$i][1][$j]->valoracion_evento = "1"; -->
      
      
                  <td *ngIf="eC.CriterioEvaluacion">
                    <!-- <input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.CriterioEvaluacion"> -->
                    <!-- <select [(ngModel)]="row.criterio_evaluacion" style="width:180px;"
                      (change)="onChangeCriterioEvaluacion(row)" >
                      <option value="">OPCIONES</option>
                      <ng-container *ngIf="row.descripcion_evento.toUpperCase() != 'CELULAR DETECTADO 360' &&
                              row.descripcion_evento.toUpperCase() != 'DISTRACCIÓN DETECTADA 360' &&
                              row.descripcion_evento.toUpperCase() != 'CONDUCTOR SOMNOLIENTO 360' &&
                              row.descripcion_evento.toUpperCase() != 'CONDUCTOR ADORMITADO 360' &&
                              row.descripcion_evento.toUpperCase() != 'CINTURÓN NO DETECTADO 360' &&
                              row.descripcion_evento.toUpperCase() != 'CIGARRO DETECTADO 360'">
                        <optgroup label="SEÑALES DE POSIBLE FATIGA">
                          <option value="BOSTEZO CONTINUO (>3 EN 30 SEG.)">BOSTEZO CONTINUO (>3 EN 30 SEG.)</option>
                          <option value="GESTOS O MOVIMIENTOS A MANERA DE AUTOGESTIÓN">GESTOS O MOVIMIENTOS A MANERA DE
                            AUTOGESTIÓN</option>
                          <option value="INCLINACIÓN DE LA CABEZA HACIA ADELANTE Y HACIA ATRÁS">INCLINACIÓN DE LA CABEZA HACIA
                            ADELANTE Y HACIA ATRÁS</option>
                          <option value="MANO CON ACCIÓN DE FROTACIÓN EN EL OJO">MANO CON ACCIÓN DE FROTACIÓN EN EL OJO
                          </option>
                          <option value="PARPADEO INVOLUNTARIO">PARPADEO INVOLUNTARIO</option>
                        </optgroup>
                        <optgroup label="SIN SEÑALES DE POSIBLE FATIGA">
                          <option value='CARACTERÍSTICA FÍSICA DE OJOS "RASGOS ASIÁTICOS"'>CARACTERÍSTICA FÍSICA DE OJOS
                            "RASGOS ASIÁTICOS"</option>
                          <option value="COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA">COBERTURA DE ROSTRO CON BUFANDA Y/O
                            CHALINA</option>
                          <option value="COMUNICACIÓN RADIAL CON EL CONDUCTOR">COMUNICACIÓN RADIAL CON EL CONDUCTOR</option>
                          <option value="EXISTE MIRADA DEL CONDUCTOR AL TABLERO">EXISTE MIRADA DEL CONDUCTOR AL TABLERO
                          </option>
                          <option value="LENTES CORRECTORES MAL PUESTOS">LENTES CORRECTORES MAL PUESTOS</option>
                          <option value="LENTES CORRECTORES CON MARCO REDUCIDO">LENTES CORRECTORES CON MARCO REDUCIDO</option>
                          <option value="LENTES DE SEGURIDAD MAL PUESTOS">LENTES DE SEGURIDAD MAL PUESTOS</option>
                          <option value="OTRO TIPO DE LENTES">OTRO TIPO DE LENTES</option>
                          <option value="PRESENCIA DE DESLUMBRAMIENTOS">PRESENCIA DE DESLUMBRAMIENTOS</option>
                          <option value="USO DE CHULLO, GORRA CON VÍSERA O CASCO">USO DE CHULLO, GORRA CON VÍSERA O CASCO
                          </option>
                          <option value="USO DE MAQUILLAJE EN EL ROSTRO">USO DE MAQUILLAJE EN EL ROSTRO</option>
                          <option value="USO DE SOBRELENTES">USO DE SOBRELENTES</option>
                        </optgroup>
                      </ng-container>
                      <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CELULAR DETECTADO 360'"
                        label="CELULAR DETECTADO">
                        <option value="CELULAR DETECTADO">CELULAR DETECTADO</option>
                        <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                        <option value="CINTA REFLECTIVA DEL CHALECO Y/O CASACA">CINTA REFLECTIVA DEL CHALECO Y/O CASACA
                        </option>
                        <option value="CONDUCTOR HACE USO DE PTT">CONDUCTOR HACE USO DE PTT</option>
                        <option value="MANO A LA ALTURA DEL OÍDO">MANO A LA ALTURA DEL OÍDO</option>
                      </optgroup>
                      <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'DISTRACCIÓN DETECTADA 360'"
                        label="DISTRACCIÓN DETECTADA">
                        <option value="DISTRACCIÓN DETECTADA">DISTRACCIÓN DETECTADA</option>
                        <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                        <option value="EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO">EXISTE MIRADA DEL CONDUCTOR HACIA EL
                          TABLERO</option>
                        <option value="MANIOBRAS DE CONDUCCIÓN">MANIOBRAS DE CONDUCCIÓN</option>
                        <option value="MANIPULACIÓN DE EQUIPOS DEL TABLERO / VEHÍCULO">MANIPULACIÓN DE EQUIPOS DEL TABLERO /
                          VEHÍCULO</option>
                        <option value="VISUALIZACIÓN DE ESPEJOS LATERALES">VISUALIZACIÓN DE ESPEJOS LATERALES</option>
                        <option value="VISUALIZACIÓN DE VÍA">VISUALIZACIÓN DE VÍA</option>
                      </optgroup>
                      <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CONDUCTOR SOMNOLIENTO 360'"
                        label="CONDUCTOR SOMNOLIENTO">
                        <option value="CONDUCTOR SOMNOLIENTO">CONDUCTOR SOMNOLIENTO</option>
                        <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                        <option value="EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO">EXISTE MIRADA DEL CONDUCTOR HACIA EL
                          TABLERO</option>
                        <option value="INCLINACIÓN POR MANIPULACIÓN DE EQUIPOS DEL TABLERO">INCLINACIÓN POR MANIPULACIÓN DE
                          EQUIPOS DEL TABLERO</option>
                        <option value="PARPADEO CONSTANTE">PARPADEO CONSTANTE</option>
                        <option value="PRESENCIA DE DESLUMBRAMIENTO HACIA EL CONDUCTOR">PRESENCIA DE DESLUMBRAMIENTO HACIA EL
                          CONDUCTOR</option>
                      </optgroup>
                      <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CONDUCTOR ADORMITADO 360'"
                        label="CONDUCTOR ADORMITADO">
                        <option value="CONDUCTOR ADORMITADO">CONDUCTOR ADORMITADO</option>
                        <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                        <option value="CERRADO DE OJOS POR BOSTEZO">CERRADO DE OJOS POR BOSTEZO</option>
                        <option value="CERRADO DE OJOS POR SONRISA">CERRADO DE OJOS POR SONRISA</option>
                        <option value="CERRADO DE OJOS POR DESLUMBRAMIENTO">CERRADO DE OJOS POR DESLUMBRAMIENTO</option>
                        <option value="EXISTE MIRADA DEL CONDUCTOR HACIA EL TABLERO">EXISTE MIRADA DEL CONDUCTOR HACIA EL
                          TABLERO</option>
                        <option value="INCLINACIÓN POR MANIPULACIÓN DE EQUIPOS DEL TABLERO">INCLINACIÓN POR MANIPULACIÓN DE
                          EQUIPOS DEL TABLERO</option>
                      </optgroup>
                      <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CINTURÓN NO DETECTADO 360'"
                        label="CINTURÓN NO DETECTADO">
                        <option value="CINTURÓN NO DETECTADO">CINTURÓN NO DETECTADO</option>
                        <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                        <option value="CINTA REFLECTIVA DEL CHALECO Y/O CASACA">CINTA REFLECTIVA DEL CHALECO Y/O CASACA
                        </option>
                        <option value="DESLUMBRAMIENTO HACIA EL CONDUCTOR">DESLUMBRAMIENTO HACIA EL CONDUCTOR</option>
                        <option value="OBSTRUCCIÓN DE VISUALIZACIÓN DE CINTURÓN">OBSTRUCCIÓN DE VISUALIZACIÓN DE CINTURÓN
                        </option>
                        <option value="USO INADECUADO DEL CINTURÓN">USO INADECUADO DEL CINTURÓN</option>
                      </optgroup>
                      <optgroup *ngIf="row.descripcion_evento.toUpperCase() == 'CIGARRO DETECTADO 360'"
                        label="CIGARRO DETECTADO">
                        <option value="CIGARRO DETECTADO">CIGARRO DETECTADO</option>
                        <option value="ACCIÓN INSEGURA">ACCIÓN INSEGURA</option>
                        <option value="CINTA REFLECTIVA DEL CHALECO Y/O CASACA">CINTA REFLECTIVA DEL CHALECO Y/O CASACA
                        </option>
                        <option value="CONDUCTOR CON OBJETO EN LA BOCA">CONDUCTOR CON OBJETO EN LA BOCA</option>
                        <option value="CONDUCTOR HACE USO DE PTT">CONDUCTOR HACE USO DE PTT</option>
                        <option value="DESLUMBRAMIENTO HACIA EL CONDUCTOR">DESLUMBRAMIENTO HACIA EL CONDUCTOR</option>
                        <option value="MANO A LA ALTURA DE LA BOCA">MANO A LA ALTURA DE LA BOCA</option>
                      </optgroup>
                      <optgroup label="SEÑALES DE POSIBLE FATIGA" *ngIf="false"> -->
      
                        <!-- <option value="Inclinación de la cabeza hacia adelante y/o hacia atrás" >Inclinación de la cabeza hacia adelante y/o hacia atrás</option>
                                <option value="Parpadeo involuntario" >Parpadeo involuntario</option>
                                <option value="Gestos o movimientos a manera de autogestión" >Gestos o movimientos a manera de autogestión</option>
                                <option value="Bostezo continuo (>=3 en 30 seg.)" >Bostezo continuo (>=3 en 30 seg.)</option>
                                <option value="Mano con acción de frotación en los ojos" >Mano con acción de frotación en los ojos</option> -->
      
                        <!-- <option value="INCLINACIÓN DE LA CABEZA HACIA ADELANTE Y/O HACIA ATRÁS">INCLINACIÓN DE LA CABEZA HACIA
                          ADELANTE Y/O HACIA ATRÁS</option>
                        <option value="PARPADEO INVOLUNTARIO">PARPADEO INVOLUNTARIO</option>
                        <option value="GESTOS O MOVIMIENTOS A MANERA DE AUTOGESTIÓN">GESTOS O MOVIMIENTOS A MANERA DE
                          AUTOGESTIÓN</option>
                        <option value="BOSTEZO CONTINUO (>=3 EN 30 SEG.)">BOSTEZO CONTINUO (>=3 EN 30 SEG.)</option>
                        <option value="MANO CON ACCIÓN DE FROTACIÓN EN LOS OJOS">MANO CON ACCIÓN DE FROTACIÓN EN LOS OJOS
                        </option>
      
      
                      </optgroup>
                      <optgroup label="SIN SEÑAL DE POSIBLE FATIGA" *ngIf="false"> -->
      
                        <!-- <option value="Características de ojos de facciones asiáticas" >Características de ojos de facciones asiáticas</option>
                                <option value="Cobertura de rostro con bufanda y/o chalina" >Cobertura de rostro con bufanda y/o chalina</option>
                                <option value="Existe mirada del conductor al tablero" >Existe mirada del conductor al tablero</option>
                                <option value="Lentes correctores mal puestos" >Lentes correctores mal puestos</option>
                                <option value="Lentes correctores con marco reducido" >Lentes correctores con marco reducido</option>
                                <option value="Lentes de seguridad mal colocados o marco reducido" >Lentes de seguridad mal colocados o marco reducido</option>
                                <option value="Otro tipo de lentes" >Otro tipo de lentes</option>
                                <option value="Presencia de deslumbramientos" >Presencia de deslumbramientos</option>
                                <option value="Uso de chullo, gorra con vísera o casco" >Uso de chullo, gorra con vísera o casco</option>
                                <option value="Uso de sobrelentes" >Uso de sobrelentes</option>
                                <option value="Uso de maquillaje en el rostro" >Uso de maquillaje en el rostro</option> -->
      
                        <!-- <option value="CARACTERÍSTICAS DE OJOS DE FACCIONES ASIÁTICAS">CARACTERÍSTICAS DE OJOS DE FACCIONES
                          ASIÁTICAS</option>
                        <option value="COBERTURA DE ROSTRO CON BUFANDA Y/O CHALINA">COBERTURA DE ROSTRO CON BUFANDA Y/O
                          CHALINA</option>
                        <option value="EXISTE MIRADA DEL CONDUCTOR AL TABLERO">EXISTE MIRADA DEL CONDUCTOR AL TABLERO</option>
                        <option value="LENTES CORRECTORES MAL PUESTOS">LENTES CORRECTORES MAL PUESTOS</option>
                        <option value="LENTES CORRECTORES CON MARCO REDUCIDO">LENTES CORRECTORES CON MARCO REDUCIDO</option> -->
                        <!-- <option value="LENTES DE SEGURIDAD MAL COLOCADOS O MARCO REDUCIDO" >LENTES DE SEGURIDAD MAL COLOCADOS O MARCO REDUCIDO</option> -->
                        <!-- <option value="LENTES DE SEGURIDAD MAL PUESTOS">LENTES DE SEGURIDAD MAL PUESTOS</option>
                        <option value="OTRO TIPO DE LENTES">OTRO TIPO DE LENTES</option>
                        <option value="PRESENCIA DE DESLUMBRAMIENTOS">PRESENCIA DE DESLUMBRAMIENTOS</option>
                        <option value="USO DE CHULLO, GORRA CON VÍSERA O CASCO">USO DE CHULLO, GORRA CON VÍSERA O CASCO
                        </option>
                        <option value="USO DE SOBRELENTES">USO DE SOBRELENTES</option>
                        <option value="USO DE MAQUILLAJE EN EL ROSTRO">USO DE MAQUILLAJE EN EL ROSTRO</option>
      
                      </optgroup>
                    </select> -->
      
                    <span *ngIf="row.es_antiguo_criterio">
                      {{row.criterio_evaluacion}}
                    </span>
      
                    <!-- <br *ngIf="row.senales_posible_fatiga">
                    <input *ngIf="row.senales_posible_fatiga" style="font-size:12px; width:180px; margin-top: 8px;"
                      type="text" [(ngModel)]="row.identificacion_video" (change)="onChangeObservacion(row)"
                      placeholder="Identificar video">
                    <br *ngIf="row.senales_posible_fatiga"> -->
      
      
                    <!-- <div ng-if="row.senales_posible_fatiga" id="form_stars"> -->
                    <!-- <div *ngIf="row.senales_posible_fatiga" class="form_stars">
                      <p class="clasificacion">
                        <input id="radio1_{{i}}" name="grupo_{{i}}" type="radio" [(ngModel)]="row.valoracion_evento"
                          (change)="onChangeObservacion(row)" value="5">
                          <label for="radio1_{{i}}">★</label>
                                <input id="radio2_{{i}}" name="grupo_{{i}}" type="radio"
                          [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="4">
                          <label for="radio2_{{i}}">★</label>
                                <input id="radio3_{{i}}" name="grupo_{{i}}" type="radio"
                          [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="3">
                          <label for="radio3_{{i}}">★</label>
                          <input id="radio4_{{i}}" name="grupo_{{i}}" type="radio"
                          [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="2">
                          <label for="radio4_{{i}}">★</label>
                                <input id="radio5_{{i}}" name="grupo_{{i}}" type="radio"
                          [(ngModel)]="row.valoracion_evento" (change)="onChangeObservacion(row)" value="1">
                          <label for="radio5_{{i}}">★</label>
                      </p>
                    </div> -->
      
      
      
                  </td>
                  <td *ngIf="eC.Observacion">
                    {{row.observacion_evaluacion}}
                    <!-- <input style="font-size: 12px;" size="30" type="text"
                    [(ngModel)]="row.observacion_evaluacion" (change)="onChangeObservacion(row)">
                  </td> -->
                  <!-- <td *ngIf="eC.Validacion"><input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.valoracion_evento"></td> -->
                  
                  
                  <td *ngIf="eC.ComRadial">{{row.ComRadial}}</td>
                  <td *ngIf="eC.VelMobileye">{{row.vel_mobileye}}</td>
                  <td *ngIf="eC.VelGPS">{{row.vel_gps}}</td>
                  <td *ngIf="eC.VelGPSspeed">{{row.vel_gps_speed}}</td>
                  <td *ngIf="eC.VelCAN">{{row.vel_can}}</td>
                  <td *ngIf="eC.VelECO">{{row.vel_eco}}</td>
      
      
                  <td *ngIf="eC.Zona">{{row.tramo}}</td>
                  <td *ngIf="eC.PuntoCercano">{{row.PC}}</td>
                  <td *ngIf="eC.Ubicacion">
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m"
                      target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
                  <td *ngIf="eC.Referencia">{{row.referencia}}</td>
                  <td *ngIf="eC.EnlaceArchivo">
                    <a href="{{row.enlaceImageCIPIA}}" target="_blank">Imagen</a>/<a href="{{row.enlaceVideoCIPIA}}"
                      target="_blank">Video</a>
                  </td>
      
                  <td *ngIf="eC.OperadorMonitoreo">
      
                    <!-- <input style="font-size: 12px;" size="30" type="text" [(ngModel)]="row.OperadorMonitoreo"> -->
      
                    <!-- <select [(ngModel)]="row.operador_monitoreo" style="width:180px;"
                      (change)="onChangeOperadorMonitoreo(row)">
      
                      <option value="">OPCIONES</option> -->
                      <!-- <option value="ATENCIO ALFARO, Claudia Isabel">ATENCIO ALFARO, Claudia Isabel</option>
                              <option value="FERNANDEZ CRUZ, Rosellia Yanina">FERNANDEZ CRUZ, Rosellia Yanina</option>
                              <option value="HAÑARI SALCEDO, Katherine Liliana">HAÑARI SALCEDO, Katherine Liliana</option>
                              <option value="MAMANI SIERRA, Raquel">MAMANI SIERRA, Raquel</option>
                              <option value="NUÑEZ CHAMBI, Michelle Solzire">NUÑEZ CHAMBI, Michelle Solzire</option>
                              <option value="NUÑONCA ALARCON, Daniela Stefany">NUÑONCA ALARCON, Daniela Stefany</option>
                              <option value="PACCI QUISPE, Betzy Belquis">PACCI QUISPE, Betzy Belquis</option>
                              <option value="RODRIGUEZ GUTIERREZ, Reyli Yussef">PACHECO MOLLO, Cesar Vidal</option>
                              <option value="ROJAS RONDON, Carla Beatriz">ROJAS RONDON, Carla Beatriz</option>
                              <option value="SUARES PACURI, Ayelen Melani">SUARES PACURI, Ayelen Melani</option>
                      EN ESPERA DE USAR OPERADORES DE PANEL ADMIN -->
                      <!-- <option *ngFor="let operator of operators" [value]="operator.value">{{ operator.label }}</option>
                      <option value="OTRO">OTRO</option>
                    </select>
      
                    <span *ngIf="row.operador_monitoreo == 'OTRO'">
                      <input style="font-size: 12px;" size="28" type="text" [(ngModel)]="row.otro_operador_monitoreo"
                        (change)="onChangeOperadorMonitoreo(row)">
                    </span>
       -->
                    <span *ngIf="row.es_antiguo_operador">
                      {{row.operador_monitoreo}}
                    </span>
      
      
                  </td>
      
      
      
      
      
                </tr>
              </tbody>
      
            </table>
          </ng-container>
      
        </div>
      
      </ng-container>
    </div>
  </ng-container>

    <!-- R039. REPORTE DE EXCESOS DE VELOCIDAD (NUEVO FORMATO) -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R039'">
      <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

        <div class="subtable-title mb-3">
          <!-- <div>
            <div>VEHÍCULO</div>
            <div>{{v_table[0][1]}}</div>
          </div> -->
          <div style="width: 100%; min-width: 100%;">
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>

        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
            <thead>
              <tr>
                  <th>Item</th>
                  <th *ngIf="!chkDateHour">Fecha/Hora</th>
                  <th *ngIf="chkDateHour">Fecha</th>
                  <th *ngIf="chkDateHour">Hora</th>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Tipo de Unidad</th>

                  <th>Id Conductor</th>
                  <th>Conductor</th>

                  <th>Geocerca</th>
                  <!-- new 29-01 -->
                  <!-- <th>Vel. Can</th>
                  <th>Satélite</th> -->

                  <th>Límite de Velocidad</th>
                  <th>Exceso de Velocidad</th>
                  <th>Ubicación</th>

              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td *ngIf="!chkDateHour">{{row.fecha_inicio}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_inicio.split(' ')[1]}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>
                  <td>{{row.tipo_unidad}}</td>

                  <td>{{row.idConductor}}</td>
                  <td>{{row.conductor}}</td>

                  <td>{{row.nombre_zona}}</td>
                  <!-- new 29-01 -->
                  <!-- <td>{{row.can_speed}}</td>
                  <td>{{row.satelite}}</td> -->

                  <td>{{row.v0}}</td>
                  <td>{{row.velocidad}}</td>
                  <td>
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>

              </tr>
            </tbody>

          </table>
        </ng-container>

      </div>
    </div>


    <!-- R041. REPORTE DE EXCESOS DE VELOCIDAD - DURACION, TOLERANCIA, NIVEL DE RIESGO -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R041' || report_data.num_rep == 'R044'">
      <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

        <div class="subtable-title mb-3">
          <!-- <div>
            <div>VEHÍCULO</div>
            <div>{{v_table[0][1]}}</div>
          </div> -->
          <div style="width: 100%; min-width: 100%;">
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>

        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions4" class="table table-border row-border hover">
            <thead>
              <tr *ngIf="chkRiesgo">
                  <th rowspan="2">Item</th>
                  <th rowspan="2" *ngIf="eC.Fecha">Fecha</th>
                  <th rowspan="2" *ngIf="eC.Hora">Hora</th>
                  <th rowspan="2" *ngIf="eC.Codigo">Unidad</th>
                  <th rowspan="2" *ngIf="eC.Placa">Placa</th>
                  <th rowspan="2" *ngIf="eC.Zona">Zona</th>
                  <th rowspan="2" >Límite de Velocidad de Zona</th>

                  <!-- <th rowspan="2" *ngIf="!chkRiesgo">Exceso de Velocidad</th> -->

                  <th colspan="3" *ngIf="chkRiesgo">Exceso de Velocidad</th>

                  <th rowspan="2">Inicio del Exceso</th>
                  <th rowspan="2">Hora del Exceso</th>
                  <th rowspan="2">Fin del Exceso</th>
                  <th rowspan="2">Duración</th>

                  <th rowspan="2" *ngIf="eC.IdConductor">ID Conductor</th>
                  <th rowspan="2" *ngIf="eC.Conductor">Conductor</th>

                  <th rowspan="2" *ngIf="eC.VelMobileye">Vel.ADAS</th>
                  <th rowspan="2" *ngIf="eC.VelGPSspeed">Vel.GPS</th>
                  <th rowspan="2" *ngIf="eC.VelCAN">Vel.CAN</th>
                  <th rowspan="2" *ngIf="eC.Satelite">Satélite</th>
                  <th rowspan="2" *ngIf="eC.Ubicacion">Ubicación</th>

              </tr>

              <tr *ngIf="chkRiesgo">
                <th style="background: #ddd; color : blue;" *ngIf="chkRiesgo">Leve(1,2)</th>
                <th style="background: #ddd; color : #FFC000;" *ngIf="chkRiesgo">Grave(3,5)</th>
                <th style="background: #ddd; color : red;" *ngIf="chkRiesgo">Muy Grave(>=6)</th>
              </tr>


              <tr *ngIf="!chkRiesgo">
                <th>Item</th>
                <th *ngIf="eC.Fecha">Fecha</th>
                <th *ngIf="eC.Hora">Hora</th>
                <th *ngIf="eC.Codigo">Unidad</th>
                <th *ngIf="eC.Placa">Placa</th>
                <th *ngIf="eC.Zona">Zona</th>
                <th >Límite de Velocidad de Zona</th>

                <th >Exceso de Velocidad</th>

                <!-- <th colspan="3" *ngIf="chkRiesgo">Exceso de Velocidad</th> -->

                <th >Inicio del Exceso</th>
                <th >Hora del Exceso</th>
                <th >Fin del Exceso</th>
                <th >Duración</th>

                <th *ngIf="eC.IdConductor">ID Conductor</th>
                <th *ngIf="eC.Conductor">Conductor</th>

                <th *ngIf="eC.VelMobileye">Vel.ADAS</th>
                <th *ngIf="eC.VelGPSspeed">Vel.GPS</th>
                <th *ngIf="eC.VelCAN">Vel.CAN</th>
                <th *ngIf="eC.Satelite">Satélite</th>
                <th *ngIf="eC.Ubicacion">Ubicación</th>

              </tr>



            </thead>


            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">


                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" >{{row.col1}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Fecha">{{row.fecha_inicio.split(' ')[0]}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Hora">{{row.fecha_inicio.split(' ')[1]}}</td>

                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Codigo">{{row.codigo}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Placa">{{row.placa}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Zona">{{row.nombre_zona}}</td>

                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" >{{row.v0}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="!chkRiesgo">{{row.velocidad}}</td>

                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="chkRiesgo">{{row.vel_leve}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="chkRiesgo">{{row.vel_grave}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="chkRiesgo">{{row.vel_muy_grave}}</td>

                  <!-- <td>{{row.fecha_inicio}}</td>
                  <td>{{row.hora_exceso}}</td>
                  <td>{{row.fin_exceso}}</td>
                  <td>{{row.duracion2}}</td> -->

                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" >{{row.fecha_inicio.split(' ')[1]}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" >{{row.hora_exceso.split(' ')[1]}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" >{{row.fin_exceso.split(' ')[1]}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" >{{row.duracion2}}</td>

                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.IdConductor">{{row.idConductor}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Conductor">{{row.conductor}}</td>

                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.VelMobileye">{{row.velMobileye_ME460}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.VelGPSspeed">{{row.GPS_speed}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.VelCAN">{{row.can_speed}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Satelite">{{row.sat}}</td>
                  <td [style.background-color]="row.es_resumen ? '#E5E3D9' : 'white'" *ngIf="eC.Ubicacion">
                    <a *ngIf="!row.es_resumen" href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>
              </tr>
            </tbody>

          </table>
        </ng-container>

      </div>
    </div>


    <!-- R042. REPORTE DE COMBUSTIBLE RESUMEN -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R042'">
      <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

        <div class="subtable-title mb-3">
          <!-- <div>
            <div>VEHÍCULO</div>
            <div>{{v_table[0][1]}}</div>
          </div> -->
          <div style="width: 100%; min-width: 100%;">
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>

        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions4" class="table table-border row-border hover">
            <thead>

              <tr >
                  <!-- -------------------------------- -->
                  <th>Item</th>
                  <th>Código</th>
                  <th>Placa</th>

                  <th>Fecha Inicio GPS</th>
                  <th>Hora Inicio GPS</th>
                  <th>Fecha Fin GPS</th>
                  <th>Hora Fin GPS</th>

                  <th>Distancia Recorrida</th>
                  <th>Combustible Consumido</th>
                  <th>Rendimiento por Galón</th>
                  <th>Vel. Promedio</th>

                  <th>Tiempo en Movimiento</th>
                  <th>Tiempo Relenti</th>
                  <th>Tiempo Apagado</th>

                  <th>Odómetro Inicial</th>
                  <th>Odometro Final</th>

              </tr>

            </thead>


            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td>{{row.codigo}}</td>
                  <td>{{row.placa}}</td>

                  <td>{{row.fecha_inicio}}</td>
                  <td>{{row.hora_inicio}}</td>
                  <td>{{row.fecha_final}}</td>
                  <td>{{row.hora_final}}</td>


                  <td>{{row.total_distancia}}</td>
                  <td>{{row.combustible_consumido}}</td>
                  <td>{{row.rendimiento_por_galon}}</td>
                  <td>{{row.velocidad_promedio}}</td>

                  <td>{{row.tiempo_movimiento}}</td>
                  <td>{{row.tiempo_relenti}}</td>
                  <td>{{row.tiempo_apagado}}</td>

                  <td>{{row.odometro_ini}}</td>
                  <td>{{row.odometro_fin}}</td>

              </tr>
            </tbody>

          </table>
        </ng-container>

      </div>
    </div>

    <!-- R043. REPORTE DE ESTADO DE TRANSMISION -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R043'">
      <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">
        <div class="subtable-title mb-3">
          <div>
            <div>VEHÍCULO</div>
            <div>{{v_table[0]}}</div>
          </div>
          <div>
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>
        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
            <thead>
              <tr>

                <th>Item</th>
                <th>Estado</th>

                <th *ngIf="!chkDateHour">Fecha/Hora de Inicio</th>
                <th *ngIf="chkDateHour">Fecha Inicio</th>
                <th *ngIf="chkDateHour">Hora Inicio</th>
                <th *ngIf="!chkDateHour">Fecha/Hora de Fin</th>
                <th *ngIf="chkDateHour">Fecha Fin</th>
                <th *ngIf="chkDateHour">Hora Fin</th>

                <th>Duración</th>
                <th>Punto Cercano</th>
                <th>Geocerca</th>
                <th>Referencia</th>
                <th>Ubicación</th>

              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <th>{{row.estado}}</th>

                  <td *ngIf="!chkDateHour">{{row.fecha_ini}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_ini.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_ini.split(' ')[1]}}</td>

                  <td *ngIf="!chkDateHour">{{row.fecha_fin}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_fin.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_fin.split(' ')[1]}}</td>


                  <th>{{row.duracion}}</th>
                  <td>{{row.punto_cercano}}</td>
                  <td>{{row.zona}}</td>
                  <td>{{row.reference}}</td>
                  <td [style.background-color]="'white'">
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>

              </tr>
            </tbody>

          </table>
        </ng-container>

      </div>
    </div>


    <!-- R045. REPORTE DE BOLIVIA -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R045'">
      <!-- <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">
        <div class="subtable-title mb-3">
          <div>
            <div>VEHÍCULO</div>
            <div>{{v_table[0]}}</div>
          </div>
          <div>
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>
        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
            <thead>
              <tr>

                <th>Item</th>
               

              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                

              </tr>
            </tbody>

          </table>
        </ng-container>

      </div> -->
    </div>

    
    <!-- R046. REPORTE DE ESTADO DE TRANSMISION (EVENTOS) -->
    <div class="container grand-container multitable-container" *ngIf="report_data.num_rep == 'R046'">
      <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 0.8rem;">
        <div class="subtable-title mb-3">
          <div>
            <div>VEHÍCULO</div>
            <div *ngIf="report_data.num_rep == 'R046'">{{v_table[0][1]}}</div>            
          </div>
          <div>
            <div>PERIODO</div>
            <div>{{period}}</div>
          </div>
        </div>
        <ng-container *ngIf="dtRendered">
          <table [ngClass]="table_hide" dt-instance="dtInstance" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-border row-border hover">
            <thead>
              <tr>

                <th>Item</th>
                <th>Estado</th>

                <th *ngIf="!chkDateHour">Fecha/Hora de Inicio</th>
                <th *ngIf="chkDateHour">Fecha Inicio</th>
                <th *ngIf="chkDateHour">Hora Inicio</th>
                <th *ngIf="!chkDateHour">Fecha/Hora de Fin</th>
                <th *ngIf="chkDateHour">Fecha Fin</th>
                <th *ngIf="chkDateHour">Hora Fin</th>

                <th *ngIf="!chkDateHour">Fecha/Hora Servidor de Inicio</th>
                <th *ngIf="chkDateHour">Fecha Servidor Inicio</th>
                <th *ngIf="chkDateHour">Hora Servidor Inicio</th>
                <th *ngIf="!chkDateHour">Fecha/Hora Servidor de Fin</th>
                <th *ngIf="chkDateHour">Fecha Servidor Fin</th>
                <th *ngIf="chkDateHour">Hora Servidor Fin</th>

                <th>Duración</th>
                <th>Velocidad</th>
                <th>Ignición</th>
                <th>Punto Cercano</th>
                <th>Geocerca</th>
                <th>Referencia</th>
                <th>Ubicación</th>

              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of v_table[1];  let i = index;">

                  <td>{{i + 1}}</td>
                  <td>{{row.descripcion_evento}}</td>

                  <td *ngIf="!chkDateHour">{{row.fecha_tracker}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_tracker.split(' ')[1]}}</td>

                  <td *ngIf="!chkDateHour">{{row.fecha_tracker_fin}}</td>
                  <td *ngIf="chkDateHour"> {{row.fecha_tracker_fin.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour"> {{row.fecha_tracker_fin.split(' ')[1]}}</td>

                  <td *ngIf="!chkDateHour">{{row.fecha_servidor}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_servidor.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour">{{row.fecha_servidor.split(' ')[1]}}</td>

                  <td *ngIf="!chkDateHour">{{row.fecha_servidor_fin}}</td>
                  <td *ngIf="chkDateHour"> {{row.fecha_servidor_fin.split(' ')[0]}}</td>
                  <td *ngIf="chkDateHour"> {{row.fecha_servidor_fin.split(' ')[1]}}</td>

                  <!-- <th>{{row.duracion}}</th> -->
                  <td>{{row.duracion_servidor}}</td>
                  <td>{{row.vel_gps}} km/h</td>
                  <td>{{row.ignicion}}</td>
                  <td>{{row.PC}}</td>
                  <!-- <td>{{row.nombre_zona}}</td> -->
                  <td>{{row.tramo}}</td>
                  <td>{{row.referencia}}</td>
                  <td [style.background-color]="'white'">
                    <a href="http://maps.google.com/maps?q={{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}&amp;t=m" target="_blank">{{row.latitud.toFixed(6)}},{{row.longitud.toFixed(6)}}</a>
                  </td>

              </tr>
            </tbody>

          </table>
        </ng-container>

      </div>
    </div>
    

    <div *ngIf="dtRendered" class="export-container">
      <ng-container *appHasPermission="'reports_excel_R001'">
        <button *ngIf="report_data.num_rep == 'R001'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelParadasMovimientos');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R002'">
        <button *ngIf="report_data.num_rep == 'R002'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelExcesosVelocidad');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R003'">
        <button *ngIf="report_data.num_rep == 'R003'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEntradaSalida');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R004'">
        <button *ngIf="report_data.num_rep == 'R004'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelCombustible');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R005'">
        <button *ngIf="report_data.num_rep == 'R005'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelExcesosEnZona');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R006'">
        <button *ngIf="report_data.num_rep == 'R006'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelGeneral');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R007'">
        <button *ngIf="report_data.num_rep == 'R007'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEventos');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R008'">
        <button *ngIf="report_data.num_rep == 'R008'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelPosicion');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R011'">
        <button *ngIf="report_data.num_rep == 'R011'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelFrenadaAceleracionBrusca');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="['reports_excel_R012','reports_excel_R022']">
        <button *ngIf="report_data.num_rep == 'R012' || report_data.num_rep == 'R022'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelDistraccionPosibleFatiga');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R013'">
        <button *ngIf="report_data.num_rep == 'R013'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelCalificacionManejo');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R014'">
        <button *ngIf="report_data.num_rep == 'R014'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelFatigaExtrema');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R015'">
        <button *ngIf="report_data.num_rep == 'R015'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelAnticolisionFrontal');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R016'">
        <button *ngIf="report_data.num_rep == 'R016'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelColisionConPeatones');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R017'">
        <button *ngIf="report_data.num_rep == 'R017'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelDesvioCarrilIzquierda');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R018'">
        <button *ngIf="report_data.num_rep == 'R018'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelDesvioCarrilDerecha');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R019'">
        <button *ngIf="report_data.num_rep == 'R019'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelBloqueoVisionMobileye');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R021'">
        <button *ngIf="report_data.num_rep == 'R021'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelExcesosVelocidadFormatoExtendido');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R023'">
        <button *ngIf="report_data.num_rep == 'R023'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelMantenimientoFisico');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R024'">
        <button *ngIf="report_data.num_rep == 'R024'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelNoRostro');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R025'">
        <button *ngIf="report_data.num_rep == 'R025'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelOdometroBaseTLinea');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R026'">
        <button *ngIf="report_data.num_rep == 'R026'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEncendidoMotor');">EXPORTAR EXCEL</button>
      </ng-container>

      <ng-container *appHasPermission="'reports_excel_R027'">
        <button *ngIf="report_data.num_rep == 'R027'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelLlaveIbutton');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R030'">
        <button *ngIf="report_data.num_rep == 'R030'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelFatigaExtremaFormatoBetsy_1');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R031'">
        <button *ngIf="report_data.num_rep == 'R031'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelAlcotester');">EXPORTAR EXCEL</button>
      </ng-container>

      <ng-container *appHasPermission="'reports_excel_R033'">
        <button *ngIf="report_data.num_rep == 'R033'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelCoordenadasGeocercas');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R034'">
        <button *ngIf="report_data.num_rep == 'R034'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelCoordenadasGeopuntos');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R035'">
        <button *ngIf="report_data.num_rep == 'R035'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelCoordenadasGeocercasCirculares');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R036'">
        <button *ngIf="report_data.num_rep == 'R036'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelGeneral');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R037'">
        <button *ngIf="report_data.num_rep == 'R037'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEventosCIPIA');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R038'">
        <button *ngIf="report_data.num_rep == 'R038'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEventosEvaluacionCIPIA');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R039'">
        <button *ngIf="report_data.num_rep == 'R039'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelExcesosVelocidadNuevoFormato');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R040'">
        <button *ngIf="report_data.num_rep == 'R040'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEventosCIPIA');">EXPORTAR EXCEL</button>
      </ng-container>

      <ng-container *appHasPermission="'reports_excel_R041'">
        <button *ngIf="report_data.num_rep == 'R041'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelExcesosVelocidadDuracionToleranciaRiesgo');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R042'">
        <button *ngIf="report_data.num_rep == 'R042'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelCombustibleResumen');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R043'">
      <button *ngIf="report_data.num_rep == 'R043'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEstadoTransmision');">EXPORTAR EXCEL</button>
      </ng-container>
      <ng-container *appHasPermission="'reports_excel_R044'">
        <button *ngIf="report_data.num_rep == 'R044'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelExcesosVelocidadDuracionToleranciaRiesgo');">EXPORTAR EXCEL</button>
      </ng-container>

      <ng-container *appHasPermission="'reports_excel_R045'">
        <button *ngIf="report_data.num_rep == 'R045'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelBOLIVIA');">EXPORTAR EXCEL</button>
      </ng-container>
      
      <ng-container *appHasPermission="'reports_excel_R046'">
        <button *ngIf="report_data.num_rep == 'R046'" class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelEstadoTransmisionEventos');">EXPORTAR EXCEL</button>
      </ng-container>

      <!-- <button *ngIf="report_data.num_rep == 'R028'" class="btn btn-gl btn-success btn-dark-hover" (click)="">EXPORTAR EXCEL</button> -->

      <!-- <div style="display: flex; flex-direction: row; justify-content: center; gap: 4rem; margin: 0; padding: 0;">
        <button class="btn btn-success" (click)="exportExcelParadasMovimientos(1)">EXPORTAR EXCEL SEPARADO</button>
        <button class="btn btn-success" (click)="exportExcelParadasMovimientos(2)">EXPORTAR EXCEL JUNTO</button>
      </div> -->

        <!-- <md-button class="md-raised md-primary" ng-click="res.exportPdfParadasMovimientos()">EXPORTAR PDF</md-button>  -->

        <!-- <md-button ng-if="res.mostrarPdf()" class="md-raised md-primary" ng-click="res.exportPdfPosicion()">EXPORTAR PDF</md-button> -->


        <ng-container *appHasPermission="'reports_pdf_R001'">
          <button *ngIf="report_data.num_rep == 'R001'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="showSelectPdf('exportPdfParadasMovimientos');">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R002'">
          <button *ngIf="report_data.num_rep == 'R002'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfExcesosVelocidadLimitePersonalizado();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R003'">
          <button *ngIf="report_data.num_rep == 'R003'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfEntradaSalida();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R004'">
          <button *ngIf="report_data.num_rep == 'R004'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfCombustible();">EXPORTAR PDF</button>
        </ng-container>

        <!-- <button *ngIf="report_data.num_rep == 'R004'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="showSelectExcel('exportPdfCombustible');">EXPORTAR PDF</button> -->
        <!-- <button *ngIf="report_data.num_rep == 4" class="btn btn-success" (click)="showSelectExcel('exportPdfExcesosEnZona');">EXPORTAR PDF</button> -->
        <ng-container *appHasPermission="'reports_pdf_R006'">
          <button *ngIf="report_data.num_rep == 'R006'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfGeneral();">EXPORTAR PDF</button>
        </ng-container>
        <!-- <button *ngIf="report_data.num_rep == 6" class="btn btn-success" (click)="showSelectExcel('exportPdfEventos');">EXPORTAR PDF</button> -->
        <ng-container *appHasPermission="'reports_pdf_R008'">
          <button *ngIf="report_data.num_rep == 'R008'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfPosicion();">EXPORTAR PDF</button>
        </ng-container>

        <ng-container *appHasPermission="'reports_pdf_R011'">
          <button *ngIf="report_data.num_rep == 'R011'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfFrenadaAceleracionBrusca();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R012'">
          <button *ngIf="report_data.num_rep == 'R012'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfDistraccionPosibleFatiga();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R013'">
          <button *ngIf="report_data.num_rep == 'R013'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfCalificacionManejo();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R014'">
          <button *ngIf="report_data.num_rep == 'R014'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfFatigaExtrema();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R015'">
          <button *ngIf="report_data.num_rep == 'R015'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfAnticolisionFrontal();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R016'">
          <button *ngIf="report_data.num_rep == 'R016'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfColisionConPeatones();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R017'">
          <button *ngIf="report_data.num_rep == 'R017'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfDesvioCarrilIzquierda();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R018'">
          <button *ngIf="report_data.num_rep == 'R018'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfDesvioCarrilDerecha();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R019'">
          <button *ngIf="report_data.num_rep == 'R019'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfBloqueoVisionMobileye();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R037'">
          <button *ngIf="report_data.num_rep == 'R037'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfEventosCIPIA();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R038'">
          <button *ngIf="report_data.num_rep == 'R038'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfEventosEvaluacionCIPIA();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R039'">
          <button *ngIf="report_data.num_rep == 'R039'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfExcesosVelocidadNuevoFormato();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R040'">
          <button *ngIf="report_data.num_rep == 'R040'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfEventosCIPIA();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R041'">
          <button *ngIf="report_data.num_rep == 'R041'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfExcesosVelocidadDuracionToleranciaRiesgo();">EXPORTAR PDF</button>
        </ng-container>


        <ng-container *appHasPermission="'reports_pdf_R042'">
          <button *ngIf="report_data.num_rep == 'R042'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfCombustibleResumen();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R043'">
          <button *ngIf="report_data.num_rep == 'R043'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfEstadoTransmision();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R044'">
          <button *ngIf="report_data.num_rep == 'R044'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfExcesosVelocidadDuracionToleranciaRiesgo();">EXPORTAR PDF</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_pdf_R046'">
          <button *ngIf="report_data.num_rep == 'R046'" class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfEstadoTransmisionEventos();">EXPORTAR PDF</button>
        </ng-container>
        <!-- <button *ngIf="report_data.num_rep == 20" class="btn btn-success" (click)="exportPdfExcesosVelocidadFormatoExtendido();">EXPORTAR PDF</button> -->


        <ng-container *appHasPermission="'reports_html_R001'">
          <button *ngIf="report_data.num_rep == 'R001'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlParadasMovimientos();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R002'">
          <button *ngIf="report_data.num_rep == 'R002'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlExcesosVelocidad();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R003'">
          <button *ngIf="report_data.num_rep == 'R003'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlEntradaSalida();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R004'">
          <button *ngIf="report_data.num_rep == 'R004'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlCombustible();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R005'">
          <button *ngIf="report_data.num_rep == 'R005'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlExcesosEnZona();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R006'">
          <button *ngIf="report_data.num_rep == 'R006'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlGeneral();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R008'">
          <button *ngIf="report_data.num_rep == 'R008'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlPosicion();">EXPORTAR HTML</button>
        </ng-container>

        <ng-container *appHasPermission="'reports_html_R037'">
          <button *ngIf="report_data.num_rep == 'R037'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlEventosCIPIA();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R038'">
          <button *ngIf="report_data.num_rep == 'R038'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlEventosEvaluacionCIPIA();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R039'">
          <button *ngIf="report_data.num_rep == 'R039'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlExcesosVelocidadNuevoFormato();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R040'">
          <button *ngIf="report_data.num_rep == 'R040'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlEventosCIPIA();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R041'">
          <button *ngIf="report_data.num_rep == 'R041'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlExcesosVelocidadDuracionToleranciaRiesgo();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R042'">
          <button *ngIf="report_data.num_rep == 'R042'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlCombustibleResumen();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R043'">
          <button *ngIf="report_data.num_rep == 'R043'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlEstadoTransmision();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R044'">
          <button *ngIf="report_data.num_rep == 'R044'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlExcesosVelocidadDuracionToleranciaRiesgo();">EXPORTAR HTML</button>
        </ng-container>
        <ng-container *appHasPermission="'reports_html_R046'">
          <button *ngIf="report_data.num_rep == 'R046'" class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlEstadoTransmisionEventos();">EXPORTAR HTML</button>
        </ng-container>

    </div>


  </ng-container>
</div>

