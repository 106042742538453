import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { includes } from 'lodash';
import { Alert, Evaluation } from 'src/app/alerts/models/alert.interface';
import { ToastService } from 'src/app/shared/services/toast.service';
import { OperatorService } from 'src/app/shared/services/operator.service';
import { WhatsappService } from '../../services/whatsapp.service';
import { AlertService } from 'src/app/alerts/service/alert.service';
import { UserDataService } from 'src/app/profile-config/services/user-data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseInterface } from 'src/app/core/interfaces/response-interface';
import { WhatsAppResponse } from '../../models/interfaces';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-event-evaluation',
  templateUrl: './event-evaluation.component.html',
  styleUrls: ['./event-evaluation.component.scss'],
})
export class EventEvaluationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() event: any;
  @Input() loading_evaluation: boolean = true;
  @Input() evaluation_criteria_filter: any[] = [];
  @Input() submitting: boolean = false;
  @Input() evaluation_criteria: any[] = [];
  @Input() eventsEvaluated: number = 0;
  @Output() submitEvaluation = new EventEmitter<{
    evaluation: Evaluation;
    event: Alert;
  }>();
  @Output() closeEvaluationExpanded = new EventEmitter<{
    evaluation: Evaluation;
  }>();
  @Output() submitSendEvaluationByWhatsapp = new EventEmitter<{
    EvaluationByWhatsapp: boolean;
  }>();
  @Output() submitSendExternal= new EventEmitter<{
    External: boolean
  }>();
  @ViewChild('submitEvaluationButton') submitEvaluationButton!: ElementRef;
  monitoringOperatorTemp: string = '';
  evaluationObservationsTemp: string[] = [];
  evaluationObservations: string[] = [];
  evaluationCriteria: string[] = [];
  showDialog: boolean = true; //controla si p-dialog se muestra
  showDialogContent: boolean = true; //controla si contenido de p-dialog se muestra
  expandedRows: { [s: string]: boolean } = {};
  sendEvaluationByWhatsapp: boolean = false;
  sendExternal: boolean = false;
  imeiBelongsToAntapaccayAccount: boolean = false;
  confirmedJudgingEvent: boolean = false;
  whatsappsSendingMessage: string = '';
  messagesSentByWhatsApp: WhatsAppResponse[] = [];

  // Variables para Linea
  checkEvent:string[] = [
    'exceso-velocidad',
    'infraccion-geocerca-direccion',
    'infraccion-geocerca',
    'exceso-velocidad-geocerca-direccion',
    'conductor-somnoliento-360',
    'conductor-adormitado-360',
    'posible-fatiga',
  ];
  // 
  operators = [
    {
      label: 'Operadores',
      items: [
        {
          label: 'ATENCIO ALFARO, Claudia Isabel',
          value: 'ATENCIO ALFARO, Claudia Isabel',
        },
        {
          label: 'FERNANDEZ CRUZ, Rosellia Yanina',
          value: 'FERNANDEZ CRUZ, Rosellia Yanina',
        },
        {
          label: 'HAÑARI SALCEDO, Katherine Liliana',
          value: 'HAÑARI SALCEDO, Katherine Liliana',
        },
        { label: 'MAMANI SIERRA, Raquel', value: 'MAMANI SIERRA, Raquel' },
        {
          label: 'NUÑEZ CHAMBI, Michelle Solzire',
          value: 'NUÑEZ CHAMBI, Michelle Solzire',
        },
        {
          label: 'NUÑONCA ALARCON, Daniela Stefany',
          value: 'NUÑONCA ALARCON, Daniela Stefany',
        },
        {
          label: 'PACCI QUISPE, Betzy Belquis',
          value: 'PACCI QUISPE, Betzy Belquis',
        },
        {
          label: 'PACHECO MOLLO, Cesar Vidal',
          value: 'PACHECO MOLLO, Cesar Vidal',
        },
        {
          label: 'ROJAS RONDON, Carla Beatriz',
          value: 'ROJAS RONDON, Carla Beatriz',
        },
        {
          label: 'SUARES PACURI, Ayelen Melani',
          value: 'SUARES PACURI, Ayelen Melani',
        },
      ],
    },
  ];

  msmOfTxRadial:string='Sin Respuesta'
  constructor(
    private toastService: ToastService,
    public operatorService: OperatorService,
    private whatsappService: WhatsappService,
    private alertService: AlertService,
    private userDataService: UserDataService,
    private http: HttpClient
  ) {}
  msmOfTxRadialChange(event: any){
    const value = event.value
    if (value == null) this.msmOfTxRadial = 'Sin respuesta'
    if (value == true) this.msmOfTxRadial = 'Si'
    if (value == false) this.msmOfTxRadial = 'No'
  }
  msmOfTxRadialSelect(value:boolean|null|undefined):string{
    if (value == null) this.msmOfTxRadial = 'Sin respuesta'
    if (value == true) this.msmOfTxRadial = 'Si'
    if (value == false) this.msmOfTxRadial = 'No'
    return this.msmOfTxRadial
  }
  ngOnInit(): void {
    this.monitoringOperatorTemp =
      localStorage.getItem('monitoringOperator') ?? '';
    this.eventsEvaluated < 6
      ? (this.submitting = false)
      : (this.submitting = true);
    this.evaluationObservationsTemp = JSON.parse(
      localStorage.getItem('evaluationObservations') || '[]'
    );
    console.log('eventEval', this.event);

    this.operators = this.operatorService.listOperators;
    this.vehicleIsFromAntapaccay(this.event.imei);
    this.messageSendingMessage();
  }
  ngOnDestroy(): void {
    localStorage.setItem('monitoringOperator', this.monitoringOperatorTemp);
    localStorage.setItem(
      'evaluationObservations',
      JSON.stringify(this.evaluationObservationsTemp)
    );
  }
  ngAfterViewInit(): void {
    // this.event.evaluations.forEach((e: any, i: any) => {
    Object.values(this.event.evaluations).map((e: any) => {
      e.operador_monitoreo = this.monitoringOperatorTemp;
    });
  }
  checkListEventsByType(type:string){
    return this.checkEvent.includes(type)
  }
  onHide() {
    this.showDialog = true;
    this.showDialogContent
      ? (this.showDialogContent = false)
      : (this.showDialogContent = true);
    // console.log('event evaluation dialog', this.event);
  }

  criteriaSelected(event: any, evaluation: Evaluation) {
    console.log('eventEval: criteriaSelected', event, evaluation);

    evaluation.senales_posible_fatiga = false;
    this.evaluation_criteria[0].items.forEach((criteria: { value: any }) => {
      if (criteria.value == event.value) {
        evaluation.senales_posible_fatiga = true;
      }
    });
  }
  criteriaSelectedFromAutoComplete(event: any, evaluation: any) {
    evaluation.senales_posible_fatiga = false;
    this.evaluation_criteria[0].items.forEach((criteria: { value: any }) => {
      if (criteria.value == event) {
        evaluation.senales_posible_fatiga = true;
      }
    });
    this.evaluationCriteria.some((c, i) => {
      if (i < 2 && c == event) {
        this.confirmedJudgingEvent = true;
        console.log(event, c, i);
        return true;
      }
      this.confirmedJudgingEvent = false;
      return false;
    });
  }

  submitEvaluationEmiter(evaluation: any, event: Alert) {
    this.monitoringOperatorTemp = evaluation.operador_monitoreo ?? '';
    if (evaluation.observacion_evaluacion?.length! > 3) {
      this.evaluationObservationsTemp.find(
        (e) => e == evaluation.observacion_evaluacion
      )
        ? null
        : this.evaluationObservationsTemp.push(
            evaluation.observacion_evaluacion!
          );
    }
    this.submitEvaluation.emit({ evaluation, event });
  }

  closeEvaluationExpandedEmiter(evaluation: Evaluation) {
    this.closeEvaluationExpanded.emit({ evaluation });
  }
  submitSendEvaluationByWhatsappEmitter() {
    let EvaluationByWhatsapp = this.sendEvaluationByWhatsapp;
    this.submitSendEvaluationByWhatsapp.emit({ EvaluationByWhatsapp });
  }
  submitSendExternalEmitter(){
    let External = this.sendExternal;
    this.submitSendExternal.emit({External})
  }
  saveEvaluationOnShiftEnter() {
    this.submitEvaluationButton.nativeElement.click();
  }
  searchEvaluationObservationsTemp(event: any) {
    let filtered: any[] = [];
    let q = event.query;
    this.evaluationObservationsTemp.forEach((e) => {
      e.toLowerCase().includes(q.toLowerCase()) ? filtered.push(e) : null;
    });
    this.evaluationObservations = filtered;
  }
  // searchEvaluationCriteria(event: any) {
  //   let filtered: any[] = [];
  //   let q = event.query.toLowerCase(); // Convertir la consulta a minúsculas y eliminar espacios en blanco

  //   this.evaluation_criteria_filter.forEach((e) => {
  //     let matchedItems: any[] = e.items.filter(
  //       (i: { label: string; value: string }) => {
  //         // Filtrar los items que coincidan con la consulta
  //         return (
  //           i.label.toLowerCase().includes(q) ||
  //           i.value.toLowerCase().includes(q)
  //         );
  //       }
  //     );

  //     if (matchedItems.length > 0) {
  //       // Si hay elementos coincidentes, agregarlos al objeto filtrado
  //       filtered.push({
  //         label: e.label,
  //         items: matchedItems,
  //       });
  //     }
  //   });
  //   // console.log('eventEval: filtered', filtered);

  //   this.evaluationCriteria = [...filtered];
  // }
  searchEvaluationCriteria(event: any) {
    let filtered: any[] = [];
    let q = event.query.toLowerCase(); // Convertir la consulta a minúsculas y eliminar espacios en blanco

    this.evaluation_criteria_filter.forEach((e) => {
      filtered.push(e.label);
      e.items.filter((i: { label: string; value: string }) => {
        i.value.toLowerCase().includes(q) ? filtered.push(i.value) : null;
      });
    });
    console.log('eventEval: filtered', filtered);

    this.evaluationCriteria = [...filtered];
  }
  iCanSeeExternalSending():boolean{
  let res: boolean = false;
    const a: any = this.alertService.getAlertById(
      this.event.evento_id.toString()
    );
    if (
      a &&
      this.imeiBelongsToAntapaccayAccount &&
      this.confirmedJudgingEvent
    ) {
      res = true;
    }
    return res;
  }
  iCanSeeWhatsappSending(): boolean {
    let res: boolean = false;
    const a: any = this.alertService.getAlertById(
      this.event.evento_id.toString()
    );
    if (
      a &&
      a.notificacion_whatsapp &&
      this.checkBoolean(a.notificacion_whatsapp) &&
      this.imeiBelongsToAntapaccayAccount &&
      this.confirmedJudgingEvent
    ) {
      res = true;
    }
    return res;
  }
  private checkBoolean(input: string | number | boolean): boolean {
    if (typeof input === 'boolean') {
      return input;
    }
    const booleanValues: Record<string, boolean> = {
      true: true,
      false: false,
      '1': true,
      '0': false,
    };
    if (typeof input === 'number') {
      return booleanValues[input.toString()] ?? false;
    }
    if (typeof input === 'string') {
      const trimmedInput = input.trim().toLowerCase();
      return booleanValues[trimmedInput] ?? false;
    }
    return false;
  }
  isUserManager961(): boolean {
    let id_user_manager = this.userDataService.userData;
    return id_user_manager !== null && id_user_manager === 961;
  }
  private vehicleIsFromAntapaccay(imei: string) {
    this.imeiBelongsToAntapaccayAccount =
      this.whatsappService.IMEIsBelongsToAntapaccayAccount.some(
        (_: string) => _ == imei
      );
  }
  messageSendingMessage() {
    let messages: Record<string, (T: WhatsAppResponse[]) => string> = {
      false: (T) => {
        if (T.length > 0) {
          return 'Algunos mensajes de WhatsApp no se enviaron.';
        } else {
          return ''; // 'No se enviaron mensajes de WhatsApp.';
        }
      },
      true: (T) => 'Los mensajes de WhatsApp se enviaron correctamente.',
    };
    this.whatsappService
      .getMessagesSentByWhatsApp(
        this.event.evaluations[0].usuario_id,
        this.event.evaluations[0].uuid_event
      )
      .then((r) => {
        this.messagesSentByWhatsApp = r;
        this.whatsappsSendingMessage =
          messages[`${this.areMessagesSentCorrectly()}`](r);
      })
      .catch((r) => {});
  }
  areMessagesSentCorrectly(): boolean {
    return this.messagesSentByWhatsApp.some((r) => r.status == 200);
  }
  getNumberOfSentByWhatsApp(T: WhatsAppResponse[]): string {
    return Object.entries(T)
      .filter(([_, wr]) => wr.status != 200)
      .map(([_, wr]) => wr.phone)
      .join(', ');
  }
}
