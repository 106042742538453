
import { Component, ElementRef, ViewChild, Input, Output, OnInit, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { Convoy, GridItem, MediaRequest, Operation, ScreenView, UnitItem, UserTracker, StructureGrid } from '../models/interfaces';
import { ResponseInterface } from 'src/app/core/interfaces/response-interface';
import { MultiviewService } from '../services/multiview.service';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {

  @Input('display') display: boolean = false;
  @Output() onHideEvent = new EventEmitter<boolean>();
  @ViewChild('nameInput') nameInput!: ElementRef;
  selectedValue: string="";
  multiple: boolean = true;
  operations: Operation[] = [];
  unitItems: UnitItem[] = [];
  units: UserTracker[] = [];
  // convoys: Convoy[] = [];
  selectedOperation: any = null;
  fromBack = false;
  fromSaved = false;
  itJustCleaned = false;
  currentMultiview!: ScreenView;
  copyMultiview!: ScreenView;
  selectedGroup!: ScreenView | null;
  loading : boolean = false;
  isGrouped: boolean = false; //Tipo de Items 0: Simple,1:Grupo similar a convoy
  stateIsGrouped: any[] = [{label: 'Simple', value: false}, {label: 'Grupo', value: true}];
  fromGroup = false; //No necesario Si se usa save
  viewTemplateGroup: boolean = false;
  // Save multiview
  showSaveMultiview = false;
  stateOptions: any[] = [{label: 'Si', value: 'si'}, {label: 'No', value: 'no'}];
  saveMultiviewOption: string = 'si';
  validName = false;

  save : boolean = true;
  multiviewIsDiff : boolean = true;
  constructor(
    public multiviewService: MultiviewService,
    private vehicleService: VehicleService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    if(!this.vehicleService.statusDataVehicle){
      // console.log('(vehicleService) vehicle service no está listo. Subscribiendo para obtener data...');
      this.vehicleService.dataCompleted.subscribe({
        next: (result: boolean) => {
          if(result){
            this.multiviewService.getOperations().subscribe((resp:ResponseInterface) => {
              this.operations = resp.data as Operation[];
              this.operations.push({id:0,descripcion:"Sin Operación", nombre:"Sin Operación",usuario_id:0});
            });
            this.resetCurrentMultiview();
            this.resetUnitItems();
          }
        },
        error: (errMsg: any) => {
          console.log('(vehicle service) Error al obtener list operations: ', errMsg);
        }
      });
    } else {
      // console.log('(vehicel service) está listo. Subscribiendo para obtener data...');
      this.multiviewService.getOperations().subscribe((resp:ResponseInterface) => {
        this.operations = resp.data as Operation[];
        this.operations.push({id:0,descripcion:"Sin Operación", nombre:"Sin Operación",usuario_id:0});
      });
      this.resetCurrentMultiview();
      this.resetUnitItems();
    }
  }

  resetCurrentMultiview(){
    this.currentMultiview = {
      name: "",
      grids: [],
      is_open: false,
      was_edited: false,
      is_group: false,
      title_group: '',
    };
  }

  onHide(){
    this.clearPreview();
    this.onHideEvent.emit(false);
  }
  onOption(e : string){
    console.log("option XD");
  }
  onSelectMultiview(event:any){
    console.log("onSelectMV event", event.value);
    this.currentMultiview = { ...event.value };
    this.fromSaved = true;
    this.currentMultiview.is_group = this.currentMultiview.is_group ?? false;
    this.isGrouped = this.currentMultiview.is_group ?? false;
    this.currentMultiview.title_group =  (event.value) ? event.value.name : '';
    this.viewTemplateGroup = this.isGrouped;
    if(!event.value){
      this.multiviewIsDiff = true;
      this.clearPreview();
      this.multiviewService.selectedUnits = [];
      console.log("onselectmultiview: ",this.multiviewService.selectedUnits);
    }else{
      this.multiviewService.selectedUnits = this.currentMultiview.grids!.map( (item:GridItem) => {
        return item.content as UnitItem;
      });
      // this.fromGroup = this.currentMultiview.is_group;
      this.multiviewIsDiff = false;
      this.copyMultiview = { ...this.currentMultiview};
      console.log('Copy',this.copyMultiview);
    }
  }
  deleteMultiview(name: string){
    console.log("delete multiview: ", name);
    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar el grupo ${name}?`,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: () => !Swal.isLoading(), // Evita interacción externa mientras se carga
      preConfirm: async () => {
        try {
          const resp = await this.multiviewService.deleteMultiview(name).toPromise();

          if (resp.success) {
            this.multiviewService.userMultiview = this.multiviewService.userMultiview.filter(
              (item) => item.name !== name
            );
            if(this.currentMultiview.was_edited == false){
              this.clearPreview();
            }
            Swal.fire(
              'Eliminado',
              'Los datos se eliminaron correctamente!!',
              'success'
            );
          } else {
            Swal.fire('Error', 'No se pudo eliminar los datos', 'error');
          }
        } catch (error) {
          console.error('Error en Eliminar multiview: ',error);
          Swal.fire('Error', 'Ocurrió un error en la solicitud', 'error');
        }
      },
    });
  }

  changeOperation(){

    this.unitItems = [];
    // // DEPRECATE
    // // obtengo los convoys que pertenecen a esa operación
    // //Para ello obtengo todos los vehiculos que pertenecen a esa operación
    // let aux_convoys = this.vehicleService.vehicles.filter((vehicle: any)=>vehicle.idoperation == this.selectedOperation && vehicle.idconvoy != 0);
    // // de todos esos vehiculos extraigo los ids de convoys
    // let convoysIds = new Set<number>(aux_convoys.map(vh => {
    //   return vh.idconvoy!;
    // }));
    // //Por cada Id de convoys, busco el primer vehiculo con ese convoyid y me creo un objeto de tipo UnitItem para insertarlo en UnitItems[]
    // convoysIds.forEach(id => {
    //   this.unitItems.push(
    //     ...[aux_convoys.find(convoy => convoy.idconvoy == id)].map(item => {
    //       return {
    //         id: item!.idconvoy!,
    //         nombre: "(CONVOY) "+item!.nameconvoy!,
    //         operation: item!.nameoperation!,
    //         selected: false,
    //         type: "convoy",
    //         imeis: this.vehicleService.vehicles.filter((vehicle: any)=>vehicle.idoperation == this.selectedOperation && vehicle.idconvoy == item!.idconvoy).map(vh => vh.IMEI!)
    //       } as UnitItem
    //     })
    //   );
    // });

    if(this.selectedOperation){
      //Una vex obtenga todos los convoys de esa operación, ahora agrego los vehiculos individualmente
      this.vehicleService.vehicles.filter((vehicle: any)=>vehicle.idoperation == this.selectedOperation).forEach(tracker_unit => {
        this.unitItems.push({
          id: tracker_unit.id,
          nombre: tracker_unit.name!,
          operation: tracker_unit.nameoperation,
          selected: false,
          type: "tracker",
          imeis: [tracker_unit.IMEI!.toString()]
        });
      })
      this.unitItems.sort((a, b) => a.nombre.localeCompare(b.nombre));
      console.log("this.unitItems: ",this.unitItems);
    }else{
      this.resetUnitItems();
    }

  }

  resetUnitItems(){
    this.unitItems = [];
    this.vehicleService.vehicles.forEach(tracker_unit => {
      this.unitItems.push({
        id: tracker_unit.id,
        nombre: tracker_unit.name!,
        operation: tracker_unit.nameoperation,
        selected: false,
        type: "tracker",
        imeis: [tracker_unit.IMEI!.toString()]
      });
    })
    this.unitItems.sort((a, b) => a.nombre.localeCompare(b.nombre));
    console.log("Reset unitItems: ",this.unitItems);
  }

  onGridChange(event:GridItem[]){
    console.log("lista unidades: ", event);
    console.log("lista structura prev: ", this.currentMultiview);
    this.currentMultiview.was_edited = true;
    if(!this.multiviewService.arraysAreEqual(this.currentMultiview.grids,event)){
      this.currentMultiview.grids = event!;
      if(this.currentMultiview.grids.length > 0){
        this.currentMultiview.was_edited = true;
        console.log("this.currentMultiview.was_edited",this.currentMultiview.was_edited);

      }else{
        this.currentMultiview.was_edited = false;
        console.log("this.currentMultiview.was_edited3",this.currentMultiview.was_edited);
      }
    }
    if(this.fromBack){ // From SaveMultiview to back
      this.currentMultiview.was_edited = true;
      console.log("this.currentMultiview.was_edited2",this.currentMultiview.was_edited);

      console.log("fromBack: ",this.fromBack);
      this.fromBack = false;
    }
    if(this.fromSaved){ //From Vistas Guardadas
      this.currentMultiview.was_edited = false;
      console.log("this.currentMultiview.was_edited22",this.currentMultiview.was_edited);

      console.log("fromSaved: ",this.fromSaved);
      this.fromSaved = false;
    }
    if(this.itJustCleaned){
      this.currentMultiview.was_edited = false;
      console.log("this.currentMultiview.was_edited333",this.currentMultiview.was_edited);

      console.log("fromSaved: ",this.fromSaved);
      console.log("itJustCleaned: ",this.itJustCleaned);
      this.itJustCleaned = false;
    }
    console.log("lista structura: ", this.currentMultiview);
  }

  changeUnits(){
    console.log('Agregando Unidades');
    const countUnits = this.multiviewService.selectedUnits.length;
    if(countUnits > 20){
      if(this.isGrouped){
        this.currentMultiview.was_edited = true;
        this.currentMultiview.grids?.push(this.addUnitItemToGrid(this.multiviewService.selectedUnits[countUnits-1]))
      }else{
        this.multiviewService.selectedUnits.splice(20);
      }
      console.log('Solo Permitir 20');
    }else{
      //si una unidad fue seleccionada
      this.currentMultiview.was_edited = true;
      console.log('Cambio en Unidad');
    }
    if(this.multiviewService.selectedUnits.length == 0){
      console.log("length: ",this.multiviewService.selectedUnits.length);
      this.currentMultiview.was_edited = false;
    }
    //console.log("selectedUnits: ", this.multiviewService.selectedUnits);
  }
  async onSubmit(){
    /*
    await this.configService.postGroup(req).toPromise()
    .then((info: { res: any; }) => {
      if(info.res){

      }else{

      }
    }).catch((errorMsg: any) => {
      console.log(`(Vehicle Group) Hubo un error al crear el nuevo grupo/convoy (promise): `, errorMsg);
    });*/
  }

  onShow(){
    console.log('Show Multi View Modal');
  }
  isValidGroupName(){
    if(this.currentMultiview.name.length < 3 || this.currentMultiview.name.includes(' ')){
      this.validName = false;
      this.nameInput.nativeElement.classList.remove('ng-valid');
      this.nameInput.nativeElement.classList.add('ng-invalid','ng-dirty');
    }
    else{
      this.validName = true;
      this.nameInput.nativeElement.classList.remove('ng-invalid','ng-dirty');
      this.nameInput.nativeElement.classList.add('ng-valid');
    }
  }
  async openNewMultiview(multiview: ScreenView){
    let newMultiview = {...multiview};
    if(newMultiview.name == ""){
      newMultiview.name = "default";
    }
    this.multiviewService.userMultiview = this.multiviewService.userMultiview.filter(item => item.name !== newMultiview.name);
    this.multiviewService.userMultiview.push(newMultiview);
    this.multiviewService.openScreenView(newMultiview.name);
  }
  clearPreview(){
    this.showSaveMultiview = false;
    this.multiviewService.selectedUnits = [];
    this.validName = true;
    this.saveMultiviewOption = 'si';
    this.loading = false;
    this.selectedGroup = null;
    this.resetCurrentMultiview();
    this.isGrouped = false;
    this.viewTemplateGroup = false;
    // this.fromGroup = false;
    this.multiviewIsDiff = true;
  }
  backToPreview(){
    console.log("multiviewService.selectedUnits",this.multiviewService.selectedUnits);
    this.fromBack = true;
    this.showSaveMultiview = false;
  }
  /**
   * @deprecated Esta función ha sido reemplazada por `onConfirmNewGroup`.
   * Usa `onConfirm` en su lugar.
  */
  onConfirmGroup(){
    if(!this.showSaveMultiview){
      //Si estamos en la vista de preview y dan click en siguiente
      console.log("en vista preview");

      if(this.currentMultiview.was_edited){
        console.log("currentMultiview editado");
        //Si la actual estructura fue editada debo mostrar la opcion de guardar
        this.showSaveMultiview = true;
      }else{
        console.log("current Multiview no fue editado");
        // si no fue editado es un grupo antiguo abierto o uno vacio
        //verificar que no este vacio y abrir, en caso sea vacio no hacer nada
        if(this.currentMultiview.grids && this.currentMultiview.grids!.length > 0){
          console.log("currentMultiview no esta vacio");
          this.openNewMultiview(this.currentMultiview)
        };
      }
      return;
    }
    // Si estamos en la vista de guardado y dan click en siguiente
    console.log("en vista guardado");
    if(this.saveMultiviewOption == 'si'){
      console.log("si quiere guardar");
      //si no tiene un nombre valido
      this.isValidGroupName();
      if(!this.validName){
        console.log("no tiene nombre valido");
        return;
      }
      console.log("si tiene nombre valido");
      //Si desea guardar y tiene un nombre valido, guardamos/actualizamos y abrimos
      this.loading = true;
      //ya que guardare, defino que no fueron editados
      this.currentMultiview.was_edited = false;
      this.currentMultiview.is_group = this.isGrouped;
      if(this.multiviewService.userMultiview.find(item => item.name === this.currentMultiview.name)){
        // Si hay un multiview que se llame igual preguntar si sobreescribirá;
        console.log("duplicated?: ", this.multiviewService.userMultiview);
        Swal.fire({
          title: '¿Sobreescribir grupo?',
          text: `¿El grupo ${this.currentMultiview.name} ya existe, desea sobreecribirlo?`,
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Sobreescribir',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: () => !Swal.isLoading(), // Evita interacción externa mientras se carga
          preConfirm: async () => {
            try {
              const resp = await this.multiviewService.saveMultiview(this.currentMultiview).toPromise();

              if (resp.success) {
                this.openNewMultiview(this.currentMultiview);
                console.log("current userMultiviews: ",this.multiviewService.userMultiview);
                this.clearPreview();
                Swal.fire(
                  'Guardado',
                  'Los datos se guardaron correctamente!!',
                  'success'
                );
              } else {
                this.loading = false;
                Swal.fire('Error', 'No se pudo guardar los datos', 'error');
              }
            } catch (error) {
              this.loading = false;
              console.error('Error en Sobreescribir Multiview: ',error);
              Swal.fire('Error', 'Ocurrió un error en la solicitud', 'error');
            }
          },
        }).then((result) => {
          if (result.isDismissed) {
            this.loading = false;
            this.currentMultiview.was_edited = true;
          }
        });;
      }else{
        this.multiviewService.saveMultiview(this.currentMultiview).subscribe(resp => {
          if(resp.success){
            this.openNewMultiview(this.currentMultiview);
            console.log("current userMultiviews: ",this.multiviewService.userMultiview);
            this.clearPreview();
          }else{
            Swal.fire('Error', 'No se pudo guardar el grupo. Intente nuevamente.', 'error');
            this.loading = false
          }
        });
      }

    }else{
      console.log("no quiso guardar");
      //  si no desea guardar se abre la pestaña con el nombre por defecto
      this.currentMultiview.name = "";
      this.openNewMultiview(this.currentMultiview);
      this.clearPreview();
    }

  }

  onConfirm(){
    if(!this.showSaveMultiview){
      //Si estamos en la vista de preview y dan click en siguiente
      if(this.currentMultiview.grids && this.currentMultiview.grids!.length > 0){
        console.log("currentMultiview no esta vacio");
        if(this.selectedGroup){ //Vistas Edit o No
          console.log('Tiene Vista Edit');
          this.multiviewIsDiff = this.diffMultiview(this.copyMultiview,this.currentMultiview);
        }

        if(this.save){
          console.log('Preview -> Save');
          this.showSaveMultiview = true;
        }else{
          console.log('Preview -> Open');
          this.openNewMultiview(this.currentMultiview)
        }
      }else{
        console.log('Grids INsuficientes');
      }
      return;
    }

    if(this.saveMultiviewOption == 'si'){
      // Opcion Guardar SI
      console.log("si quiere guardar");
      //si no tiene un nombre valido
      this.isValidGroupName();
      if(!this.validName){
        console.log("NOMBRE INVALIDO");
        return;
      }
      //Si desea guardar y tiene un nombre valido, guardamos/actualizamos y abrimos
      this.loading = true;
      //ya que guardare, defino que no fueron editados
      this.currentMultiview.was_edited = false;
      this.currentMultiview.is_group = this.isGrouped;
      if(this.multiviewService.userMultiview.find(item => item.name === this.currentMultiview.name)){
        // Si hay un multiview que se llame igual preguntar si sobreescribirá;
        console.log("duplicated?: ", this.multiviewService.userMultiview);
        Swal.fire({
          title: '¿Sobreescribir grupo?',
          text: `¿El grupo ${this.currentMultiview.name} ya existe, desea sobreecribirlo?`,
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Sobreescribir',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: () => !Swal.isLoading(), // Evita interacción externa mientras se carga
          preConfirm: async () => {
            try {
              const resp = await this.multiviewService.saveMultiview(this.currentMultiview).toPromise();

              if (resp.success) {
                this.openNewMultiview(this.currentMultiview);
                console.log("current userMultiviews: ",this.multiviewService.userMultiview);
                this.clearPreview();
                Swal.fire('Guardado','Los datos se guardaron correctamente!!','success');
              } else {
                this.loading = false;
                Swal.fire('Error', 'No se pudo guardar los datos', 'error');
              }
            } catch (error) {
              this.loading = false;
              console.error('Error en Sobreescribir Multiview: ',error);
              Swal.fire('Error', 'Ocurrió un error en la solicitud', 'error');
            }
          },
        }).then((result) => {
          if (result.isDismissed) {
            this.loading = false;
            this.currentMultiview.was_edited = true;
          }
        });;
      }else{
        this.multiviewService.saveMultiview(this.currentMultiview).subscribe(resp => {
          if(resp.success){
            this.openNewMultiview(this.currentMultiview);
            console.log("current userMultiviews: ",this.multiviewService.userMultiview);
            this.clearPreview();
          }else{
            Swal.fire('Error', 'No se pudo guardar el grupo. Intente nuevamente.', 'error');
            this.loading = false
          }
        });
      }
    }else{
      // Opcion Guardar NO
      this.currentMultiview.name = "";
      this.openNewMultiview(this.currentMultiview);
      this.clearPreview();
    }
  }

  // Retornar TRUE si son Diferentes
  diffMultiview(copy:ScreenView,current:ScreenView):boolean{
    console.log('Check Copy : ',this.copyMultiview);
    console.log('Check Current : ',this.currentMultiview);
    console.log('Comparacion Manual ...');
    // is_group y title_group
    if(copy.is_group != current.is_group || copy.title_group != current.title_group){
      console.log('Diff in Group');
      return true;
    }
    // Cantidad de Elementos
    if(copy.grids?.length != current.grids?.length){
      console.log('Diff in Grids Lenght');
      return true;
    }
    // Unidades Identicas
    if(!this.equalImeis(copy.grids!,current.grids!)){
      console.log('Diff IMEIS');
      return true;
    }
    // Order de Unidades
    if(!this.equalStructured(copy.grids!,current.grids!)){
      console.log('Diff Esctructure');
      return true;
    }
    console.log('Retorno DEFAULT FALSE Son IGUALES');
    return false;
  }

  equalStructured(copyGrids: GridItem[], currentGrids: GridItem[]){
    // Recorremos ambos arrays de grids
    for (let i = 0; i < copyGrids.length; i++) {
      const copyGrid = copyGrids[i];
      const currentGrid = currentGrids[i];

      // Verificamos si 'structure' existe en ambos grids
      if (!copyGrid.structure || !currentGrid.structure) {
        return false; // Si alguno de los dos no tiene 'structure', no son iguales
      }
      // Comparamos los valores de row, col y span
      if (
        copyGrid.structure.row !== currentGrid.structure.row ||
        copyGrid.structure.col !== currentGrid.structure.col ||
        copyGrid.structure.span !== currentGrid.structure.span
      ) {
        return false; // Si alguna propiedad es diferente, los grids no son iguales
      }
    }
    return true; // Si pasaron todas las comparaciones, los grids son iguales
  }

  extractImeis(grids: GridItem[]): string[] {
    return grids
      .reduce((acc: string[], item) => {
        const imeis = item.content?.imeis || [];
        return acc.concat(imeis); // Añade los IMEIs al acumulador
      }, [])
      .filter(imei => imei) // Filtra valores nulos o indefinidos
      .sort(); // Ordena alfabéticamente
  }
  equalImeis(copy: GridItem[], current: GridItem[]): boolean {
    const imeisCopy = this.extractImeis(copy);
    const imeisCurrent = this.extractImeis(current);

    if (imeisCopy.length !== imeisCurrent.length) return false;

    return imeisCopy.every((imei, index) => imei === imeisCurrent[index]);
  }

  async onChangeTypeIsGroup(){
    if(this.currentMultiview.grids && this.currentMultiview.grids.length>20 && !this.isGrouped){
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: `El modo Simple solo puede gestionar 20 unidades, el resto de unidades se descartarán.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, cambiar',
        cancelButtonText: 'No, cancelar',
      });
      if (result.isConfirmed) {
      } else {
        this.isGrouped = !this.isGrouped; // Vuelve al valor anterior
        return;
      }
    }

    await this.validateMaxUnits();
    this.changeIsGroup();  // Llama la función para cambiar isGrouped

  }
  changeIsGroup(){
    this.currentMultiview.is_group = !this.currentMultiview.is_group;
    // this.currentMultiview.was_edited = (this.fromGroup != this.isGrouped) ? true : false;
    this.viewTemplateGroup = this.isGrouped;
  }
  async validateMaxUnits(): Promise<void> {
    return new Promise((resolve) => {
      if (this.currentMultiview.grids && this.currentMultiview.grids.length > 20 && !this.isGrouped) {
        // Mantén solo los primeros 20 elementos
        this.currentMultiview.grids = this.currentMultiview.grids.slice(0, 20);
        this.multiviewService.selectedUnits = this.multiviewService.selectedUnits.slice(0, 20);
      }
      resolve(); // Finaliza la promesa
    });
  }


  addUnitItemToGrid(unitItem:UnitItem){
    const addGrid:GridItem = {
      content : unitItem,
      content_type: "minimap",
      label: unitItem.nombre,
      show_only_label : true,
      title : unitItem.nombre + ' ' + unitItem.operation,
    }
    return addGrid;
  }

}
